import { DataFieldConfig, Model } from '@bryntum/schedulerpro'

// Implicitly adds new fields to an existing interface
interface PlayerModel {
  id: string | number
  firstName: string
  lastName: string
  sportId: number
  sportLabel: string
  positionId: number
  positionLabel: string
  isStaff: boolean
  isAvailable: boolean
}

// Explicitly extends Bryntum types and adds missing types
// TODO: remove once the issue is fixed: https://github.com/bryntum/support/issues/5017
interface DataFieldConfigExtension extends DataFieldConfig {
  type: string
}

// Types of the raw fields
interface PlayerRawObject {
  id: number
  first_name: string
  last_name: string
  sport_id: number
  sport_label: string
  sport_position_id: number
  sport_position_label: string
  is_staff: boolean
  is_available: boolean
}

/**
 * PlayerModel is used to describe player in players[]
 */
class PlayerModel extends Model {
  static $name = 'PlayerModel'

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      { name: 'firstName', type: 'string', dataSource: 'first_name' },
      { name: 'lastName', type: 'string', dataSource: 'last_name' },
      { name: 'sportId', type: 'number', dataSource: 'sport_id' },
      { name: 'sportLabel', type: 'string', dataSource: 'sport_label' },
      { name: 'positionId', type: 'number', dataSource: 'sport_position_id' },
      { name: 'positionLabel', type: 'string', dataSource: 'sport_position_label' },
      { name: 'isStaff', type: 'boolean', dataSource: 'is_staff', defaultValue: false },
      { name: 'isAvailable', type: 'boolean', dataSource: 'is_available', defaultValue: true },
    ]
  }

  get isPlayerModel(): boolean {
    return true
  }

  public toJSON(): Partial<PlayerRawObject> {
    return super.toJSON()
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  set fullName(name: string) {
    const [firstName, ...otherNames] = name.split(' ')
    const lastName = otherNames.join(' ')

    this.firstName = firstName
    this.lastName = lastName
  }

  get isAthlete(): boolean {
    return !this.isStaff
  }

  set isAthlete(value: boolean) {
    this.isStaff = !value
  }

  get roleText(): string {
    return this.isStaff ? 'Staff' : 'Athlete'
  }

  get availabilityText(): string {
    return this.isAvailable ? 'Available' : 'Not available'
  }
}

export default PlayerModel
