import { Store, StoreConfig } from '@bryntum/schedulerpro'
import WorkloadModel from '@/components/bryntum/models/WorkloadModel'

class WorkloadStore extends Store {
  static $name = 'WorkloadStore'

  static get defaultConfig(): Partial<StoreConfig> {
    return {
      modelClass: WorkloadModel,
      sorters: [
        { field: 'fullName', ascending: true }
      ],
    }
  }
}

export default WorkloadStore
