




































































import { BryntumButton, BryntumCombo, BryntumDateField, BryntumWidget } from '@bryntum/schedulerpro-vue'
// import { DurationConfig } from '@bryntum/schedulerpro'
import {
  TodayButtonConfig,
  FitButtonConfig,
  LayoutButtonConfig,
  SizingButtonConfig,
  ThemeComboConfig,
  ViewPresetComboConfig,
} from '@/components/bryntum/configs/ToolbarConfigs'
import { ZOOM_LEVELS } from '@/components/bryntum/configs/ZoomLevelsConfig'
import { SchedulerConfig } from '@/components/bryntum/configs/SchedulerConfig'

interface DurationConfig {
  magnitude: number
  unit: string
}

export default {
  name: 'SchedulerToolbar',

  components: {
    BryntumButton,
    BryntumCombo,
    BryntumDateField,
    BryntumWidget,
  },

  props: {
    data: { type: Array, default: undefined },
    startDate: { type: Date, default: undefined },
    viewPreset: { type: [String, Object], default: undefined },
    selectedTheme: { type: String, default: undefined },
    fitColumns: { type: String, default: undefined },
    eventLayout: { type: String, default: undefined },
    columnWidth: { type: Number, default: undefined },
    tickHeight: { type: Number, default: undefined },
  },

  data() {
    return {
      TodayButtonConfig,
      FitButtonConfig,
      LayoutButtonConfig,
      ViewPresetComboConfig,
      SizingButtonConfig,
      ThemeComboConfig,
      SchedulerConfig,
    }
  },

  computed: {
    viewPresetId() {
      if (typeof this.viewPreset === 'string') {
        return this.viewPreset
      }
      if (typeof this.viewPreset === 'object') {
        return this.viewPreset.id
      }
      return undefined
    },
    datePickerStep() {
      let magnitude = 1
      let unit = 'day'

      if (this.viewPresetId && ZOOM_LEVELS[this.viewPresetId]) {
        magnitude = ZOOM_LEVELS[this.viewPresetId].shiftIncrement as number
        unit = ZOOM_LEVELS[this.viewPresetId].shiftUnit as string
      }

      const step: DurationConfig = {
        magnitude,
        unit,
      }

      return step
    }
  },

  watch: {
    fitColumns: function (value: string): void {
      this.setFitItems(value)
    },
    eventLayout: function (value: string): void {
      this.setLayoutItems(value)
    },
    columnWidth: function (value: string): void {
      this.setColumnWidth(value)
    },
    tickHeight: function (value: string): void {
      this.setTickHeight(value)
    },
  },

  mounted(): void {
    this.initFitButton()
    this.initLayoutButton()
    this.initSizingButton()
  },

  beforeDestroy(): void {
    const fitButton = this.$refs.fitButton.instance
    const layoutButton = this.$refs.layoutButton.instance
    const sizingButton = this.$refs.sizingButton.instance
    const { columnWidth, tickHeight } = sizingButton.menu.widgetMap

    fitButton.menu.un('item')
    layoutButton.menu.un('item')
    columnWidth.un('input')
    tickHeight.un('input')
  },

  methods: {
    themeItemRenderer(themeRecord: { id: string, name: string }): string {
      const isSelected = themeRecord.id === this.selectedTheme
      const cls = isSelected ? 'b-fa b-fa-check selected-item' : ''

      return `<i class="${cls}"></i> ${themeRecord.name}`
    },

    //region FitButton
    initFitButton(): void {
      const fitButton = this.$refs.fitButton.instance
      this.setFitItems(this.fitColumns, true)
      fitButton.menu.on('item', this.onFitSelection)
    },

    onFitSelection({ item }: any): void {
      this.setFitItems(item.ref)
    },

    setFitItems(value: string, isInit = false): void {
      const fitButton = this.$refs.fitButton.instance
      const { none, fill, fit } = fitButton.menu.widgetMap

      none.checked = value === 'none'
      fill.checked = value === 'fill'
      fit.checked = value === 'fit'

      this.$emit('update:fillWidth', fill.checked, isInit)
      this.$emit('update:fitWidth', fit.checked, isInit)
    },
    //endregion

    //region LayoutButton
    initLayoutButton(): void {
      const layoutButton = this.$refs.layoutButton.instance
      this.setLayoutItems(this.eventLayout, true)
      layoutButton.menu.on('item', this.onLayoutSelection)
    },

    onLayoutSelection({ item }: any): void {
      this.setLayoutItems(item.ref)
    },

    setLayoutItems(value: string, isInit = false): void {
      const layoutButton = this.$refs.layoutButton.instance
      const { none, pack, mixed } = layoutButton.menu.widgetMap

      none.checked = value === 'none'
      pack.checked = value === 'pack'
      mixed.checked = value === 'mixed'

      this.$emit('update:eventLayout', value, isInit)
    },
    //endregion

    //region SizingButton
    initSizingButton(): void {
      const sizingButton = this.$refs.sizingButton.instance
      const { columnWidth, tickHeight } = sizingButton.menu.widgetMap

      this.setColumnWidth(this.columnWidth)
      this.setTickHeight(this.tickHeight)

      columnWidth.on('input', this.onColumnWidthUpdate)
      tickHeight.on('input', this.onTickHeightUpdate)
    },

    setColumnWidth(value: number): void {
      const sizingButton = this.$refs.sizingButton.instance
      const { columnWidth } = sizingButton.menu.widgetMap

      columnWidth.value = value
    },

    onColumnWidthUpdate({ value }: { value: number }): void {
      this.$emit('update:columnWidth', value)
    },

    setTickHeight(value: number): void {
      const sizingButton = this.$refs.sizingButton.instance
      const { tickHeight } = sizingButton.menu.widgetMap

      tickHeight.value = value
    },

    onTickHeightUpdate({ value }: { value: number }): void {
      this.$emit('update:tickHeight', value)
    },
    //endregion
  }
}
