import { Store, StoreConfig } from '@bryntum/schedulerpro'
import GroupModel from '@/components/bryntum/models/GroupModel'

class GroupStore extends Store {
  static $name = 'GroupStore'

  static get defaultConfig(): Partial<StoreConfig> {
    return {
      modelClass: GroupModel,
      sorters: [
        { field: 'title', ascending: true }
      ]
    }
  }
}

export default GroupStore
