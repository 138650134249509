import { DataFieldConfig, Model, ObjectHelper } from '@bryntum/schedulerpro'
import SessionElementModel, { SessionElementRawObject } from '@/components/bryntum/models/SessionElementModel'

// Implicitly adds new fields to an existing interface
interface ElementWorkloadModel {
  id: string | number
  sessionElementId: number
  title: string
  rpe: number
  duration: number
  actualDuration: number | null
  _workload: number
}

// Types of the raw fields
export interface ElementWorkloadRawObject {
  id: string | number
  element_id: number
  element_title: string
  rpe: number
  duration: number
  actual_duration: number | null
  workload: number
}

// Raw fields that represent element workload
const ELEMENT_WORKLOAD_RAW_OBJECT_PROPS = [
  'id',
  'element_id',
  'element_title',
  'rpe',
  'duration',
  'actual_duration',
  'workload',
]

// Explicitly extends Bryntum types and adds missing types
// TODO: remove once the issue is fixed: https://github.com/bryntum/support/issues/5017
interface DataFieldConfigExtension extends DataFieldConfig {
  type: string
}

/**
 * ElementWorkloadModel is used to describe workload in events[].player_workloads[].player_element_workloads[].
 * We store player's workload data based on elements this player is assigned to
 * and actual duration player spent to the element/activity.
 */
class ElementWorkloadModel extends Model {
  static $name = 'ElementWorkloadModel'

  static createRawElementWorkloadBasedOnRawSessionElement(rawSessionElement: SessionElementRawObject): Partial<ElementWorkloadRawObject> {
    return {
      element_id: rawSessionElement.element_id,
      element_title: rawSessionElement.element_title,
      rpe: rawSessionElement.rpe,
      duration: rawSessionElement.duration,
      actual_duration: null,
      workload: rawSessionElement.workload,
    }
  }

  static createElementWorkloadBasedOnSessionElement(sessionElementRecord: SessionElementModel, actualDuration: number | null): ElementWorkloadModel {
    return new ElementWorkloadModel(
      {
        sessionElementId: sessionElementRecord.elementId,
        title: sessionElementRecord.title,
        rpe: sessionElementRecord.rpe,
        duration: sessionElementRecord.duration,
        actualDuration
      }
    )
  }

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      { name: 'sessionElementId', type: 'number', dataSource: 'element_id' },
      { name: 'title', type: 'string', dataSource: 'element_title' },
      { name: 'rpe', type: 'number', defaultValue: 0 },
      { name: 'duration', type: 'number', defaultValue: 0 },
      { name: 'actualDuration', type: 'number', defaultValue: null, dataSource: 'actual_duration' },
      { name: 'workload', type: 'number', defaultValue: 0 },
    ]
  }

  get isPlayerSessionElementModel(): boolean {
    return true
  }

  set workload(value: number | null) {
    this._workload = this.calculateWorkload
  }

  get workload(): number {
    return this.calculateWorkload
  }

  get calculateWorkload(): number {
    return this.rpe * (this.actualDuration || this.duration)
  }

  get elementWorkloadJSON(): Partial<ElementWorkloadRawObject> {
    const result = {} as Partial<ElementWorkloadRawObject>
    const rawData = this.toJSON()

    ObjectHelper.copyProperties(result, rawData, ELEMENT_WORKLOAD_RAW_OBJECT_PROPS)

    if (this.isPhantom) {
      delete result.id
    }

    return result
  }

  public isBasedOnSessionElement(sessionElementRecord: SessionElementModel): boolean {
    return this.sessionElementId === sessionElementRecord.elementId
  }
}

export default ElementWorkloadModel
