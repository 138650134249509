import { Store, StoreConfig } from '@bryntum/schedulerpro'
import SurfaceModel from '@/components/bryntum/models/SurfaceModel'

class SurfaceStore extends Store {
  static $name = 'SurfaceStore'

  static get defaultConfig(): Partial<StoreConfig> {
    return {
      modelClass: SurfaceModel,
      sorters: [
        { field: 'title', ascending: true }
      ]
    }
  }
}

export default SurfaceStore
