import VueAxios from '@/api/VueAxios'
import SnackbarError from '@/components/snackbar/SnackbarError'

declare global {
    interface Window {
        $cookies: any;
    }
}

const Player = {
    namespaced: true,
    state: {
        list: [],
        ballets: [],
        pianists: [],
        dancers: []
    },
    mutations: {
        SET_LIST(state: any, payload: any) {
            Player.state.list = payload
        },
        SET_BALLETS(state: any, payload: any) {
            Player.state.ballets = payload
        },
        SET_PIANISTS(state: any, payload: any) {
            Player.state.pianists = payload
        },
        SET_DANCERS(state: any, payload: any) {
            Player.state.dancers = payload
        }
    },
    actions: {
        async FETCH_LIST({commit, dispatch}: any, filters: { key: string, value: number|string }[] = []) {
            try {
                let params = ''
                if (filters.length) {
                    // ?filter[sport_position_id]=99&filter[sport_id]=5
                    params = '?' + filters.map(filter => `filter[${filter.key}]=${filter.value}`).join('&')
                }
                const response = await VueAxios.get('/players' + params)
                const data = response.data
                const list = data.sort((a: any, b: any) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
                await commit('SET_LIST', list)

                return list
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
        FETCH_BALLETS({commit, dispatch}: any, payload:any) {
           commit('SET_BALLETS', payload)
        },
        FETCH_PIANISTS({commit, dispatch}: any, payload:any) {
            commit('SET_PIANISTS', payload)
        },
        async FETCH_DANCERS({commit, dispatch}: any, payload:any) {
            commit('SET_DANCERS', payload)
        }
    },
    getters: {
        getList(state: any) {
            return Player.state.list
        },
        getDancers(state: any) {
            return Player.state.dancers
        },
        getBalletMasters(state: any) {
            return Player.state.ballets
        },
        getPianists(state: any) {
            return Player.state.pianists
        }
    },

}

export default Player
