<template>
  <div class="calendar-dashboard fill-height">
    <scheduler-toolbar
      :start-date="startDate"
      :view-preset="viewPreset"
      :selected-theme="selectedTheme"
      :fit-columns="fitColumns"
      :event-layout="eventLayout"
      :column-width="columnWidth"
      :tick-height="tickHeight"
      @update:startDate="updateStartDate"
      @update:viewPreset="updateViewPreset"
      @update:selectedTheme="updateSelectedTheme"
      @update:fillWidth="updateFillWidth"
      @update:fitWidth="updateFitWidth"
      @update:eventLayout="updateEventLayout"
      @update:columnWidth="updateColumnWidth"
      @update:tickHeight="updateTickHeight"
      @pdfExportInit="verticalPdfExport"
    />
    <div class="calendar-dashboard-container">
      <div
        ref="schedulerContainer"
        class="calendar-dashboard-column scheduler-container"
        :class="{
          'scheduler-container-export': isExport,
          'export-landscape': isExport && exportConfig.pageOrientation === 'landscape',
          'export-portrait': isExport && exportConfig.pageOrientation === 'portrait',
          'export-font-size-0-5': isExport && exportConfig.sessionFontSize === '0.5em',
          'export-font-size-0-6': isExport && exportConfig.sessionFontSize === '0.6em',
          'export-font-size-0-7': isExport && exportConfig.sessionFontSize === '0.7em',
          'export-font-size-0-8': isExport && exportConfig.sessionFontSize === '0.8em',
          'export-font-size-0-9': isExport && exportConfig.sessionFontSize === '0.9em',
          'export-font-size-1-0': isExport && exportConfig.sessionFontSize === '1em',
          'export-font-size-1-1': isExport && exportConfig.sessionFontSize === '1.1em',
          'export-font-size-1-2': isExport && exportConfig.sessionFontSize === '1.2em',
          'export-font-size-1-3': isExport && exportConfig.sessionFontSize === '1.3em',
          'export-font-size-1-4': isExport && exportConfig.sessionFontSize === '1.4em',
          'export-font-size-1-5': isExport && exportConfig.sessionFontSize === '1.5em',
        }"
        :style="exportContainerStyles"
      >
        <div
          ref="exportScheduleHeader"
          class="export-schedule-header"
        >
          <div class="title-row">
            <div class="logo-col">
              <v-img src="/img/logo-small.png" />
            </div>
            <div class="name-col">{{ selectedSquadName }}</div>
            <div class="week-date-col">{{ formattedWeekStartDate }}</div>
          </div>
          <div class="body-row">
            <div class="date-col">{{ formattedStartDate }}</div>
            <div class="note-col">{{ exportConfig.comment }}</div>
          </div>
        </div>
        <bryntum-scheduler-pro
          ref="verticalScheduler"
          v-bind="SchedulerConfig"
          cls="vertical-scheduler"
          :resources="studios"
          :view-preset="viewPreset"
        />
        <div
          ref="exportScheduleFooter"
          class="export-schedule-footer"
        >
        </div>
      </div>
      <div class="calendar-dashboard-column sidebar-container">
        <div>
          <v-btn
            elevation="2"
            icon
            class="sidebar-collapse-button"
            @click="() => showSidebar = !showSidebar"
          >
            <v-icon v-if="!showSidebar">
              mdi-chevron-left
            </v-icon>
            <v-icon v-if="showSidebar">
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </div>
        <div
          v-if="showSidebar"
          class="sidebar-collapsible-container"
        >
          <div
            v-if="squadsStore"
            class="sidebar-top-container"
          >
            <!-- v-model doesn't work in bryntum-combo  -->
            <!-- :items is a static, warning in console, dont use it -->
            <bryntum-combo
              flex="1"
              :store="squadsStore"
              label="Select Squad"
              display-field="title"
              value-field="id"
              :value="selectedSquad"
              @select="({record}) => selectedSquad = record"
            />
          </div>
          <!-- Groups grid -->
          <div class="sidebar-grids">
            <group-grid
              :data="participants.playerGroups"
              :is-admin="isAdmin"
              @grid-mounted="groupGridMounted"
              @grid-unmounted="groupGridUnmounted"
            />
            <player-grid
              :data="participants.allPlayers"
              :is-admin="isAdmin"
              @grid-mounted="playerGridMounted"
              @grid-unmounted="playerGridUnmounted"
            />
            <session-template-grid
              :data="rawEventTemplates"
              :is-admin="isAdmin"
              @grid-mounted="sessionTemplateGridMounted"
              @grid-unmounted="sessionTemplateGridUnmounted"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import html2pdf from 'html2pdf.js/src'
import { SchedulerConfig } from '@/components/bryntum/configs/SchedulerConfig'
import { ZOOM_LEVELS } from '@/components/bryntum/configs/ZoomLevelsConfig'
import SessionTemplateGrid from '@/components/SessionTemplateGrid'
import SchedulerToolbar from '@/components/SchedulerToolbar'
import GroupGrid from '@/components/GroupGrid'
import PlayerGrid from '@/components/PlayerGrid'
import GroupDrag from '@/components/bryntum/drag/GroupDrag.js'
import PlayerDrag from '@/components/bryntum/drag/PlayerDrag.js'
import SessionTemplateDrag from '@/components/bryntum/drag/SessionTemplateDrag.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import auth from '@/store/components/Auth'
import { BryntumCombo, BryntumSchedulerPro } from '@bryntum/schedulerpro-vue'
import { AsyncHelper, DateHelper, MessageDialog, ObjectHelper, Popup, Store, Toast } from '@bryntum/schedulerpro'
import loadPlayers from '@/api/Player'
import loadStudios from '@/api/Studio'
import loadSurfaces from '@/api/Surface'
import loadEventTemplates from '@/api/EventTemplate'
import { eventBus } from '@/helpers/EventBus'
import AssignmentValidatorPopup from '@/components/bryntum/widgets/AssignmentValidatorPopup'
import EventTypeModel from '@/components/bryntum/models/EventTypeModel'
import SessionTemplateModel from '@/components/bryntum/models/SessionTemplateModel'
import BenchmarkDateHelper from '@/components/bryntum/helper/BenchmarkDateHelper'
import { getSelectedTheme, setSelectedTheme } from '@/components/bryntum/configs/ThemeSwitcherConfig'
import SessionElementStore from '@/components/bryntum/stores/SessionElementStore'
import ElementWorkloadModel from '@/components/bryntum/models/ElementWorkloadModel'
import {
  CONFIRM_DELETE_PUBLISHED_SESSION,
  CONFIRM_DELETE_SESSION,
  CONFIRM_UPDATE_PUBLISHED_SESSION
} from '@/components/bryntum/configs/MessageDialogConfirmationConfig'
import { COLUMN_WIDTH_MAX, COLUMN_WIDTH_MIN } from '@/components/bryntum/configs/ToolbarConfigs'
import { TICK_HEIGHT_MAX, TICK_HEIGHT_MIN } from '@/components/bryntum/configs/ToolbarConfigs'

const WEEK_START_DAY = SchedulerConfig.weekStartDay
const DEFAULT_ZOOM_LEVEL_ID = SchedulerConfig.viewPreset
const FIRST_ITEM = 0
const SECOND_ITEM = 1

export default {
  name: 'CalendarDashboard',

  components: {
    BryntumCombo,
    SchedulerToolbar,
    GroupGrid,
    PlayerGrid,
    BryntumSchedulerPro,
    SessionTemplateGrid
  },

  beforeRouteEnter(to, from, next) {
    const { view = '', date = '', x = '', y = '' } = to.query

    const isViewQueryValid = ['day', 'week', 'month'].includes(view)
    const initView = isViewQueryValid ? view : DEFAULT_ZOOM_LEVEL_ID.split('-')[SECOND_ITEM]
    const initZoomLevelId = `benchmark-${initView}`
    const { shiftUnit, shiftIncrement, tickWidth, tickHeight } = ZOOM_LEVELS[initZoomLevelId]
    SchedulerConfig.viewPreset = initZoomLevelId

    const isDateQueryValid = BenchmarkDateHelper.isValidDateFormat(date)
    const initDate = isDateQueryValid ? date : BenchmarkDateHelper.format(new Date(), 'YYYY-MM-DD')
    const parsedDate = BenchmarkDateHelper.parse(initDate, 'YYYY-MM-DD')
    const initStartDate = BenchmarkDateHelper.startOf(parsedDate, shiftUnit, true, WEEK_START_DAY)
    const initEndDate = BenchmarkDateHelper.add(initStartDate, shiftIncrement, shiftUnit)

    const parsedX = parseInt(x)
    const isValidColumnWidth = !isNaN(parsedX) && parsedX >= COLUMN_WIDTH_MIN && parsedX <= COLUMN_WIDTH_MAX
    const initColumnWidth = isValidColumnWidth ? parsedX : tickWidth
    SchedulerConfig.resourceColumns.columnWidth = initColumnWidth

    const parsedY = parseInt(y)
    const isValidTickHeight = !isNaN(parsedY) && parsedY >= TICK_HEIGHT_MIN && parsedY <= TICK_HEIGHT_MAX
    const initTickHeight = isValidTickHeight ? parsedY : tickHeight
    SchedulerConfig.tickSize = initTickHeight

    const query = {
      view: initView,
      date: initDate,
      x: `${initColumnWidth}`,
      y: `${initTickHeight}`,
    }

    const isQueryValid = isViewQueryValid && isDateQueryValid && isValidColumnWidth && isValidTickHeight

    next(vm => {
      vm.startDate = initStartDate
      vm.endDate = initEndDate
      vm.viewPreset = initZoomLevelId
      vm.columnWidth = initColumnWidth
      vm.tickHeight = initTickHeight

      if (!isQueryValid) {
        vm.$router.replace({
          ...to,
          query,
        })
      }
    })
  },

  data: () => ({
    SchedulerConfig,
    scheduler: {},
    startDate: null,
    endDate: null,
    viewPreset: DEFAULT_ZOOM_LEVEL_ID,
    selectedTheme: getSelectedTheme(),
    fitColumns: 'none', // Options: 'none', 'fill', 'fit'
    eventLayout: 'none', // Options: 'none', 'pack', 'mixed'
    columnWidth: 100,
    tickHeight: 100,
    studios: [],
    participants: {
      allPlayers: [],
      playerGroups: []
    },
    surfaces: [],
    rawEventTemplates: [],
    isAdmin: false,
    isExport: false,
    exportConfig: {
      pageFormat: 'a4',
      a4PaperLongSidePx: 1122,
      a4PaperShortSidePx: 794,
      pageOrientation: 'landscape',
      fitHeight: true,
      pageCount: 1,
      sessionFontSize: '1em',
      filterTicks: true,
      filterLocations: true,
      comment: '',
      // Will be set dynamically during export
      exportHeight: 0,
      exportWidth: 0,
    },
    sessionDetailView: true, // TODO: Add a button to the toolbar to make it configurable
    showEditor: false,
    events: [],
    currentEvent: {},
    selectedSquad: null,
    showSidebar: true,
    groupGridDragHelper: null,
    playerGridDragHelper: null,
    sessionTemplateGridDragHelper: null,
    squadsStore: null,
    // Copied sessions might be pasted into a different day, time, and location. (on schedule click)
    sessionPerTimeClipboard: null,
    // Copied sessions might be pasted into a different day, and location. Time is preserved. (on resource header click)
    sessionsPerLocationClipboard: null,
    // Copied sessions might be pasted into a different day. Time and location are preserved. (on timeline click)
    sessionsPerDayClipboard: null,
  }),

  computed: {
    ...mapGetters({
      loading: 'Index/loading',
      getSquads: 'Event/getSquads',
      getSurfaces: 'Surface/getSurfaces',
      getPlayerGroups: 'PlayerGroup/getPlayerGroups',
      getEventTypes: 'EventType/getEventTypes',
      getEventTemplates: 'EventTemplate/getList'
    }),

    formattedStartDate() {
      // "Friday, 22nd March 2024"
      return this.startDate ? DateHelper.format(this.startDate, 'dddd, Do MMMM YYYY') : ''
    },

    formattedWeekStartDate() {
      if (!this.startDate) {
        return ''
      }

      const startOfWeek = BenchmarkDateHelper.startOf(this.startDate, 'week', true, WEEK_START_DAY)

      // "Week 22nd March 2024"
      return `Week ${DateHelper.format(startOfWeek, 'Do MMMM YYYY')}`
    },

    selectedSquadName() {
      return this.selectedSquad?.title || ''
    },

    showSessionDetails() {
      return this.isExport || this.sessionDetailView
    },

    exportContainerStyles () {
      if (this.isExport) {
        return {
          'min-height': `${this.exportConfig.exportHeight}px`,
          'height': `${this.exportConfig.exportHeight}px`,
          'max-height': `${this.exportConfig.exportHeight}px`,
          'min-width': `${this.exportConfig.exportWidth}px`,
          'width': `${this.exportConfig.exportWidth}px`,
          'max-width': `${this.exportConfig.exportWidth}px`,
        }
      }

      return {}
    },

    squads() {
      return this.getSquads
    },

    groups() {
      return this.getPlayerGroups
    },

    eventTypes() {
      return this.getEventTypes
    },

    eventTemplates() {
      return this.getEventTemplates
    }
  },
  watch: {
    selectedSquad: {
      handler: async function (squadId) {
        eventBus.$emit('reloadGrid')
      }
    },
    startDate(val) {
      const date = BenchmarkDateHelper.format(val, 'YYYY-MM-DD')

      if (this.$route.query.date !== date) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            date,
          },
        })
      }
    },
    viewPreset(val) {
      const view = val.split('-')[SECOND_ITEM]

      if (this.$route.query.view !== view) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            view,
          },
        })
      }
    },
    columnWidth(val) {
      const x = `${val}`

      if (this.$route.query.x !== x) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            x,
          },
        })
      }
    },
    tickHeight(val) {
      const y = `${val}`

      if (this.$route.query.y !== y) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...this.$route.query,
            y,
          },
        })
      }
    },
  },

  beforeMount() {
    this.setLoading(true)
  },

  async mounted() {
    // Setting theme requires initial HTML to be rendered
    setSelectedTheme(this.selectedTheme)

    // Fetch Squads first!
    await this.fetchSquads()
    await this.fetchEventTypes()

    this.squadsStore = new Store({ data: this.squads })
    this.selectedSquad = this.squads[FIRST_ITEM] ? this.squads[FIRST_ITEM] : null

    // selectedSquad should be set before we call for data
    await loadSurfaces(this)
    await loadStudios(this)
    await loadPlayers(this)
    await loadEventTemplates(this)

    // Set user rights
    this.isAdmin = auth.state.user.roles.includes('admin')

    const scheduler = this.$refs.verticalScheduler.instance
    this.scheduler = scheduler

    scheduler.setTimeSpan(this.startDate, this.endDate)

    await this.fetchAndLoadEvents()

    //region Init toolbar items
    this.initFitColumns()
    this.initEventLayout()
    // No need to init here since it's been init in beforeRouteEnter
    // this.initColumnWidth()
    // this.initTickHeight()
    //endregion

    this.$nextTick(() => {
      /**
       * https://www.bryntum.com/docs/scheduler-pro/api/Core/data/Store#events
       */
      scheduler.eventStore.on({
        update: this.onEventStoreUpdate,
        remove: this.onSessionDelete,
        addConfirmed: this.onSessionAdded,
        add: this.onSessionAdd,
        thisObj: scheduler
      })

      scheduler.on({
        //region Task editing
        beforeTaskEdit: this.onBeforeTaskEdit,
        beforeTaskEditShow: this.onBeforeTaskEditorShow,
        beforeTaskSave: this.onBeforeTaskEditorSave,
        afterTaskSave: this.onAfterTaskEditorSave,
        afterTaskEdit: this.onAfterTaskEdit,
        taskEditCanceled: this.onAfterTaskEditCanceled,
        //endregion
        //region Copy-pasting
        scheduleMenuBeforeShow: this.onScheduleMenuBeforeShow,
        copySessionPerTime: this.onCopySessionPerTime,
        pasteSessionPerTime: this.onPasteSessionPerTime,
        //endregion
        //region Publishing
        publishSession: this.publishSession,
        unpublishSession: this.unpublishSession,
        resetToLastPublishedSession: this.resetToLastPublishedSession,
        //endregion
        beforeEventDropFinalize: this.onBeforeEventDropFinalize,
        sessionTemplateDrop: this.onSessionTemplateDrop,
        playerDrop: this.onPlayerDrop,
        groupDrop: this.onGroupDrop,
        dateRangeChange: this.fetchAndLoadEvents,
        beforeDestroy: this.onSchedulerBeforeDestroy
      })
    })

    this.setLoading(false)

    eventBus.$on('reloadGrid', async () => {
      if (this.loading) {
        return
      }
      await loadStudios(this)
      await loadPlayers(this)
      scheduler.eventStore.suspendEvents(false)
      await this.fetchAndLoadEvents()
      scheduler.eventStore.resumeEvents()
    })

    return true
  },

  methods: {
    ...mapMutations({
      setLoading: 'Index/SET_LOADING',
      setError: 'Index/SET_SNACKBAR_ERROR',
    }),
    ...mapActions({
      deleteEvent: 'Event/DELETE',
      fetchEvent: 'Event/VIEW',
      createEvent: 'Event/STORE',
      updateEvent: 'Event/UPDATE',
      fetchStudios: 'Studio/FETCH_LIST',
      fetchEventTemplates: 'EventTemplate/FETCH_LIST',
      fetchPlayers: 'Player/FETCH_LIST',
      fetchBallets: 'Player/FETCH_BALLETS',
      fetchPianists: 'Player/FETCH_PIANISTS',
      fetchDancers: 'Player/FETCH_DANCERS',
      fetchPlayerGroups: 'PlayerGroup/FETCH_LIST',
      fetchSurfaces: 'Surface/FETCH_LIST',
      fetchEvents: 'Event/FETCH_LIST',
      fetchSquads: 'Event/FETCH_SQUADS',
      fetchSquadEvent: 'Event/FETCH_SQUAD_EVENT',
      setEditorStatus: 'Event/EDITOR_STATUS',
      fetchEventTypes: 'EventType/FETCH_LIST'
    }),

    onSchedulerBeforeDestroy() {
      eventBus.$off('reloadGrid')
    },

    async onEventStoreUpdate({ record, source: eventStore, changes }) {
      const { isEditing } = this.scheduler.features.taskEdit
      const isNewSession = record.isPhantom
      const isSync = record.isSessionSync

      /**
       * Do not react on update when we create event
       * Do not react on update when we edit event
       * Do not react on update when we sync event data
       */
      if (isNewSession || isEditing || isSync) {
        return
      }

      await this.saveSession({ record })
      eventStore.commit()
    },

    async onBeforeTaskEditorSave({ source: schedule, taskRecord: record, editor }) {
      editor.mask({
        text:'Saving...'
      })
      await this.saveSession({ record })
      schedule.eventStore.commit()
      editor.unmask()
    },

    async onAfterTaskEditorSave({ editor }) {
      const { workloadGrid, elementsGrid } = editor.widgetMap
      // Commit changes, so they cannot be reverted by next cancel
      workloadGrid.store.commit()
      elementsGrid.store.commit()

      // In case we want to fast-sync backend data and frontend records after editing, fetch and load events.
      // await this.fetchAndLoadEvents()
    },

    onAfterTaskEdit({ source: scheduler, taskRecord, editor }) {
      const { tabs, elementsGrid } = editor.widgetMap
      // Reset active tab, so the 1st tab is shown next time
      tabs.activeTab = 0

      elementsGrid.store.destroy()

      /**
       * TODO check with Bryntum examples and report the issue
       * Refresh scheduler manually, because pro scheduler mode='vertical'
       * doesn't remove event from DOM when we cancel event creation
       */
      if (taskRecord.isPhantom) {
        scheduler.refresh()
      }
    },

    onAfterTaskEditCanceled({ editor }) {
      const { workloadGrid, elementsGrid } = editor.widgetMap
      // Revert all changes done to grids
      workloadGrid.store.revertChanges()
      elementsGrid.store.revertChanges()
    },

    onBeforeTaskEdit({ source: scheduler, taskRecord: sessionRecord }) {
      const {
        sessionTemplateField,
        playerGroupsField,
        playersField,
        surfaceField,
        elementsGrid,
        workloadGrid,
      } = scheduler.features.taskEdit.editor.widgetMap

      sessionTemplateField.store.data = [...this.eventTemplates]
      playerGroupsField.store.data = [...this.participants.playerGroups]
      playersField.store.data = [...this.participants.allPlayers]
      surfaceField.store.data = [...this.surfaces]

      const assignedAthletes = sessionRecord.players.filter((rec) => rec.isAthlete)
      // elementsGrid.store.data = [...sessionRecord.sessionElements]
      elementsGrid.store = new SessionElementStore({
        data: [...sessionRecord.sessionElements]
      })

      // When we assign/unassign players, we need to add/remove ElementWorkload records for the player
      elementsGrid.store.on({
        update: () => {
          this.updateElementWorkloadData(sessionRecord, elementsGrid.store)
        }
      })

      const sessionElementPlayersColumn = elementsGrid.columns.get('playerIds')
      const sessionElementPlayersEditor = sessionElementPlayersColumn.editor
      sessionElementPlayersEditor.store.data = [...assignedAthletes]

      workloadGrid.store.data = [...sessionRecord.playerWorkloads]
    },

    onBeforeTaskEditorShow({ editor, taskRecord: sessionRecord }) {
      editor.title = sessionRecord.isPhantom ? 'New Session' : 'Edit Session'
      const {
        publishButton,
        draftButton,
        resetToPublishedButton,
        sessionTemplateField,
        elementsTab,
        tabs,
        workloadGrid
      } = editor.widgetMap

      // Disable it instead of hide it to prevent unwanted animation in v5.0.5
      // https://2amigoscg.atlassian.net/browse/SCHED-111
      // TODO: try to use hide option after library update
      elementsTab.disabled = sessionRecord.sessionElements.length === 0
      // elementsTab.hidden = sessionRecord.sessionElements.length === 0

      publishButton.hidden = sessionRecord.published
      draftButton.hidden = sessionRecord.draft
      resetToPublishedButton.hidden = sessionRecord.draft

      // TODO: "disabled" does not work, monitor this issue: https://forum.bryntum.com/viewtopic.php?t=23395
      sessionTemplateField.disabled = !!sessionRecord.sessionTemplate
      sessionTemplateField.readOnly = !!sessionRecord.sessionTemplate

      // Make sure the 1st tab is active
      tabs.activeTab = 0

      // Forward beforeHide to workloadGrid
      editor.on({
        beforeHide: () => {
          workloadGrid.trigger('editorBeforeHide', editor)
        },
        once: true,
      })
    },

    updateElementWorkloadData(sessionRecord, sessionElementStore) {
      const workloads = [...sessionRecord.playerWorkloads]

      workloads.forEach(workloadRecord => {
        const sessionElementRecords = sessionElementStore.elementsByPlayerId(workloadRecord.playerId)
        workloadRecord.updatePlayerElementWorkloads(sessionElementRecords)
      })

      sessionRecord.playerWorkloads = workloads
    },

    onScheduleMenuBeforeShow({ items }) {
      if (this.sessionPerTimeClipboard == null) {
        items.pasteEvent = false
      }
    },

    onCopySessionPerTime({ sessionRecord }) {
      this.sessionPerTimeClipboard = this.mapRecordForData(sessionRecord)
    },

    onPasteSessionPerTime({ startDate, locationRecord }) {
      const schedule = this.$refs.verticalScheduler.instance

      if (this.sessionPerTimeClipboard == null || !schedule) {
        return
      }

      const { duration, duration_unit } = this.sessionPerTimeClipboard
      const endDate = DateHelper.add(startDate, duration, duration_unit)

      const eventData = this.clearIdentitiesFromSessionData({
        ...this.mapDataForRecord(this.sessionPerTimeClipboard),
        resourceId: locationRecord.id,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })

      const record = schedule.eventStore.createRecord(eventData)
      schedule.eventStore.append(record)
    },

    clearIdentitiesFromSessionData(eventData) {
      // Clear session ID so a new record is created
      delete eventData.id

      // Clear player workload record IDs so new records are created
      const playerWorkloads = eventData.playerWorkloads || eventData.player_workloads
      playerWorkloads.forEach(playerWorkload => {
        delete playerWorkload.id

        // Clear player element workload record IDs so new records are created
        playerWorkload.player_element_workloads.forEach(playerElementWorkload => {
          delete playerElementWorkload.id
        })
      })

      return eventData
    },

    async onSessionTemplateDrop({ schedule, data }) {
      const eventData = this.mapDataForRecord(data)
      const record = schedule.eventStore.createRecord(eventData)
      schedule.eventStore.append(record)
    },

    onSessionAdd({ source: eventStore, records }) {
      records.forEach(async (record) => {
        if (!record.isCreating) {
          // To be sure a new added session will be marked as 'draft' (for the copy action for example)
          record.draft = true
          await this.saveSession({ record })
          eventStore.commit()
        }
      })
    },

    onSessionAdded({ source: eventStore }) {
      eventStore.commit()
    },

    async onPlayerDrop({ schedule, targetSession, player }) {
      await this.processDD(
        { schedule, targetSession, draggedPlayers: [player] }
      )
    },

    async onGroupDrop({ schedule, targetSession, group }) {
      await this.processDD(
        { schedule, targetSession, draggedPlayers: group.players, group }
      )
    },

    async processDD({ schedule, targetSession, draggedPlayers, group }) {
      if (targetSession.published) {
        const result = await MessageDialog.confirm({ ...CONFIRM_UPDATE_PUBLISHED_SESSION })
        // User cancelled update. Do not save changes.
        if (result !== MessageDialog.okButton) {
          return
        }
      }

      const rangeSessions = schedule.eventStore.getEvents({
        startDate: targetSession.startDate,
        endDate: targetSession.endDate
      })

      if (group) {
        schedule.eventStore.suspendEvents(false)
        targetSession.assignGroup(group)
        schedule.eventStore.resumeEvents()
      }

      targetSession.assignPlayers(draggedPlayers)

      if (rangeSessions.length > 1) {
        const validatorConfig = { targetSession, rangeSessions, selectedPlayers: draggedPlayers }
        //TODO Check warning
        const validatorPopup = new AssignmentValidatorPopup(validatorConfig)
        if (validatorPopup.hasIntersections()) {
          await validatorPopup.validate()
        }
      }
    },

    async onBeforeEventDropFinalize({ context }) {
      const { eventRecord: sessionRecord } = context

      if (sessionRecord.published) {
        context.async = true

        const result = await MessageDialog.confirm({ ...CONFIRM_UPDATE_PUBLISHED_SESSION })

        // `true` to accept the changes or `false` to reject them
        context.finalize(result === MessageDialog.yesButton)
      }
    },

    rollbackDeletion() {
      this.scheduler.eventStore.revertChanges()
      this.scheduler.refresh()
    },

    confirmDeletion() {
      this.scheduler.eventStore.commit()
      this.scheduler.refresh()
    },

    async onSessionDelete({ source: eventStore, records }) {
      const sessionRecord = records[FIRST_ITEM]

      if (sessionRecord.isPhantom) {
        return this.confirmDeletion()
      }

      if (sessionRecord.published) {
        const result = await MessageDialog.confirm({ ...CONFIRM_DELETE_PUBLISHED_SESSION })
        // User cancelled deletion. Do not delete session.
        if (result !== MessageDialog.okButton) {
          return this.rollbackDeletion()
        }

        const snapshotRecord = eventStore.getById(sessionRecord.snapshotId)

        if (!snapshotRecord) {
          this.setError(`Cannot find snapshot with ID ${sessionRecord.snapshotId}`)
          return this.rollbackDeletion()
        }

        this.scheduler.mask({
          text:'Deleting the snapshot...'
        })

        try {
          await this.deleteEvent(snapshotRecord.id)
        } catch (error) {
          this.scheduler.unmask()
          return this.rollbackDeletion()
        }

        eventStore.suspendEvents()
        eventStore.remove(snapshotRecord.id)
        eventStore.resumeEvents()

        this.scheduler.unmask()
      } else {
        const result = await MessageDialog.confirm({ ...CONFIRM_DELETE_SESSION })
        // User cancelled deletion. Do not delete session.
        if (result !== MessageDialog.okButton) {
          return this.rollbackDeletion()
        }
      }

      this.scheduler.mask({
        text:'Deleting the session...'
      })

      try {
        await this.deleteEvent(sessionRecord.id)
      } catch (error) {
        this.scheduler.unmask()
        return this.rollbackDeletion()
      }

      this.scheduler.unmask()

      return this.confirmDeletion()
    },

    async fetchAndLoadEvents() {
      // Scheduler is expected to be initialised when this function is called
      const startDate = this.scheduler.startDate
      const endDate = this.scheduler.endDate

      this.scheduler.mask('Fetching events...')

      const events = await this.fetchEventsForDates({ startDate, endDate })

      if (this.scheduler.eventStore) {
        console.info(`${events.length} record(s) are loaded to the store`)
        await this.scheduler.eventStore.loadDataAsync(events)
      }

      this.filterOutSnapshots()

      this.scheduler.unmask()
    },

    async fetchEventsForDates({ startDate, endDate }) {
      // Workaround for https://2amigoscg.atlassian.net/browse/BEN-1650
      // Add offset to start/end dates to grab more events
      const offset = 1 // day
      const filterStartDate = DateHelper.add(startDate, -offset, 'day')
      const filterEndDate = DateHelper.add(endDate, offset, 'day')

      const startDateDay = DateHelper.format(filterStartDate, 'DD-MM-YYYY')
      const endDateDay = DateHelper.format(filterEndDate, 'DD-MM-YYYY')
      const squadId = this.selectedSquad.id

      const sessionFilter = (session) => {
        let keepSessionInDataset = true

        // We check that only the start date is within the scheduler's visual date range
        const sessionStartDate = new Date(session.start_at)
        // sessionStartDate >= startDate and < endDate
        const isWithinRange = DateHelper.betweenLesser(sessionStartDate, startDate, endDate)

        if(!isWithinRange) {
          // While workaround for https://2amigoscg.atlassian.net/browse/BEN-1650
          // is used, it is only an info message.
          console.info(
            '[Date Issue] The session start date is outside of the scheduler date range.\n',
            `${session.id} - Session ID.\n`,
            `${sessionStartDate} - Session start date.\n`,
            `${startDate} - Scheduler start date.\n`,
            `${endDate} - Scheduler end date.\n`,
            session
          )
          keepSessionInDataset = false
        }

        const isLocationFound = Boolean(this.studios.find((studio) => studio.id === session.studio_id))

        if(!isLocationFound) {
          console.warn(
            '[Location Issue] The session is in a location that does not exist.\n',
            `${session.id} - Session ID.\n`,
            `${session.studio_id} - Location ID.\n`,
            session
          )
          keepSessionInDataset = false
        }

        return keepSessionInDataset
      }

      console.info(`%cFetching events for squad ${squadId} from ${startDateDay} to ${endDateDay}`, 'color:green; font-weight: bold')
      const rawSessions = await this.fetchEvents({ startDateDay, endDateDay, squadId })
      console.info(`${rawSessions.length} record(s) are fetched from server`)

      console.groupCollapsed('Events validation')
      const events = rawSessions.filter(sessionFilter).map((session) => this.mapDataForRecord(session))
      console.groupEnd()

      return events
    },

    async saveSession({ record: sessionRecord }) {
      const sessionRawObject = this.mapRecordForData(sessionRecord)
      const isNewSession = sessionRecord.isPhantom

      let response

      if (isNewSession) {
        response = await this.createEvent(sessionRawObject)
      } else {
        response = await this.updateEvent(sessionRawObject)
      }

      const savedSessionRawObject = this.mapDataForRecord(response)

      sessionRecord.isSessionSync = true
      await sessionRecord.setAsync({ ...savedSessionRawObject })
      sessionRecord.isSessionSync = false

      return sessionRecord
    },

    async publishSession({ scheduler, record: sessionRecord, editor }) {
      editor.mask({
        text:'Publishing...'
      })

      const sessionRawObject = this.mapRecordForData(sessionRecord)
      const sessionRawData = this.clearIdentitiesFromSessionData({
        ...sessionRawObject,
        is_snapshot: true,
      })

      let response = null

      try {
        response = await this.createEvent(sessionRawData)
      } catch (error) {
        editor.unmask()
        return
      }

      const savedSessionRawObject = this.mapDataForRecord(response)

      // true is for silence
      scheduler.eventStore.add(savedSessionRawObject, true)

      editor.unmask()

      sessionRecord.published = true
      sessionRecord.snapshotId = savedSessionRawObject.id
      editor.onSaveClick()

      this.filterOutSnapshots()
    },

    async unpublishSession({ scheduler, record: sessionRecord, editor }) {
      const { eventStore } = scheduler
      const snapshotRecord = eventStore.getById(sessionRecord.snapshotId)

      if (!snapshotRecord) {
        this.setError(`Cannot find snapshot with ID ${sessionRecord.snapshotId}`)
        return
      }

      editor.mask({
        text: 'Unpublishing...'
      })

      try {
        await this.deleteEvent(snapshotRecord.id)
      } catch (error) {
        editor.unmask()
        return
      }

      eventStore.suspendEvents()
      eventStore.remove(snapshotRecord.id)
      eventStore.resumeEvents()

      this.scheduler.refresh()

      editor.unmask()

      // Reset snapshot info for the session
      await sessionRecord.setAsync({
        draft: true,
        snapshotId: null,
      })

      editor.onSaveClick()

      this.filterOutSnapshots()
    },

    async resetToLastPublishedSession({ scheduler, record: sessionRecord, editor }) {
      const { eventStore } = scheduler
      const snapshotRecord = eventStore.getById(sessionRecord.snapshotId)

      if (!snapshotRecord) {
        this.setError(`Cannot find snapshot with ID ${sessionRecord.snapshotId}`)
        return
      }

      editor.close()

      scheduler.mask({
        text:'Resetting to the last published session...'
      })

      // Delete actual session
      await this.deleteEvent(sessionRecord.id)

      eventStore.suspendEvents()
      eventStore.remove(sessionRecord.id)
      eventStore.resumeEvents()

      // Turn snapshot into a standalone session
      await snapshotRecord.setAsync({
        isSnapshot: false,
        readOnly: false,
      })

      await eventStore.commit()

      this.filterOutSnapshots()

      this.scheduler.refresh()

      scheduler.unmask()
    },

    filterOutSnapshots() {
      if (this.scheduler?.eventStore) {
        this.scheduler.eventStore.filter((record) => {
          return record.isSnapshot === false
        })
      }
    },

    async verticalPdfExport() {
      const schedule = this.$refs.verticalScheduler.instance

      if (schedule.eventStore.count === 0) {
        Toast.show('No data to export')
        return
      }

      // TODO: move component to another file
      const popup = new Popup({
        header: 'Export to PDF',
        autoShow: true,
        centered: true,
        closable: true,
        closeAction: 'destroy',
        width: '30em',
        defaults: {
          labelWidth: '8em',
        },
        items: {
          fileNameField: {
            type: 'textfield',
            label: 'File name',
            weight: 10,
            value: `schedule-${DateHelper.format(this.startDate, 'YYYY-MM-DD')}`,
            required: true,
          },
          commentField: {
            type: 'textarea',
            label: 'Note to the export',
            weight: 15,
            value: this.exportConfig.comment,
          },
          pageFormatField: {
            type: 'combo',
            label: 'Page format',
            weight: 20,
            displayText: 'text',
            valueField: 'id',
            editable: false,
            value: this.exportConfig.pageFormat,
            disabled: true,
            items: [
              { id: 'a4', text: 'A4' },
            ]
          },
          pageOrientationField: {
            type: 'combo',
            label: 'Page orientation',
            weight: 25,
            displayText: 'text',
            valueField: 'id',
            editable: false,
            value: this.exportConfig.pageOrientation,
            items: [
              { id: 'landscape', text: 'Landscape' },
              { id: 'portrait', text: 'Portrait' },
            ]
          },
          fitHeightField: {
            type: 'checkbox',
            text: 'Fit height',
            label: ' ',
            weight: 30,
            value: this.exportConfig.fitHeight,
            disabled: true, // disabled until tick height is implemented
            onChange({ value }){
              this.owner.widgetMap.pageCountField.hidden = !value
            }
          },
          pageCountField: {
            type: 'number',
            label: 'Number of pages',
            weight: 35,
            hidden: !this.exportConfig.fitHeight,
            value: this.exportConfig.pageCount,
            required: true,
            min: 1,
            max: 1000,
          },
          sessionFontSizeField: {
            type: 'combo',
            label: 'Session font size',
            weight: 40,
            displayText: 'text',
            valueField: 'value',
            editable: false,
            value: this.exportConfig.sessionFontSize,
            items: [
              { id: '0.5em', text: 'x0.5' },
              { id: '0.6em', text: 'x0.6' },
              { id: '0.7em', text: 'x0.7' },
              { id: '0.8em', text: 'x0.8' },
              { id: '0.9em', text: 'x0.9' },
              { id: '1em', text: 'x1.0' },
              { id: '1.1em', text: 'x1.1' },
              { id: '1.2em', text: 'x1.2' },
              { id: '1.3em', text: 'x1.3' },
              { id: '1.4em', text: 'x1.4' },
              { id: '1.5em', text: 'x1.5' },
            ]
          },
          filterTicksField: {
            type: 'checkbox',
            text: 'Hide empty time slots',
            label: ' ',
            weight: 45,
            value: this.exportConfig.filterTicks
          },
          filterLocationsField: {
            type: 'checkbox',
            text: 'Hide empty locations',
            label: ' ',
            weight: 50,
            value: this.exportConfig.filterLocations
          },
        },
        bbar: [
          {
            text: 'Cancel',
            minWidth: 100,
            cls: 'b-raised b-gray',
            onAction: 'up.close'
          },
          {
            text: 'Export',
            minWidth: 100,
            cls: 'b-raised b-blue',
            onAction: async () => {
              if (!popup.isValid) {
                return
              }

              // region Apply export settings
              const {
                fileNameField,
                commentField,
                pageFormatField,
                pageOrientationField,
                fitHeightField,
                pageCountField,
                sessionFontSizeField,
                filterTicksField,
                filterLocationsField,
              } = popup.widgetMap

              // 'schedule-2024-03-24'
              const fileName = fileNameField.value
              // 'A comment to the export. Might stay empty.'
              const comment = commentField.value
              // 'a4'
              const pageFormat = pageFormatField.value
              // 'landscape'
              const pageOrientation= pageOrientationField.value
              // true
              const fitHeight = fitHeightField.value
              // 1
              const pageCount = pageCountField.value > 0 ? Math.ceil(pageCountField.value) : 1
              // '1em'
              const sessionFontSize = sessionFontSizeField.value
              // true
              const filterTicks = filterTicksField.value
              // true
              const filterLocations = filterLocationsField.value

              let exportWidth = 0
              let exportHeight = 0

              if (pageFormat === 'a4') {
                if (pageOrientation === 'landscape') {
                  exportWidth = this.exportConfig.a4PaperLongSidePx
                  exportHeight = this.exportConfig.a4PaperShortSidePx
                } else if (pageOrientation === 'portrait') {
                  exportWidth = this.exportConfig.a4PaperShortSidePx
                  exportHeight = this.exportConfig.a4PaperLongSidePx
                } else {
                  Toast.show('Page orientation is not correct')
                  return
                }

                if (fitHeight) {
                  exportHeight = exportHeight * pageCount
                }
              }
              else {
                Toast.show('Page format is not correct')
                return
              }

              this.exportConfig = {
                ...this.exportConfig,
                comment,
                pageFormat,
                pageOrientation,
                fitHeight,
                pageCount,
                sessionFontSize,
                filterTicks,
                filterLocations,
                exportHeight,
                exportWidth,
              }
              // endregion

              // Note to get field values before close the popup, otherwise fields will be destroyed
              popup.close()

              const originalTickSize = schedule.tickSize

              this.isExport = true

              // Delay is needed to give CSS time to apply
              await new Promise(resolve => setTimeout(resolve, 150))
              await AsyncHelper.animationFrame()

              schedule.clearEventSelection()

              if (this.exportConfig.filterTicks) {
                schedule.timeAxis.filterBy(tick => {
                  return schedule.eventStore.query(session => {
                    return DateHelper.intersectSpans(
                      session.startDate,
                      session.endDate,
                      tick.startDate,
                      tick.endDate
                    )
                  }).length > 0
                })
              }

              const tickCount = schedule.timeAxis.count

              if (this.exportConfig.filterLocations) {
                schedule.resourceStore.filterBy(location => {
                  return schedule.eventStore.getEvents({
                    resourceRecord: location,
                    startDate: schedule.timeAxis.startDate,
                    endDate: schedule.timeAxis.endDate
                  }).length > 0
                })
              }

              if (fitHeight) {
                schedule.tickSize = (
                  this.exportConfig.exportHeight
                  - schedule.resourceColumns.height
                  - this.$refs.exportScheduleHeader.offsetHeight
                  - this.$refs.exportScheduleFooter.offsetHeight
                ) / tickCount
              } else {
                this.exportConfig.exportHeight = (
                  (originalTickSize * tickCount)
                  + schedule.resourceColumns.height
                  + this.$refs.exportScheduleHeader.offsetHeight
                  + this.$refs.exportScheduleFooter.offsetHeight
                )
              }

              // Fits columns into page width
              const originalFitColumns = this.fitColumns
              let restoreFitColumns = false

              if (this.fitColumns !== 'fit') {
                restoreFitColumns = true
                this.updateFitWidth(true)
              }

              // Delay is needed to give CSS time to apply
              await new Promise(resolve => setTimeout(resolve, 150))
              await AsyncHelper.animationFrame()

              const exportOptions = {
                filename: `${fileName}.pdf`,
                image: {type: 'jpeg', quality: 1},
                jsPDF: {orientation: this.exportConfig.pageOrientation}
              }

              console.time('export time')
              await html2pdf(this.$refs.schedulerContainer, exportOptions)
              console.timeEnd('export time')

              if (this.exportConfig.filterLocations) {
                schedule.resourceStore.clearFilters()
              }

              if (this.exportConfig.filterTicks) {
                schedule.timeAxis.clearFilters()
              }

              // Restore tick size
              if (fitHeight) {
                schedule.tickSize = originalTickSize
              }

              // Restore column size
              if (restoreFitColumns) {
                if (originalFitColumns === 'fill') {
                  this.updateFillWidth(true)
                  this.updateFitWidth(false)
                } else if (originalFitColumns === 'none') {
                  this.updateFillWidth(false)
                  this.updateFitWidth(false)
                }
              }

              this.isExport = false
            }
          }
        ]
      })
    },

    /**
     * TODO move mapRecordForData and mapDataForRecord to SessionModel class
     */
    mapRecordForData(record) {
      const recordsToIds = (records) => {
        return records ? records.map((rec) => rec.id) : []
      }

      const session = {
        id: !record.isPhantom ? record.id : undefined,
        title: record.name,
        duration: record.duration,
        duration_unit: record.durationUnit,
        event_template_id: record.sessionTemplate.id,
        public_note: record.publicNote,
        studio_id: record.resourceId,
        team_id: this.selectedSquad.id,
        players: recordsToIds(record.players),
        player_groups: recordsToIds(record.groups),
        player_workloads: record.playerWorkloads.map(rec => rec.workloadJSON),
        session_elements: record.sessionElements.map(rec => rec.toJSON()),
        status: record.status,
        start_at: record.startDate.toISOString(),
        end_at: record.endDate.toISOString(),
        color: record.eventColor,
        rpe: record.rpe,
        workload: record.workload,
        surface_id: record.surfaceId,
        is_snapshot: record.isSnapshot,
        snapshot_id: record.snapshotId,
      }

      return session
    },

    mapDataForRecord(data) {
      const sortNumbersAsc = (itemA, itemB) => {
        if (+itemA < +itemB) {
          return -1
        } else if (+itemA > +itemB) {
          return 1
        }

        return 0
      }

      const sortObjectsByIdAsc = (objA, objB) => {
        return sortNumbersAsc(objA.id, objB.id)
      }

      const rawEventTemplate = this.eventTemplates.find((template) => template.id === data.event_template_id)

      const rawEventType = rawEventTemplate
        ? this.eventTypes.find((type) => type.id === rawEventTemplate.event_type_id)
        : null

      const idsToRecords = (ids, source) => {
        if (!ids) {
          return []
        }

        return ids
          .sort(sortNumbersAsc)
          .map((itemId) => source.find((item) => item.id === itemId))
      }

      const playerWorkloadsToRecords = (playerIds, workloads, allPlayers, sessionElements) => {
        if (!playerIds) {
          return []
        }

        // Note, only athletes can have workload. Ignore staff.
        const athletes = allPlayers.filter((player) => playerIds.includes(player.id) && !player.is_staff)

        const playerWorkloads = athletes
          .sort(sortObjectsByIdAsc)
          .map((athlete) => {
            const id = athlete.id
            const athleteClone = ObjectHelper.clone(athlete)

            athleteClone.player_id = id
            delete athleteClone.id

            // Workload data
            const workload = workloads.find((workloadData) => workloadData.player_id === id) || {}

            // Elements associated with the player
            const rawPlayerSessionElements = sessionElements.filter(element => element.player_ids.includes(id))

            const rawPlayerElementWorkloads = (workload.player_element_workloads || []).sort(sortObjectsByIdAsc)

            // Create a record for every missing player element workload
            const populatedPlayerElementWorkloads = rawPlayerSessionElements.map(rawSessionElement => {
              // Find existing value in player element workloads
              const workloadElement = rawPlayerElementWorkloads.find(
                  rawElementWorkload => rawElementWorkload.element_id === rawSessionElement.element_id
              )

              if (workloadElement) {
                return workloadElement
              } else {
                console.log(`New player workload element is created for Element ID ${rawSessionElement.element_id}`)
                return ElementWorkloadModel.createRawElementWorkloadBasedOnRawSessionElement(rawSessionElement)
              }
            })

            return {
              ...athleteClone,
              ...workload,
              player_element_workloads: populatedPlayerElementWorkloads,
            }
          })

        return playerWorkloads
      }

      const sessionElements = (data.session_elements || []).sort(sortObjectsByIdAsc)

      const session = {
        id: data.id,
        name: data.title,
        sessionTemplateId: data.event_template_id,
        sessionTemplate: rawEventTemplate ? new SessionTemplateModel(rawEventTemplate) : null,
        sessionType: rawEventType ? new EventTypeModel(rawEventType) : null,
        resourceId: data.studio_id,
        duration: data.duration,
        durationUnit: data.duration_unit,
        players: idsToRecords(data.players, this.participants.allPlayers),
        playerWorkloads: playerWorkloadsToRecords(data.players, data.player_workloads, this.participants.allPlayers, sessionElements),
        groups: idsToRecords(data.player_groups, this.participants.playerGroups),
        sessionElements: sessionElements,
        status: data.status,
        publicNote: data.public_note,
        startDate: data.start_at,
        endDate: data.end_at,
        eventColor: data.color,
        rpe: data.rpe,
        // workload: data.workload, // Calc manually
        surfaceId: data.surface_id,
        isSnapshot: data.is_snapshot,
        readOnly: data.is_snapshot,
        snapshotId: data.snapshot_id,
      }

      return session
    },

    groupGridMounted(grid) {
      const schedule = this.$refs.verticalScheduler.instance
      this.groupGridDragHelper = new GroupDrag({
        grid,
        schedule,
        constrain: false,
        outerElement: grid.element,
      })
    },

    groupGridUnmounted() {
      this.groupGridDragHelper.destroy()
      this.groupGridDragHelper = null
    },

    playerGridMounted(grid) {
      const schedule = this.$refs.verticalScheduler.instance
      this.playerGridDragHelper = new PlayerDrag({
        grid,
        schedule,
        constrain: false,
        outerElement: grid.element,
      })
    },

    playerGridUnmounted() {
      this.playerGridDragHelper.destroy()
      this.playerGridDragHelper = null
    },

    sessionTemplateGridMounted(grid) {
      const schedule = this.$refs.verticalScheduler.instance
      this.sessionTemplateGridDragHelper = new SessionTemplateDrag({
        grid,
        schedule,
        constrain: false,
        outerElement: grid.element,
      })
    },

    sessionTemplateGridUnmounted() {
      this.sessionTemplateGridDragHelper.destroy()
      this.sessionTemplateGridDragHelper = null
    },

    initFitColumns() {
      const schedule = this.$refs.verticalScheduler.instance

      if (schedule.resourceColumns.fillWidth) {
        this.fitColumns = 'fill'
      } else if (schedule.resourceColumns.fitWidth) {
        this.fitColumns = 'fit'
      } else {
        this.fitColumns = 'none'
      }
    },

    initEventLayout() {
      const schedule = this.$refs.verticalScheduler.instance
      this.eventLayout = schedule.eventLayout
    },

    initColumnWidth() {
      const schedule = this.$refs.verticalScheduler.instance
      this.columnWidth = schedule.resourceColumns.columnWidth
    },

    initTickHeight() {
      const schedule = this.$refs.verticalScheduler.instance
      this.tickHeight = schedule.tickSize
    },

    updateStartDate(startDate) {
      this.updateSchedulerTimeSpan(startDate, this.viewPreset)
      this.startDate = startDate
    },

    updateViewPreset(zoomLevelId) {
      const schedule = this.$refs.verticalScheduler.instance
      const viewPresetRecord = schedule.presets.getById(zoomLevelId)
      const { start, end } = this.updateSchedulerTimeSpan(this.startDate, zoomLevelId)
      this.viewPreset = zoomLevelId
      this.startDate = start
      this.endDate = end
      this.updateColumnWidth(viewPresetRecord.tickWidth)
      this.updateTickHeight(viewPresetRecord.tickHeight)
    },

    updateSchedulerTimeSpan(startDate, zoomLevelId) {
      const schedule = this.$refs.verticalScheduler.instance
      const viewPresetRecord = schedule.presets.getById(zoomLevelId)
      const start = BenchmarkDateHelper.startOf(startDate, viewPresetRecord.shiftUnit, true, WEEK_START_DAY)
      const end = DateHelper.add(start, viewPresetRecord.shiftIncrement, viewPresetRecord.shiftUnit)
      schedule.setTimeSpan(start, end)
      return { start, end }
    },

    /**
     * Automatically resize resource columns to fill available width.
     * `true` means columns will stretch (grow) to fill viewport.
     * `false` means columns will respect their configured columnWidth.
     * @param newValue boolean
     */
    updateFillWidth(newValue) {
      const schedule = this.$refs.verticalScheduler.instance
      if (schedule) {
        schedule.resourceColumns.fillWidth = newValue
        this.initFitColumns()
      }
    },

    /**
     * Automatically resize resource columns to always fit available width.
     * `true` means columns will grow or shrink to always fit viewport.
     * `false` means columns will respect their configured columnWidth.
     * @param newValue boolean
     */
    updateFitWidth(newValue) {
      const schedule = this.$refs.verticalScheduler.instance
      if (schedule) {
        schedule.resourceColumns.fitWidth = newValue
        this.initFitColumns()
      }
    },

    /**
     * Value is one of the following options:
     * - `pack` adjusts event width.
     * - `mixed` allows two events to overlap, more packs (only vertical).
     * - `none` allows events to overlap.
     * @param newValue string
     */
    updateEventLayout(newValue) {
      const schedule = this.$refs.verticalScheduler.instance
      if (schedule) {
        schedule.eventLayout = newValue
        this.initEventLayout()
      }
    },

    /**
     * Value is the column width (60px to 340px)
     * @param newValue number
     */
    updateColumnWidth(newValue) {
      const schedule = this.$refs.verticalScheduler.instance
      if (schedule) {
        schedule.resourceColumns.columnWidth = newValue
        this.fitColumns = 'none'
        this.initColumnWidth()
      }
    },

    /**
     * Value is the tick height (20px to 280px)
     * @param newValue number
     */
    updateTickHeight(newValue) {
      const schedule = this.$refs.verticalScheduler.instance
      if (schedule) {
        schedule.suppressFit = true
        schedule.tickSize = newValue
        this.initTickHeight()
      }
    },

    updateSelectedTheme(newThemeId) {
      const oldThemeId = this.selectedTheme
      this.selectedTheme = setSelectedTheme(newThemeId, oldThemeId)
    },
  }
}
</script>
