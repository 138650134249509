import VueAxios from '@/api/VueAxios'
import SnackbarError from '@/components/snackbar/SnackbarError'
import SnackbarMessage from '@/components/snackbar/SnackbarMessage'

declare global {
    interface Window {
        $cookies: any;
    }
}

const LoginSecurity = {
    namespaced: true,
    state: {
        qrCode: {
            image: '',
            secret: ''
        },

    },
    mutations: {
        SET_QR_CODE(state: any, payload: any) {
            state.qrCode.image = payload.image
            state.qrCode.secret = payload.secret
        }
    },
    actions: {
        async GENERATE_QR_CODE({commit, dispatch}: any, params: any) {
            try {
                const response = await VueAxios.post('/login-security/qr-code')
                const {image, secret} = response.data
                await commit('SET_QR_CODE', {image: image, secret: secret})
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },

        async DISABLE_TWO_FACTOR({commit, dispatch}: any, currentPassword: any) {
            try {
                const response = await VueAxios.post('/login-security/disable-2fa', {
                    current_password: currentPassword
                })
                const {message} = response.data
                SnackbarMessage({commit, dispatch}, message)
                return response.data
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },

        async ENABLE_TWO_FACTOR({commit, dispatch}: any, secret: any) {
            try {
                const response = await VueAxios.post('/login-security/enable-2fa', {
                    secret
                })
                const {message} = response.data
                SnackbarMessage({commit, dispatch}, message)
                return response.data
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
    },
    getters: {
        qrCode(state: any) {
            return state.qrCode
        }
    },

}

export default LoginSecurity
