class LocalStorageHelper {
  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  static get storageAvailable() {
    let storage, x

    try {
      storage = localStorage
      x = '__storage_test__'

      storage.setItem(x, x)
      storage.removeItem(x)
      return true
    }
    catch (e) {
      return e instanceof DOMException && (
          // everything except Firefox
          e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
    }
  }

  static setLocalStorageItem(key: string, value: any) {
    this.storageAvailable && localStorage.setItem(key, value)
  }

  static getLocalStorageItem(key: string): string | null {
    const result = this.storageAvailable && localStorage.getItem(key)
    return result === false ? null : result
  }

  static removeLocalStorageItem(key: string) {
    this.storageAvailable && localStorage.removeItem(key)
  }

}
export default LocalStorageHelper
