/**
 * Drag from groups grid onto an event. Assigns a group of athletes to the event.
 *
 * Essential copy of `examples/drag-from-grid/lib/Drag.js`.
 *
 * Note: https://www.bryntum.com/forum/viewtopic.php?f=51&t=21437
 */
import { DragHelper, StringHelper, Toast } from '@bryntum/schedulerpro'

class GroupDrag extends DragHelper {
  static get configurable() {
    return {
      callOnFunctions: true,
      // Don't drag the actual row element, clone it
      cloneTarget: true,
      // OVERRIDDEN: use auto-size feature
      // autoSizeClonedTarget: false,
      // OVERRIDDEN: Only allow drops on events
      dropTargetSelector: '.b-timeline-subgrid .b-sch-event',
      // Only allow drag of row elements inside on the unplanned grid
      targetSelector: '.b-grid-row:not(.b-group-row)'
    }
  }

  afterConstruct(config) {
    // Configure DragHelper with schedule's scrollManager to allow scrolling while dragging
    this.scrollManager = this.schedule.scrollManager
  }

  createProxy(element) {
    const
      { schedule } = this,
      proxy = document.createElement('div'),
      group = this.grid.getRecordFromElement(element)

    // Make the drag proxy element look like an event bar
    proxy.classList.add('b-sch-event-wrap', 'b-sch-style-border', 'b-unassigned-class')

    // OVERRIDDEN:
    // - used custom template
    // - removed setting `newSize` since `autoSizeClonedTarget` config is true
    proxy.innerHTML = StringHelper.xss`
        <div class="b-sch-event b-has-content b-sch-event-withicon">
          <div class="b-sch-event-content">
            <i></i>
            <div>
              <div>${group.title}</div>
            </div>
          </div>
        </div>
      `

    schedule.enableScrollingCloseToEdges(schedule.timeAxisSubGrid)

    return proxy
  }

  onDragStart({ context }) {
    const
      { schedule } = this,
      group = this.grid.getRecordFromElement(context.grabbed)

    // save a reference to the group being dragged, so we can access it later
    context.group = group

    schedule.enableScrollingCloseToEdges(schedule.timeAxisSubGrid)

    // Prevent tooltips from showing while dragging
    schedule.features.eventTooltip.disabled = true
  }

  onDrag({ context }) {
    const
      { schedule } = this,
      { group, target, element } = context,
      session = context.session = target && schedule.resolveEventRecord(target),
      validationIndicator = element.querySelector('i')

    // Don't allow drops everywhere, only allow drops if the drop is a session
    if (session) {
      context.valid = true
    } else {
      context.valid = false
    }

    // Update dragged element
    if (context.valid) {
      validationIndicator.className = 'b-fa b-fa-fw b-fa-check'
    } else {
      validationIndicator.className = 'b-fa b-fa-fw b-fa-times'
    }

    element.classList.toggle('b-sch-color-red', !context.valid)
  }

  async onDrop({ context }) {
    const
      me = this,
      { schedule } = me,
      { group, target } = context

    schedule.disableScrollingCloseToEdges(schedule.timeAxisSubGrid)

    // OVERRIDDEN:
    // If drop was done in a valid location, assign the group to the session
    if (context.valid) {
      const targetSession = schedule.resolveEventRecord(target)
      if (targetSession) {
        Toast.show(`Dropped ${group.title} group (${group.playerNames.join(', ')}) on ${targetSession.name}`)
        schedule.trigger('groupDrop', {schedule, targetSession, group})
      }
    }

    schedule.features.eventTooltip.disabled = false
  }
}

export default GroupDrag
