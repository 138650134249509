import { Popup } from '@bryntum/schedulerpro'
import '@/components/bryntum/widgets/AssignmentValidatorGrid'
import SessionModel from '@/components/bryntum/models/SessionModel'
import PlayerModel from '@/components/bryntum/models/PlayerModel'

class AssignmentValidatorPopup extends Popup {
  static get $name() {
    return 'AssignmentValidatorPopup'
  }

  static get type() {
    return 'assigmentvalidatorpopup'
  }

  static get configurable() {
    return {
      title: 'Warning',
      tools: {
        infoContainer: {
          type: 'container',
          cls: 'b-fa b-fa-info-circle',
          tooltip: {
            html: `
              <div>
                <div>Players intersections Info</div>
                <div>Column marked as light blue - target session column</div>
                <div>Disabled cell-checkbox - session where player doesn't exist</div>
                <div>Select/Deselect checkbox to apply/remove user to a session</div>
              </div>`,
            hideDelay: 0
          }
        }
      },
      modal: true,
      autoShow: false,
      centered: true,
      closable: true,
      closeAction: 'destroy',
      minWidth: '30em',
      minHeight: '30em'
    }
  }

  private resolve: any

  construct({ rangeSessions, targetSession, selectedPlayers }: {
    rangeSessions: SessionModel[],
    targetSession: SessionModel,
    selectedPlayers: PlayerModel[]
  }) {
    const config = {
      items: {
        playersIntersectionGrid: {
          type: 'assigmentvalidatorgrid',
          rangeSessions,
          targetSession,
          selectedPlayers
        }
      },
      bbar: [
        {
          text: 'SAVE',
          minWidth: 100,
          cls: 'b-raised b-blue',
          onClick: () => {
            this.onSaveClick()
          }
        },
        {
          text: 'Set only target',
          tooltip: {
            html: 'Remove players from another sessions that are intersect with target session',
            hideDelay: 0
          },
          onClick: () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const grid = this.widgetMap.playersIntersectionGrid
            grid.uncheckSideSessions(targetSession.id)
          }
        },
        {
          text: 'CANCEL',
          minWidth: 100,
          onClick: () => {
            this.resolve(null)
            this.close()
          }
        }
      ]
    }
    super.construct(config)
  }

  private onSaveClick() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const grid = this.widgetMap.playersIntersectionGrid
    grid.filterSessionsPlayers()
    this.resolve(true)
    this.close()
  }

  public validate() {
    this.show()
    return new Promise(resolve => {
      this.resolve = resolve
    })
  }

  public hasIntersections(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.widgetMap.playersIntersectionGrid.store.count > 0
  }
}

AssignmentValidatorPopup.initClass()

export default AssignmentValidatorPopup
