import {
  Column,
  ColumnConfig,
  DomConfig,
  Grid,
  GridConfig,
  GridFeaturesConfigType,
  Menu,
  MenuItem,
} from '@bryntum/schedulerpro'
import PlayerStore from '@/components/bryntum/stores/PlayerStore'
import PlayerModel from '@/components/bryntum/models/PlayerModel'

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5031
interface ColumnConfigExtension extends ColumnConfig {
  type: string
}

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5020
interface GridConfigExtension extends GridConfig {
  stripeFeature: GridFeaturesConfigType['stripe']
  filterFeature: GridFeaturesConfigType['filter']
  cellTooltipFeature: GridFeaturesConfigType['cellTooltip']
  columnPickerFeature: GridFeaturesConfigType['columnPicker']
  sortFeature: GridFeaturesConfigType['sort']
  groupFeature: GridFeaturesConfigType['group']
  headerMenuFeature: GridFeaturesConfigType['headerMenu']
}

type GroupRendererArgs = {
  size: {
    height: number
  }
}

type MenuItemArgs = {
  menu: Menu
}

type ProcessMenuItemsArgs = {
  column: Column,
  items: {
    addNewRecord: MenuItem
  }
}

const columns: Partial<ColumnConfigExtension>[] = [
  {
    text: 'Athletes / Staff',
    field: 'fullName',
    icon: 'b-fa b-fa-circle-info',
    tooltip: `
      <h4>Athletes and staff grouped by position</h4>
      <p>Drag a person onto a session to assign it to the session.</p>
    `,
    flex: 1,
    renderer: ({ value, record: playerRecord }: { value: string, record: PlayerModel }): DomConfig => {
      return {
        children: [
          {
            tag: 'i',
            dataset: {
              btip: `${playerRecord.roleText} / ${playerRecord.availabilityText}`,
            },
            className: {
              'b-fa': true,
              'b-fa-user': playerRecord.isAthlete,
              'b-fa-user-tie': playerRecord.isStaff,
              'player-icon': true,
              'player-injured': !playerRecord.isAvailable,
            }
          },
          {
            tag: 'span',
            text: value,
          },
        ]
      }
    },
  },
  {
    text: 'Athlete',
    type: 'check',
    field: 'isAthlete',
    align: 'center',
    width: 50,
    renderer: ({ value }: { value: boolean }): DomConfig => {
      return {
        tag: 'i',
        className: {
          'b-fa': true,
          'b-fa-check': value,
        }
      }
    },
  },
  {
    text: 'Staff',
    type: 'check',
    field: 'isStaff',
    align: 'center',
    width: 50,
    renderer: ({ value }: { value: boolean }): DomConfig => {
      return {
        tag: 'i',
        className: {
          'b-fa': true,
          'b-fa-check': value,
        }
      }
    },
  },
  {
    text: 'Available',
    type: 'check',
    field: 'isAvailable',
    align: 'center',
    width: 80,
    renderer: ({ value }: { value: boolean }): DomConfig => {
      return {
        tag: 'i',
        dataset: {
          btip: value ? 'Available' : 'Injured',
        },
        className: {
          'b-fa': true,
          'b-fa-check': value,
          'b-fa-times': !value,
        }
      }
    },
  },
]

const PlayerGridConfig: Partial<GridConfigExtension> = {
  cls: 'players-grid',
  readOnly: true,
  rowHeight: 20,
  // TODO: declare store type once the issue is fixed: https://github.com/bryntum/support/issues/5021
  store: {
    ...PlayerStore.defaultConfig,
  },
  columns,
  // region Features
  stripeFeature: true,
  filterFeature: true,
  cellTooltipFeature: false,
  columnPickerFeature: false,
  sortFeature: {
    multiSort: false,
  },
  groupFeature: {
    // Grouped by `positionLabel` in the store
    renderer({ size }: GroupRendererArgs) {
      size.height = 35
    }
  },
  headerMenuFeature: {
    items: {
      // Hide filter feature menu icons
      filter: false,
      editFilter: false,
      removeFilter: false,
      // Hide sort feature menu icons
      sortAsc: false,
      sortDesc: false,
      // Hide group feature menu icons
      groupAsc: false,
      groupDesc: false,
      groupRemove: false,
      // Additional items
      addNewRecord: {
        text: 'Create New Player',
        icon: 'b-fa-plus',
        weight: 200,
        onItem: ({ menu }: MenuItemArgs) => {
          menu.owner.trigger('addNewPlayerClick')
        }
      },
    },
    processItems({ column, items }: ProcessMenuItemsArgs) {
      const grid = column.subGrid.owner as Grid
      // TODO: Introduce public adminRights property. Property 'adminRights' does not exist on type 'Grid'.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      items.addNewRecord.hidden = !grid.adminRights
    }
  }
  // endregion
}

export { PlayerGridConfig }
