const nonAuthorized = ({to, from, next, store}: any) => {
    const isLoggedIn = store.getters['Auth/isLoggedIn']

    if (isLoggedIn) {
        return next({name: 'home'})
    }
    return next()
}

export default nonAuthorized
