<template>
  <v-app>
    <component :is="layout"/>
    <snackbar/>
  </v-app>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout'
import Snackbar from '../../layouts/components/Snackbar'

export default {
  name: 'App',
  components: {
    Snackbar,
    'auth-layout': AuthLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'auth-layout'
    },
  },

}
</script>
<style lang="scss">
@import "./App.scss";
</style>
