import { Model, DataFieldConfig } from '@bryntum/schedulerpro'
import SessionModel from '@/components/bryntum/models/SessionModel'
import PlayerModel from '@/components/bryntum/models/PlayerModel'

interface PlayersIntersectionModel {
  session: SessionModel,
  players: PlayerModel[]
}

interface DataFieldConfigExtension extends DataFieldConfig {
  type: string
}

/**
 * PlayersIntersectionModel is used in assignment validation
 */
class PlayersIntersectionModel extends Model {
  static $name = 'PlayersIntersectionModel'

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      {name: 'session', type: 'object'},
      {name: 'players', type: 'array'},
    ]
  }
}

export default PlayersIntersectionModel


