import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '@/store/components/Auth'
import Index from '@/store/components/Index'
import LoginSecurity from '@/store/components/LoginSecurity'
import Profile from '@/store/components/Profile'
import Studio from '@/store/components/Studio'
import EventTemplate from '@/store/components/EventTemplate'
import Player from '@/store/components/Player'
import PlayerGroup from '@/store/components/PlayerGroup'
import Surface from '@/store/components/Surface'
import Event from '@/store/components/Event'
import EventType from '@/store/components/EventType'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        snackbar: {
            message: '',
            visible: false,
            type: 'error',
        },
    },
    mutations: {},
    actions: {},
    modules: {
        Auth,
        EventTemplate,
        Index,
        LoginSecurity,
        Player,
        PlayerGroup,
        Surface,
        Profile,
        Studio,
        Event,
        EventType
    }
})

export default store
