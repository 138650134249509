<template>
  <v-container>
    <h2>Change your password</h2>

    <v-row>
      <v-col md="4">
        <v-form
            ref="passwordForm"
            @keyup.enter="passwordChangeClick"
        >
          <v-text-field
              v-model="passwordForm.current_password"

              :append-icon="showCurrentPwd ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showCurrentPwd ? 'text' : 'password'"
              label="Current Password"
              hide-details="auto"
              name="input-10-1"
              counter
              @click:append="showCurrentPwd = !showCurrentPwd"
          />
          <v-text-field
              v-model="passwordForm.new_password"
              :type="showCurrentPwd ? 'text' : 'password'"
              label="New Password"
              hide-details="auto"
          />
          <v-text-field
              v-model="passwordForm.new_password_confirmation"
              :type="showCurrentPwd ? 'text' : 'password'"
              label="New Password Repeat"
              hide-details="auto"
          />
        </v-form>
        <div class="mt-2">
          <v-btn
              class="float-right"
              color="primary"
              @click="passwordChangeClick"
          >
            Change
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'

import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component
export default class PasswordChange extends Vue {
  data () {
    return {
      showCurrentPwd: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
      },
      passwordForm: {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      }
    }
  }

  @Action('Profile/CHANGE_PASSWORD') changePassword
  @Action('Auth/LOGOUT') logout

  async passwordChangeClick () {
    try {
      const result = await this.changePassword(this.passwordForm)
      this.logout()
    } catch (e) {
      throw e
    }

  }
}
</script>

<style scoped>

</style>
