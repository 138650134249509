import { EventStore, EventStoreConfig } from '@bryntum/schedulerpro'
import SessionModel from '@/components/bryntum/models/SessionModel'

class SessionStore extends EventStore {
  static $name = 'SessionStore'

  static get defaultConfig(): Partial<EventStoreConfig> {
    return {
      modelClass: SessionModel,
      // TODO: check why the filter is not applied automatically on resetToLastPublishedSession
      //   as a workaround used manual filter filterOutSnapshots
      // filters: [
      //   { property: 'isSnapshot', value: false }
      // ],
      // reapplyFilterOnAdd: true,
      // reapplyFilterOnUpdate: true,
      // It is true by default for Vue wrapper
      syncDataOnLoad: false,
    }
  }
}

export default SessionStore
