import { Store, StoreConfig } from '@bryntum/schedulerpro'
import SessionElementModel from '@/components/bryntum/models/SessionElementModel'

class SessionElementStore extends Store {
  static $name = 'SessionElementStore'

  static get defaultConfig(): Partial<StoreConfig> {
    return {
      modelClass: SessionElementModel,
    }
  }

  private elementsByPlayerId(playerId: number): SessionElementModel[] {
    return this.query((record: SessionElementModel)  => record.playerIds.includes(playerId)) as SessionElementModel[]
  }
}

export default SessionElementStore
