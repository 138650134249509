import { DataFieldConfig, EventModel, Model } from '@bryntum/schedulerpro'
import BenchmarkDateHelper from '@/components/bryntum/helper/BenchmarkDateHelper'
import EventTypeModel from '@/components/bryntum/models/EventTypeModel'
import ElementModel, { ElementDataObject, ElementRawDataObject } from '@/components/bryntum/models/ElementModel'

// Implicitly adds new fields to an existing interface
interface SessionTemplateModel {
  performance: string
  itemOrder: number
  eventTypeId: number
  eventTypeLabel: string
  eventType: EventTypeModel
  elements: ElementModel[]
}

// Types of raw data used as a template for sessions
interface TemplateRawObject {
  title: string
  duration: number
  duration_unit: string
  color: string
  event_template_id: string | number // field used by Session record to have reference to SessionTemplate id
  session_elements: ElementRawDataObject[]
}

// Types of data used as a template for sessions.
interface TemplateObject {
  name: string
  duration: number
  durationUnit: string
  eventColor: string
  sessionTemplateId: string | number // field used by Session record to have reference to SessionTemplate id
  sessionElements: ElementDataObject[]
}

// Explicitly extends Bryntum types and adds missing types
interface DataFieldConfigExtension extends DataFieldConfig {
  // TODO: remove once the issue is fixed: https://github.com/bryntum/support/issues/5017
  type: string

  // TODO: remove once the issue is fixed: https://www.bryntum.com/forum/viewtopic.php?f=51&t=22096
  convert(value: unknown): unknown

  serialize(value: unknown, record: Model): unknown
}

/**
 * SessionTemplateModel is used to describe template in event-templates[]
 */
class SessionTemplateModel extends EventModel {
  static $name = 'SessionTemplateModel'

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      // Map default fields
      { name: 'name', type: 'string', dataSource: 'title' },
      { name: 'durationUnit', type: 'durationunit', defaultValue: 'hour', dataSource: 'duration_unit' },
      { name: 'eventColor', type: 'string', dataSource: 'color' },
      // Add new fields
      { name: 'performance', type: 'string' },
      { name: 'itemOrder', type: 'number', dataSource: 'item_order' },
      { name: 'eventTypeId', type: 'number', dataSource: 'event_type_id' },
      {
        name: 'elements',
        type: 'array',
        defaultValue: [],
        convert(elements: any[]) {
          return elements.map((element) => {
            return element instanceof ElementModel ? element : new ElementModel(element)
          })
        }
      },
      // Virtual field mapped from "event_type" object. Do not persist!
      {
        name: 'eventTypeLabel',
        type: 'string',
        dataSource: 'event_type.section_name',
        persist: false,
      },
      // Field that represents EventTypeModel
      {
        name: 'eventType',
        type: 'object',
        dataSource: 'event_type',
        convert(value: any): EventTypeModel {
          return new EventTypeModel(value)
        },
        serialize(value: EventTypeModel): any {
          return value.toJSON()
        }
      },
    ]
  }

  get isSessionTemplateModel(): boolean {
    return true
  }

  /**
   * Returns raw data used as a template for sessions.
   * Keys correspond to raw object from server.
   */
  get templateRawDataJSON(): TemplateRawObject {
    return {
      event_template_id: this.id,
      title: this.name,
      duration: this.duration,
      duration_unit: this.durationUnit,
      color: this.eventColor,
      session_elements: this.elements.map((element: ElementModel): ElementRawDataObject => element.rawDataJSON),
    }
  }

  /**
   * Returns data used as a template for sessions.
   * Keys correspond to field names.
   */
  get templateDataJSON(): TemplateObject {
    return {
      name: this.name,
      duration: this.duration,
      durationUnit: this.durationUnit,
      eventColor: this.eventColor,
      sessionTemplateId: this.id,
      sessionElements: this.elements.map((element: ElementModel): ElementDataObject => element.dataJSON),
    }
  }

  get niceDuration(): string {
    return BenchmarkDateHelper.convertDurationToNiceTime(this.duration, this.durationUnit, true)
  }

  get fullName(): string {
    return `${this.name} / ${this.eventType.sectionName} / ${this.fullDuration}`
  }

  get elementCount(): number {
    return this.elements.length
  }

  get elementNames(): string[] {
    return this.elements.map((element: ElementModel) => element.title)
  }
}

export default SessionTemplateModel
