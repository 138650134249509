import { ComboConfig, DomClassList } from '@bryntum/schedulerpro'
import GroupStore from '@/components/bryntum/stores/GroupStore'
import GroupModel from '@/components/bryntum/models/GroupModel'

const PlayerGroupsComboConfig: Partial<ComboConfig> = {
  displayField: 'title',
  valueField: null, // "null" means return record as a value
  multiSelect: true,
  clearable: true,
  // TODO: declare store type once the issue is fixed: https://github.com/bryntum/support/issues/5021
  store: {
    ...GroupStore.defaultConfig,
  },
  chipView: {
    iconTpl: (record: GroupModel): string => {
      const tooltip = record.playerNames.join(', ')
      return `<i data-btip="${tooltip}" class="b-fa b-fa-user-group player-group-icon"></i>`
    },
    itemTpl: (record: GroupModel) => `<span>${record.title}</span>`,
  },
  listItemTpl: (record: GroupModel): string => {
    const tooltip = record.playerNames.join(', ')
    return `
      <div>
        <i data-btip="${tooltip}" class="b-fa b-fa-user-group player-group-icon"></i>
        <span>${record.title}</span>
      </div>
    `
  },
  picker: {
    cls: 'playerGroupsPicker',
    allowGroupSelect: false,
  },
}

export { PlayerGroupsComboConfig }
