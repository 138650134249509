import { DomHelper } from '@bryntum/schedulerpro'
import LocalStorageHelper from '@/components/bryntum/helper/LocalStorageHelper'

const THEME_CLASS_PREFIX = 'b54-theme'

const DEFAULT_THEME = 'material'

export const AVAILABLE_THEMES = [
  { id: 'classic', name: 'Classic' },
  { id: 'classic-dark', name: 'Classic Dark' },
  { id: 'classic-light', name: 'Classic Light' },
  { id: 'material', name: 'Material' },
  { id: 'stockholm', name: 'Stockholm' }
]

export const getSelectedTheme = () => {
  const themeId = LocalStorageHelper.getLocalStorageItem(THEME_CLASS_PREFIX)
  return isValid(themeId) ? themeId : DEFAULT_THEME
}

export const setSelectedTheme = (newThemeId: string, oldThemeId: string): string => {
  const themeId = isValid(newThemeId) ? newThemeId : DEFAULT_THEME

  // If themeInfo is missing, it means DOM doesn't have the theme identifier
  if (!DomHelper.themeInfo) {
    console.warn('DOM is not ready to apply Bryntum theme')
    return themeId
  }

  DomHelper.setTheme(themeId).then(() => {
    const { body} = document

    LocalStorageHelper.setLocalStorageItem(THEME_CLASS_PREFIX, themeId)

    if (isValid(oldThemeId)) {
      body.classList.remove(`${THEME_CLASS_PREFIX}-${oldThemeId}`)
    }

    body.classList.add(`${THEME_CLASS_PREFIX}`, `${THEME_CLASS_PREFIX}-${themeId}`)
  })

  return themeId
}

const isValid = (themeId: string | null): boolean => {
  return Boolean(themeId && AVAILABLE_THEMES.find((item) => item.id === themeId))
}
