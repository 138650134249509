/**
 * Drag from session templates grid onto the scheduler. Creates a new session based on the template.
 *
 * Essential copy of `examples/drag-from-grid/lib/Drag.js`.
 *
 * Note: https://www.bryntum.com/forum/viewtopic.php?f=51&t=21437
 */
import { DateHelper, DomHelper, DragHelper, StringHelper } from '@bryntum/schedulerpro'

export default class SessionTemplateDrag extends DragHelper {
  static get configurable() {
    return {
      callOnFunctions: true,
      // Don't drag the actual row element, clone it
      cloneTarget: true,
      // OVERRIDDEN: use auto-size feature
      // autoSizeClonedTarget: false,
      // Only allow drops on the schedule area
      dropTargetSelector: '.b-timeline-subgrid',
      // Only allow drag of row elements inside on the unplanned grid
      targetSelector: '.b-grid-row:not(.b-group-row)'
    }
  }

  afterConstruct(config) {
    // Configure DragHelper with schedule's scrollManager to allow scrolling while dragging
    this.scrollManager = this.schedule.scrollManager
  }

  createProxy(element) {
    const
      { schedule } = this,
      proxy = document.createElement('div'),
      sessionTemplate = this.grid.getRecordFromElement(element)

    // Make the drag proxy element look like an event bar
    proxy.classList.add('b-sch-event-wrap', 'b-sch-style-border', 'b-unassigned-class')

    // OVERRIDDEN:
    // - used custom template
    // - removed setting `newSize` since `autoSizeClonedTarget` config is true
    proxy.innerHTML = StringHelper.xss`
        <div class="b-sch-event b-has-content b-sch-event-withicon">
          <div class="b-sch-event-content">
            <i></i>
            <div>
              <div>${sessionTemplate.name}</div>
            </div>
          </div>
        </div>
      `

    schedule.enableScrollingCloseToEdges(schedule.timeAxisSubGrid)

    return proxy
  }

  onDragStart({ context }) {
    const
      { schedule } = this,
      sessionTemplate = this.grid.getRecordFromElement(context.grabbed)

    // save a reference to the session template being dragged, so we can access it later
    context.sessionTemplate = sessionTemplate

    schedule.enableScrollingCloseToEdges(schedule.timeAxisSubGrid)

    // Prevent tooltips from showing while dragging
    schedule.features.eventTooltip.disabled = true
  }

  onDrag({ context }) {
    const
      { schedule } = this,
      { sessionTemplate, target, element } = context,
      // OVERRIDDEN: replaced `context.newX` with `coordinateResolver(element)`
      coordinateResolver = DomHelper[`getTranslate${schedule.mode === 'horizontal' ? 'X' : 'Y'}`],
      startDate = schedule.getDateFromCoordinate(coordinateResolver(element), 'round', false),
      endDate = startDate && DateHelper.add(startDate, sessionTemplate.duration, sessionTemplate.durationUnit),
      validationIndicator = element.querySelector('i'),
      // OVERRIDDEN: replaced `resolveResourceRecord(target)` with `resolveResourceRecord(event, [event.offsetX, event.offsetY])`
      room = context.room = target && schedule.resolveResourceRecord(event, [event.offsetX, event.offsetY])

    // Don't allow drops everywhere, only allow drops if the drop is on the timeaxis and on top of a room
    if (startDate && room) {
      // Check availability only if there is a restriction to overlap
      context.valid &= schedule.allowOverlap || schedule.isDateRangeAvailable(startDate, endDate, null, room)
    } else {
      context.valid = false
    }

    // Update dragged element
    if (context.valid) {
      validationIndicator.className = 'b-fa b-fa-fw b-fa-check'
    } else {
      validationIndicator.className = 'b-fa b-fa-fw b-fa-times'
    }

    element.classList.toggle('b-sch-color-red', !context.valid)
  }

  async onDrop({ context }) {
    const
      { schedule } = this,
      { sessionTemplate, element } = context

    schedule.disableScrollingCloseToEdges(schedule.timeAxisSubGrid)

    // DO NOT MODIFY `sessionTemplate` - it is a Template record!
    // If drop was done in a valid location:
    // 1. Save event in DB
    // 2. If saved successfully, add event to the Scheduler event store
    if (context.valid) {
      // OVERRIDDEN:
      // - replaced `context.newX` with `coordinateResolver(element)`
      // - removed `targetSession` (dependencies feature is disabled)
      const
        coordinateResolver = DomHelper[`getTranslate${schedule.mode === 'horizontal' ? 'X' : 'Y'}`],
        date = schedule.getDateFromCoordinate(coordinateResolver(element), 'round', false)

      const newEvent = {
        ...sessionTemplate.templateRawDataJSON,
        'studio_id': context.room.id,
        'start_at': date.toISOString(),
      }

      schedule.trigger('sessionTemplateDrop', { data: newEvent, schedule })
    }

    schedule.features.eventTooltip.disabled = false
  }
}
