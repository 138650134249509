










import { BryntumGrid } from '@bryntum/schedulerpro-vue'
import { SessionTemplateGridConfig } from '@/components/bryntum/configs/SessionTemplateGridConfig'

export default {
  name: 'SessionTemplateGrid',

  components: {
    BryntumGrid,
  },

  props: {
    data: { type: Array, default: undefined },
    isAdmin: { type: Boolean, default: false },
  },

  data() {
    return {
      SessionTemplateGridConfig,
    }
  },

  computed: {
    createSessionTemplateUrl(): string {
      return `${process.env.VUE_APP_NOVA_URL}/nova/resources/event-templates/new`
    },
  },

  watch: {
    isAdmin(value: boolean): void {
      this.updateGridRights(value)
    }
  },

  mounted(): void {
    this.updateGridRights(this.isAdmin)

    const grid = this.$refs.sessionTemplateGrid.instance
    grid.on('addNewSessionTemplateClick', this.onAddNewSessionTemplateClick)

    this.$emit('grid-mounted', grid)
  },

  beforeDestroy(): void {
    const grid = this.$refs.sessionTemplateGrid.instance
    grid.un('addNewSessionTemplateClick')

    this.$emit('grid-unmounted', grid)
  },

  methods: {
    updateGridRights(isAdmin: boolean): void {
      const grid = this.$refs.sessionTemplateGrid.instance
      grid.adminRights = isAdmin
    },
    onAddNewSessionTemplateClick(): void {
      if (this.isAdmin) {
        window.open(this.createSessionTemplateUrl)
      }
    }
  }
}
