const loadPlayers = async (component) => {
  const players = await component.fetchPlayers([
    { key: 'team_id', value: component.selectedSquad.id },
  ])
  const playerGroups = await component.fetchPlayerGroups([
    { key: 'team_id', value: component.selectedSquad.id }
  ])
  component.participants.playerGroups = playerGroups
  component.participants.allPlayers = players
}

export default loadPlayers
