import VueAxios from '@/api/VueAxios'
import SnackbarError from '@/components/snackbar/SnackbarError'

declare global {
    interface Window {
        $cookies: any;
    }
}

const EventTemplates = {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        SET_LIST(state: any, payload: any) {
            EventTemplates.state.list = payload
        }
    },
    actions: {
        async FETCH_LIST({commit, dispatch}: any, params: any) {
            try {
                const response = await VueAxios.get('/event-templates')
                const list = response.data
                await commit('SET_LIST', list)
                return list
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
    },
    getters: {
        getList(state: any) {
            return EventTemplates.state.list
        }
    },

}

export default EventTemplates
