import VueAxios from '@/api/VueAxios'
import SnackbarError from '@/components/snackbar/SnackbarError'
import SnackbarMessage from '@/components/snackbar/SnackbarMessage'
import router from '@/router'

declare global {
    interface Window {
        $cookies: any;
    }
}

const Auth = {
    namespaced: true,
    state: {
        user: window.$cookies.get('user') || {},
        token: window.$cookies.get('access_token') || ''
    },
    mutations: {
        SET_AUTH(state: any, payload: any) {
            state.token = payload.token
            state.user = payload.user
        },
        SET_FA_ENABLED(state: any, enabled: any) {
            state.user.two_fa_enabled = enabled
            window.$cookies.set('user', JSON.stringify(state.user))
        }
    },
    actions: {
        async LOGOUT({commit, dispatch}: any, params: any) {
            try {
                const response = await VueAxios.post('/auth/logout')
                const {success} = response.data
                if (success) {
                    window.$cookies.remove('access_token')
                    window.$cookies.remove('user')
                    await commit('SET_AUTH', {token: null, user: null})
                    await router.push({name: 'auth.login'})
                }
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
        async ME({commit, dispatch}: any, params: any) {
            try {
                const response = await VueAxios.get('/auth/me')

                const user = response.data
                window.$cookies.set('user', JSON.stringify(user))
                return user
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
        async PASSWORD_RESET({commit, dispatch}: any, email: any) {
            try {
                const response = await VueAxios.post('/auth/password-reset', {
                    email
                })

                const {message} = response.data
                SnackbarMessage({commit, dispatch}, message)
                await router.push({name: 'auth.login'})
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
        async SET_NEW_PASSWORD({commit, dispatch}: any, params: any) {
            try {
                const response = await VueAxios.post('/auth/set-new-password', params)

                const {message} = response.data
                SnackbarMessage({commit, dispatch}, message)
                await router.push({name: 'auth.login'})
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
        async LOGIN({commit, dispatch}: any, params: any) {
            VueAxios.defaults.headers.common.Authorization = null
            try {
                const response = await VueAxios.post('/auth/login', params)

                if (response.status === 206) {
                    const {message} = response.data
                    SnackbarMessage({commit, dispatch}, message)
                    return
                }

                const {access_token, user} = response.data
                if (user.two_fa_enabled) {
                    return true
                }
                await commit('SET_AUTH', {token: access_token, user: user})
                window.$cookies.set('access_token', access_token)
                window.$cookies.set('user', JSON.stringify(user))

                VueAxios.defaults.headers.common.Authorization = 'Bearer ' + response.data.access_token
                router.push({name: 'calendar'})
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        }
    },
    getters: {
        token(state: any) {
            return state.token
        },
        user(state: any) {
            return state.user
        },
        isLoggedIn: (state: any) => Boolean(state.token),
    },

}

export default Auth
