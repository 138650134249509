import { DataFieldConfig, Model } from '@bryntum/schedulerpro'

// Implicitly adds new fields to an existing interface
interface SurfaceModel {
  id: number,
  title: string
  labelLong: string
  uefaCode: string
}

// Explicitly extends Bryntum types and adds missing types
// TODO: remove once the issue is fixed: https://github.com/bryntum/support/issues/5017
interface DataFieldConfigExtension extends DataFieldConfig {
  type: string;
}

/**
 * SurfaceModel is used to describe surface that can be selected for session
 */
class SurfaceModel extends Model {
  static $name = 'GroupModel'

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      { name: 'title', type: 'string' },
      { name: 'labelLong', type: 'string', dataSource: 'label_long' },
      { name: 'uefaCode', type: 'string', dataSource: 'uefa_code' },
    ]
  }

  get isSurfaceModel(): boolean {
    return true
  }
}

export default SurfaceModel
