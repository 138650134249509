import { DataFieldConfig, Model, ObjectHelper } from '@bryntum/schedulerpro'
import PlayerModel from '@/components/bryntum/models/PlayerModel'
import ElementWorkloadModel, { ElementWorkloadRawObject } from '@/components/bryntum/models/ElementWorkloadModel'
import SessionElementModel from '@/components/bryntum/models/SessionElementModel'
import ElementWorkloadStore from '@/components/bryntum/stores/ElementWorkloadStore'

// Implicitly adds new fields to an existing interface
interface WorkloadModel {
  id: string | number
  playerId: string | number
  jumps: number | null
  elementWorkloads: ElementWorkloadModel[]
  sessionElementsTotalRpe: number
  sessionElementsTotalDuration: number
  sessionElementsTotalWorkload: number
  rpe: number | null
  duration: number | null
  _workload: number | null
  presence: boolean
  notes: string
}

interface WorkloadSummary {
  elementIds: (string | number)[]
  totalRpe: number
  totalDuration: number
  totalWorkload: number
}

// Raw fields that represent workload
const WORKLOAD_RAW_OBJECT_PROPS = [
  'id',
  'player_id',
  'jumps',
  'player_element_workloads',
  'session_elements_total_rpe',
  'session_elements_total_duration',
  'session_elements_total_workload',
  'rpe',
  'duration',
  'workload',
  'presence',
  'notes',
]

// Types of the raw fields
interface WorkloadRawObject {
  id: string | number
  player_id: string | number
  jumps: number
  player_element_workloads: Partial<ElementWorkloadRawObject>[]
  session_elements_total_rpe: number
  session_elements_total_duration: number
  session_elements_total_workload: number
  rpe: number
  duration: number
  workload: number
  presence: boolean
  notes: string
}

// Explicitly extends Bryntum types and adds missing types
// TODO: remove once the issue is fixed: https://github.com/bryntum/support/issues/5017
interface DataFieldConfigExtension extends DataFieldConfig {
  type: string

  // TODO: remove once the issue is fixed: https://www.bryntum.com/forum/viewtopic.php?f=51&t=22096
  convert(value: unknown): unknown

  serialize(value: unknown, record: Model): unknown
}

/**
 * WorkloadModel is used to describe workload in events[].player_workloads[].
 * We store player's achievements/results and manual workload data.
 */
class WorkloadModel extends PlayerModel {
  static $name = 'WorkloadModel'

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      { name: 'playerId', type: 'number', dataSource: 'player_id' },
      { name: 'jumps', type: 'number', defaultValue: null },
      {
        name: 'elementWorkloads',
        type: 'array',
        defaultValue: [],
        dataSource: 'player_element_workloads',
        convert(elementWorkloads: any[]) {
          return elementWorkloads.map((elementWorkload): ElementWorkloadModel => {
            if (elementWorkload instanceof ElementWorkloadModel) {
              return elementWorkload
            } else {
              return new ElementWorkloadModel(elementWorkload)
            }
          })
        }
      },
      {
        name: 'sessionElementsTotalRpe',
        type: 'number',
        defaultValue: null,
        dataSource: 'session_elements_total_rpe'
      },
      {
        name: 'sessionElementsTotalDuration',
        type: 'number',
        defaultValue: null,
        dataSource: 'session_elements_total_duration'
      },
      {
        name: 'sessionElementsTotalWorkload',
        type: 'number',
        defaultValue: null,
        dataSource: 'session_elements_total_workload'
      },
      { name: 'rpe', type: 'number', defaultValue: null },
      { name: 'duration', type: 'number', defaultValue: null },
      { name: 'workload', type: 'number', defaultValue: null },
      { name: 'presence', type: 'boolean', defaultValue: true },
      { name: 'notes', type: 'string', defaultValue: '' },
    ]
  }

  get isPlayerModel(): boolean {
    return false
  }

  get isWorkloadModel(): boolean {
    return true
  }

  get workloadJSON(): Partial<WorkloadRawObject> {
    const result = {} as Partial<WorkloadRawObject>
    const rawData = this.toJSON()

    ObjectHelper.copyProperties(result, rawData, WORKLOAD_RAW_OBJECT_PROPS)

    if (this.isPhantom) {
      delete result.id
    }

    result.player_element_workloads = this.elementWorkloads.map(
      (elementWorkload: ElementWorkloadModel): Partial<ElementWorkloadRawObject> => {
        return elementWorkload.elementWorkloadJSON
      }
    )

    return result
  }

  set workload(value: number | null) {
    this._workload = this.calculateWorkload
  }

  get workload(): number | null {
    return this.calculateWorkload
  }

  get calculateWorkload(): number | null {
    return this.rpe != null && this.duration != null ? this.rpe * this.duration : null
  }

  get playerElementNames(): string[] {
    return this.elementWorkloads.map(rec => rec.title)
  }

  public setElementWorkloads(elementWorkloads: ElementWorkloadModel[]): void {
    const summary = ElementWorkloadStore.workloadSummary(elementWorkloads)

    this.elementWorkloads = elementWorkloads
    this.sessionElementsTotalRpe = summary.totalRpe
    this.sessionElementsTotalDuration = summary.totalDuration
    this.sessionElementsTotalWorkload = summary.totalWorkload
  }

  public updatePlayerElementWorkloads(sessionElementRecords: SessionElementModel[]): void {
    const elementWorkloads = sessionElementRecords.map(sessionElementRecord => {
      const elementWorkloadRecord = this.elementWorkloads.find(
        playerElementWorkloadRecord => playerElementWorkloadRecord.isBasedOnSessionElement(sessionElementRecord)
      )

      return ElementWorkloadModel.createElementWorkloadBasedOnSessionElement(
        sessionElementRecord,
        elementWorkloadRecord ? elementWorkloadRecord.actualDuration : null,
      )
    })

    this.setElementWorkloads(elementWorkloads)
  }
}

export default WorkloadModel
