<template>
  <v-navigation-drawer
    permanent
    app
    dark
    :mini-variant.sync="mini"
  >
    <v-list>
      <v-list-item class="px-2">
        <v-img src="/img/logo-small.png" />
        <v-list-item-title>Benchmark54</v-list-item-title>
        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider />

      <v-list-item
        v-for="menuItem in menuItems"
        :key="menuItem.title"
        link
        :to="{ name: menuItem.path}"
      >
        <v-list-item-icon>
          <v-icon
            :left="$vuetify.breakpoint.mdAndUp"
            size="20"
            v-text="menuItem.icon"
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list-item
        :key="'logout'"
        link
        @click="debouncedLogoutClick"
      >
        <v-list-item-icon>
          <v-icon
            :left="$vuetify.breakpoint.mdAndUp"
            size="20"
          >
            mdi-location-exit
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'NavigationBar',
  data() {
    return {
      mini: true,

      admins: [
        ['Management', 'people_outline'],
        ['Settings', 'settings']
      ],

      menuItems: [
        {
          title: 'Calendar',
          icon: 'mdi-calendar',
          path: 'calendar'
        },
        {
          title: 'Security',
          icon: 'mdi-security',
          path: 'user.security'
        },
        {
          title: 'Password',
          icon: 'mdi-lock',
          path: 'user.password-change'
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      logout: 'Auth/LOGOUT'
    }),
    logoutClick() {
      this.logout()
    },
    debouncedLogoutClick: debounce(function() {
      this.logoutClick()
    }, 100)
  },
}
</script>

<style scoped>
</style>
