import {
  Column,
  ColumnConfig,
  Combo,
  DomConfig,
  Grid,
  GridConfig,
  GridFeaturesConfigType,
  NumberColumnConfig,
  Store,
  WidgetColumnConfig,
} from '@bryntum/schedulerpro'
import SessionElementStore from '@/components/bryntum/stores/SessionElementStore'
import SessionElementModel from '@/components/bryntum/models/SessionElementModel'
import { PlayersComboConfig } from '@/components/bryntum/configs/PlayersComboConfig'
import PlayerModel from '@/components/bryntum/models/PlayerModel'

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5031
interface ColumnConfigExtension extends ColumnConfig, NumberColumnConfig, WidgetColumnConfig {
  type: string
}

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5020
interface GridConfigExtension extends GridConfig {
  // Features
  stripeFeature: GridFeaturesConfigType['stripe']
  filterFeature: GridFeaturesConfigType['filter']
  cellTooltipFeature: GridFeaturesConfigType['cellTooltip']
  columnPickerFeature: GridFeaturesConfigType['columnPicker']
  sortFeature: GridFeaturesConfigType['sort']
  groupFeature: GridFeaturesConfigType['group']
  headerMenuFeature: GridFeaturesConfigType['headerMenu']
  cellMenuFeature: GridFeaturesConfigType['cellMenu']
  rowCopyPasteFeature: GridFeaturesConfigType['rowCopyPaste']
}

interface PlayersColumn extends Column {
  editor: Combo
}

interface CellMenuItemArgs {
  source: Grid,
  cellData: { column: string },
  record: SessionElementModel
}

const columns: Partial<ColumnConfigExtension>[] = [
  {
    text: 'Name',
    field: 'title',
    width: 180,
    editor: false,
    enableCellContextMenu: false,
  },
  {
    text: 'RPE',
    htmlEncodeHeaderText: false,
    field: 'rpe',
    width: 70,
    align: 'center',
    type: 'number',
    min: 0,
    max: 10,
  },
  {
    text: 'Duration<br>(mins)',
    htmlEncodeHeaderText: false,
    field: 'duration',
    width: 70,
    align: 'center',
    type: 'number',
    min: 0,
  },
  {
    text: 'Estimated<br>Workload<br>(AU)',
    htmlEncodeHeaderText: false,
    field: 'workload',
    editor: false,
    enableCellContextMenu: false,
    width: 80,
    align: 'center',
    type: 'number',
    min: 0,
  },
  {
    text: 'Athletes',
    field: 'playerIds',
    minWidth: 100,
    flex: 1,
    htmlEncode: false,
    renderer: ({ value: playerIds, column }: { value: number[], column: PlayersColumn }): DomConfig => {
      let playerNamesRow = playerIds.join(', ')
      let playerNamesList = playerIds.join('<br/>')

      if (column.editor.store) {
        const playersStore = column.editor.store as Store
        const playerNames = playerIds.map((id) => {
          const playerRecord = playersStore.getById(id) as PlayerModel
          return playerRecord ? playerRecord.fullName : id
        })

        playerNamesRow = playerNames.join(', ')
        playerNamesList = playerNames.join('<br/>')
      }

      return playerNamesRow ? {
        tag: 'div',
        class: 'session-element-players',
        dataset: {
          btip: playerNamesList,
        },
        html: playerNamesRow,
      } : {}
    },
    editor: {
      ...PlayersComboConfig,
      type: 'combobox',
      valueField: 'id',
      triggers : {
        check: {
          cls: 'b-fa b-fa-check',
          weight: 10,
          handler() {
            this.value = this.store.records.filter((rec: any) => !rec.isGroupHeader).map((rec: any) => rec.id)
          }
        },
      },
    },
  },
]

const SessionElementsGridConfig: Partial<GridConfigExtension> = {
  cls: 'elements-grid',
  // TODO: declare store type once the issue is fixed: https://github.com/bryntum/support/issues/5021
  store: {
    ...SessionElementStore.defaultConfig,
  },
  columns,
  rowHeight: 50,
  // region Features (to be applied to ES6 Bryntum component instead of Vue component)
  features: {
    stripe: true,
    filter: false,
    columnPicker: false,
    sort: false,
    group: false,
    headerMenu: false,
    rowCopyPaste: false,
    cellMenu: {
      items: {
        removeRow: false,
        bulkUpdate: {
          text: 'Bulk Update',
          icon: 'b-fa b-fa-arrow-down',
          onItem({ source: grid, cellData, record }: CellMenuItemArgs) {
            const value = record.get(cellData.column)
            const store = grid.store as SessionElementStore
            store.beginBatch()
            let nextRecord = store.getNext(record) as SessionElementModel | null
            while (nextRecord) {
              nextRecord.set(cellData.column, value)
              nextRecord = store.getNext(nextRecord) as SessionElementModel | null
            }
            store.endBatch()
          },
          weight: 140
        }
      },
    }
  },
  // endregion
}

export { SessionElementsGridConfig }
