import {
  Column,
  ColumnConfig,
  Grid,
  GridConfig,
  GridFeaturesConfigType,
  Menu,
  MenuItem,
  StringHelper
} from '@bryntum/schedulerpro'
import SessionTemplateStore from '@/components/bryntum/stores/SessionTemplateStore'
import SessionTemplateModel from '@/components/bryntum/models/SessionTemplateModel'

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5020
interface GridConfigExtension extends GridConfig {
  stripeFeature: GridFeaturesConfigType['stripe']
  filterFeature: GridFeaturesConfigType['filter']
  cellTooltipFeature: GridFeaturesConfigType['cellTooltip']
  columnPickerFeature: GridFeaturesConfigType['columnPicker']
  sortFeature: GridFeaturesConfigType['sort']
  groupFeature: GridFeaturesConfigType['group']
  headerMenuFeature: GridFeaturesConfigType['headerMenu']
}

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5031
interface ColumnConfigExtension extends ColumnConfig {
  type: string
}

type TooltipRendererArgs = {
  record: SessionTemplateModel
}

type GroupRendererArgs = {
  size: {
    height: number
  }
}

type MenuItemArgs = {
  menu: Menu
}

type ProcessMenuItemsArgs = {
  column: Column,
  items: {
    addNewRecord: MenuItem
  }
}

const columns: Partial<ColumnConfigExtension>[] = [
  {
    text: 'Sessions',
    field: 'name',
    icon: 'b-fa b-fa-circle-info',
    tooltip: `
      <h4>Preconfigured session config</h4>
      <p>Hover elements number to see what elements are associated with the template.</p>
      <p>Drag a session config to the scheduler to create a new session with the selected config.</p>
    `,
    flex: 1,
    htmlEncode : false,
    renderer: ({ record, value }: { record: SessionTemplateModel, value: string }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return StringHelper.xss`
        <i class="b-icon b-icon-square" style="color: ${record.eventColor};"></i>${value}
      `
    }
  },
  {
    text: 'Duration',
    field: 'niceDuration',
    width: 120,
    align: 'center',
  },
  {
    htmlEncodeHeaderText: false,
    text: 'Total<br/>Elements',
    field: 'elementCount',
    type: 'number',
    width: 80,
    sortable: false,
    filterable: false,
    align: 'center',
    tooltipRenderer({ record }: TooltipRendererArgs): string {
      return record.elementNames.join('<br />')
    },
  },
]

const SessionTemplateGridConfig: Partial<GridConfigExtension> = {
  cls: 'session-templates-grid',
  readOnly: true,
  rowHeight: 20,
  // TODO: declare store type once the issue is fixed: https://github.com/bryntum/support/issues/5021
  store: {
    ...SessionTemplateStore.defaultConfig,
  },
  columns,
  // region Features
  stripeFeature: true,
  filterFeature: true,
  cellTooltipFeature: true,
  columnPickerFeature: false,
  sortFeature: {
    multiSort: false,
  },
  groupFeature: {
    // Grouped by `eventTypeLabel` in the store
    renderer({ size }: GroupRendererArgs) {
      size.height = 35
    }
  },
  headerMenuFeature: {
    items: {
      // Hide filter feature menu icons
      filter: false,
      editFilter: false,
      removeFilter: false,
      // Hide sort feature menu icons
      sortAsc: false,
      sortDesc: false,
      // Hide group feature menu icons
      groupAsc: false,
      groupDesc: false,
      groupRemove: false,
      // Additional items
      addNewRecord: {
        text: 'Create New Session Template',
        icon: 'b-fa-plus',
        weight: 200,
        onItem: ({ menu }: MenuItemArgs) => {
          menu.owner.trigger('addNewSessionTemplateClick')
        }
      },
    },
    processItems({ column, items }: ProcessMenuItemsArgs) {
      const grid = column.subGrid.owner as Grid
      // TODO: Introduce public adminRights property. Property 'adminRights' does not exist on type 'Grid'.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      items.addNewRecord.hidden = !grid.adminRights
    }
  }
  // endregion
}

export { SessionTemplateGridConfig }
