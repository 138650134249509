import VueAxios from '@/api/VueAxios'
import SnackbarError from '@/components/snackbar/SnackbarError'

const Event = {
    namespaced: true,
    state: {
        list: {},
        squads: [],
        eventTemplate: '',
        editorStatus: false, // "true means the editor is shown, false otherwise"
    },
    mutations: {
        SET_LIST(state: any, payload: any) {
            Event.state.list = payload
        },
        SET_SQUADS(state: any, payload: any) {
            Event.state.squads = payload
        },
        SET_TEMPLATE(state: any, payload: any) {
            Event.state.eventTemplate = payload
        },
        SET_EDITOR_STATUS(state: any, payload: boolean) {
            Event.state.editorStatus = payload
        },
    },

    actions: {
        async DELETE({commit, dispatch}: any, eventId: any) {
            try {
                const response = await VueAxios.delete(`/events/${eventId}`)
                const data = response.data

                return data
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
        async UPDATE({commit, dispatch}: any, params: any) {
            try {
                const response = await VueAxios.patch(`/events/${params.id}`, params)
                const data = response.data
                return data
            } catch (err) {
                throw err
                console.log('Error', err)
            }
        },

        async VIEW({commit, dispatch}: any, eventId: any) {
            try {
                const response = await VueAxios.get(`/events/${eventId}`)
                const data = response.data

                return data
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },

        async STORE({commit, dispatch}: any, params: any) {
            try {
                const response = await VueAxios.post('/events', params)
                const data = response.data

                return data
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },

        async FETCH_SQUADS({commit, dispatch}: any) {
            try {
                const response = await VueAxios.get('/club-manager/teams')
                const list = response.data
                await commit('SET_SQUADS', list)
                return list
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },

        async FETCH_LIST({commit, dispatch}: any, params: any) {

            try {
                const squadId = params.squadId ? `&filter[team_id]=${params.squadId}` : ''
                const startDate = params.startDateDay
                const endDate = params.endDateDay
                const response = await VueAxios.get(`/events?filter[start_at_begin]=${startDate}&filter[start_at_end]=${endDate}` + squadId)
                const list = response.data
                await commit('SET_LIST', list)
                return list
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },

        async FETCH_TEMPLATE({commit, dispatch}: any, templateID: any) {
            try {
                const response = await VueAxios.get(`/event-templates/${templateID}`)
                const data = response.data
                await commit('SET_TEMPLATE', data)

                return data
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
        EDITOR_STATUS({commit}: any, status: boolean) {
            commit('SET_EDITOR_STATUS', status)
        },
    },
    getters: {
        getList(state: any) {
            return state.list
        },
        getSquads(state: any) {
          return state.squads
        },
        getEventTemplate(state: any) {
            return Event.state.eventTemplate
        },
        getEditorStatus(state: any) {
            return Event.state.editorStatus
        }
    },

}

export default Event
