










import { BryntumGrid } from '@bryntum/schedulerpro-vue'
import { GroupGridConfig } from '@/components/bryntum/configs/GroupGridConfig'

export default {
  name: 'GroupGrid',

  components: {
    BryntumGrid,
  },

  props: {
    data: { type: Array, default: undefined },
    isAdmin: { type: Boolean, default: false },
  },

  data() {
    return {
      GroupGridConfig,
    }
  },

  computed: {
    createGroupUrl(): string {
      return `${process.env.VUE_APP_NOVA_URL}/nova/resources/player-groups/new`
    },
  },

  watch: {
    isAdmin(value: boolean): void {
      this.updateGridRights(value)
    }
  },

  mounted(): void {
    this.updateGridRights(this.isAdmin)

    const grid = this.$refs.groupGrid.instance
    grid.on('addNewGroupClick', this.onAddNewGroupClick)

    this.$emit('grid-mounted', grid)
  },

  beforeDestroy(): void {
    const grid = this.$refs.groupGrid.instance
    grid.un('addNewGroupClick')

    this.$emit('grid-unmounted', grid)
  },

  methods: {
    updateGridRights(isAdmin: boolean): void {
      const grid = this.$refs.groupGrid.instance
      grid.adminRights = isAdmin
    },
    onAddNewGroupClick(): void {
      if (this.isAdmin) {
        window.open(this.createGroupUrl)
      }
    }
  }
}
