const SnackbarError = ({commit, dispatch}: any, err: any, root = true) => {
    let text = ''
    if (err.response) {
        const errors = Object.values({...err.response.data.errors})
        const message = err.response.data.message
        text = errors.join('\r\n') ?? message
    }

    if (text.length === 0) text = 'Unknown error happened'
    commit('Index/SET_SNACKBAR_ERROR', text, {root: true})
}

export default SnackbarError
