<template>
  <v-main>
    <v-container
        fill-height
        fluid
    >
      <v-layout
          align-center
          justify-center
      >
        <v-flex
            md4
            sm8
            xs12
        >
          <v-card class="elevation-12">
            <v-toolbar
                color="primary"
                dark
            >
              <v-toolbar-title>Reset your password</v-toolbar-title>
            </v-toolbar>

            <v-expand-transition>
              <v-card-text>
                <v-text-field
                    v-model="email"
                    label="Email"
                    name="email"
                    prepend-icon="mdi-email"
                    type="text"
                />
              </v-card-text>
            </v-expand-transition>

            <v-card-actions>
              <v-btn
                  text
                  :to="{name: 'auth.login'}"
              >
                Back to Login
              </v-btn>
              <v-spacer/>
              <v-btn
                  color="primary"
                  @click="resetPasswordClick"
              >
                Reset Password
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import Vue from 'vue'
import { Action } from 'vuex-class'
import { Component } from 'vue-property-decorator'

@Component
export default class ForgotPassword extends Vue {
  data () {
    return {
      email: '',
    }
  }

  @Action('Auth/PASSWORD_RESET') passwordReset

  async resetPasswordClick () {
    try {
      const resetR = await this.passwordReset(this.email)

    } catch (e) {
      throw e
    }

  }
}
</script>
