import {
  ColumnConfig,
  Grid,
  GridConfig,
  GridFeaturesConfigType,
  NumberColumnConfig,
  WidgetColumnConfig,
} from '@bryntum/schedulerpro'
import ElementWorkloadStore from '@/components/bryntum/stores/ElementWorkloadStore'
import ElementWorkloadModel from '@/components/bryntum/models/ElementWorkloadModel'

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5031
interface ColumnConfigExtension extends Omit<ColumnConfig, 'sum'>, Omit<NumberColumnConfig, 'sum'>, Omit<WidgetColumnConfig, 'sum'> {
  type: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  sum: 'sum' | 'add' | 'count' | 'countNotEmpty' | 'average' | Function
}

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5020
interface GridConfigExtension extends GridConfig {
  // Features
  stripeFeature: GridFeaturesConfigType['stripe']
  filterFeature: GridFeaturesConfigType['filter']
  cellTooltipFeature: GridFeaturesConfigType['cellTooltip']
  columnPickerFeature: GridFeaturesConfigType['columnPicker']
  sortFeature: GridFeaturesConfigType['sort']
  groupFeature: GridFeaturesConfigType['group']
  headerMenuFeature: GridFeaturesConfigType['headerMenu']
  cellMenuFeature: GridFeaturesConfigType['cellMenu']
  rowCopyPasteFeature: GridFeaturesConfigType['rowCopyPaste']
  summaryFeature: GridFeaturesConfigType['summary']
}

interface CellMenuItemArgs {
  source: Grid,
  cellData: { column: string },
  record: ElementWorkloadModel
}

interface WorkloadSummary {
  elementIds: (string | number)[]
  totalRpe: number
  totalDuration: number
  totalWorkload: number
}

const columnSummary = (
  result: WorkloadSummary | null,
  currentRecord: ElementWorkloadModel,
  currentIndex: number,
  allRecords: ElementWorkloadModel[],
): WorkloadSummary | null => {
  const isLast = currentIndex === allRecords.length - 1
  if (!isLast) {
    return null
  }
  return ElementWorkloadStore.workloadSummary(allRecords)
}

const columns: Partial<ColumnConfigExtension>[] = [
  {
    text: 'Elements / Characters',
    field: 'title',
    flex: 1,
    editor: false,
    enableCellContextMenu: false,
    sum: 'count',
    summaryRenderer: ({ sum }: { sum: string }) => {
      return `Total: ${sum} element(s)`
    },
  },
  {
    text: 'RPE',
    field: 'rpe',
    width: 50,
    editor: false,
    enableCellContextMenu: false,
    align: 'center',
    type: 'number',
    min: 0,
    max: 10,
    sum: columnSummary,
    summaryRenderer: ({ sum }: { sum: WorkloadSummary | null }): string => {
      if (!sum) {
        return ''
      }
      return `${sum.totalRpe} <i class="b-fa b-fa-question-circle" data-btip="${sum.totalWorkload} AU / ${sum.totalDuration} min = ${sum.totalRpe}"></i>`
    },
  },
  {
    text: 'Duration (mins)',
    field: 'actualDuration',
    width: 150,
    align: 'center',
    type: 'number',
    min: 0,
    icon: 'b-fa b-fa-circle-info',
    tooltip: `
      <h4>Duration per element for the athlete</h4>
      <p>Update to apply actual time athlete spent for the element.</p>
      <p>Clear value to apply default value defined to the element.</p>
    `,
    sum: columnSummary,
    summaryRenderer: ({ sum }: { sum: WorkloadSummary | null }): string => {
      if (!sum) {
        return ''
      }
      return `&Sigma; ${sum.totalDuration} min`
    },
    renderer: ({ value, record: elementWorkload }: { value: number | null, record: ElementWorkloadModel }): number => {
      return value || elementWorkload.duration
    },
  },
  {
    text: 'Workload (AU)',
    field: 'workload',
    width: 150,
    align: 'center',
    editor: false,
    enableCellContextMenu: false,
    type: 'number',
    min: 0,
    sum: columnSummary,
    summaryRenderer: ({ sum }: { sum: WorkloadSummary | null }): string => {
      if (!sum) {
        return ''
      }
      return `&Sigma; ${sum.totalWorkload} AU`
    },
  },
]

const ElementWorkloadGridConfig: Partial<GridConfigExtension> = {
  cls: 'player-session-elements-grid',
  // TODO: declare store type once the issue is fixed: https://github.com/bryntum/support/issues/5021
  store: {
    ...ElementWorkloadStore.defaultConfig,
  },
  columns,
  rowHeight: 50,
  listeners: {
    // Workaround to make cells show fresh values after cell editing
    finishCellEdit({ grid }: { grid: Grid }) {
      grid.refreshRows()
      grid.features.summary.refresh()
    }
  },
  // region Features (to be applied to ES6 Bryntum component instead of Vue component)
  features: {
    summary: true,
    stripe: true,
    filter: false,
    columnPicker: false,
    sort: false,
    group: false,
    headerMenu: false,
    rowCopyPaste: false,
    cellMenu: {
      items: {
        removeRow: false,
        bulkUpdate: {
          text: 'Bulk Update',
          icon: 'b-fa b-fa-arrow-down',
          onItem({ source: grid, cellData, record }: CellMenuItemArgs) {
            const value = record.get(cellData.column)
            const store = grid.store as ElementWorkloadStore
            store.beginBatch()
            let nextRecord = store.getNext(record) as ElementWorkloadModel | null
            while (nextRecord) {
              nextRecord.set(cellData.column, value)
              nextRecord = store.getNext(nextRecord) as ElementWorkloadModel | null
            }
            store.endBatch()
          },
          weight: 140
        }
      },
    }
  },
  // endregion
}

export { ElementWorkloadGridConfig }
