import axios from 'axios'

const VueAxios = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}`,
    timeout: 10000,
})

const accessToken = window.$cookies.get('access_token') || ''

if (accessToken) {
    VueAxios.defaults.headers.common.Authorization = 'Bearer ' + accessToken
}


export default VueAxios


