<template>
  <v-main>
    <v-container
        fill-height
        fluid
    >
      <v-layout
          align-center
          justify-center
      >
        <v-flex
            md4
            sm8
            xs12
        >
          <v-card class="elevation-12">
            <v-toolbar
                color="primary"
                dark
            >
              <v-toolbar-title>Set up a new password</v-toolbar-title>
            </v-toolbar>

            <v-expand-transition>
              <v-card-text>
                <v-text-field
                    v-model="passwordForm.password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="text"
                />
                <v-text-field
                    v-model="passwordForm.password_confirmation"
                    label="Password Confirmation"
                    name="password-confirmation"
                    prepend-icon="mdi-lock"
                    type="text"
                />
              </v-card-text>
            </v-expand-transition>

            <v-card-actions>
              <v-btn
                  text
                  :to="{name: 'auth.login'}"
              >
                Back to Login
              </v-btn>
              <v-spacer/>
              <v-btn
                  color="primary"
                  @click="resetPasswordClick"
              >
                RESET
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import Vue from 'vue'
import { Action } from 'vuex-class'
import { Component } from 'vue-property-decorator'

@Component
export default class ForgotPassword extends Vue {
  data () {
    return {
      passwordForm: {
        password: null,
        password_confirmation: null,
        token: null
      },
    }
  }

  mounted () {
    this.passwordForm.token = this.$route.params.token
  }

  @Action('Auth/SET_NEW_PASSWORD') setNewPassword

  async resetPasswordClick () {
    try {
      const setPasswordR = await this.setNewPassword(this.passwordForm)

    } catch (e) {
      throw e
    }

  }
}
</script>
