const authorized = ({to, from, next, store}: any) => {
    const isLoggedIn = store.getters['Auth/isLoggedIn']
    if (to.name === 'auth.login' && isLoggedIn) {
        return next({name: 'calendar'})
    }
    if (!isLoggedIn) {
        return next({name: 'auth.login'})
    }
    return next()
}

export default authorized
