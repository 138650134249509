<template>
  <v-main>
    <v-container
      fill-height
      fluid
    >
      <v-layout
        align-center
        justify-center
      >
        <v-flex
          md4
          sm8
          xs12
        >
          <v-card class="elevation-12">
            <v-toolbar
              color="primary"
              dark
            >
              <v-toolbar-title>Login form</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-expand-transition v-if="revealCodeForm">
                <v-text-field
                  v-model="loginForm.two_factor_code"
                  label="Six Digit Code"
                  name="Six Digit Code"
                  prepend-icon="mdi-key"
                  type="text"
                />
              </v-expand-transition>

              <v-form
                v-else
                @submit.prevent="loginClick">
                <v-text-field
                  v-model="loginForm.email"
                  label="Login"
                  name="login"
                  persistent-placeholder
                  prepend-icon="mdi-account-circle"
                  type="text"
                />
                <v-text-field
                  id="password"
                  v-model="loginForm.password"
                  label="Password"
                  name="password"
                  persistent-placeholder
                  prepend-icon="mdi-lock-outline"
                  type="password"
                />

                <v-card-actions>
                  <v-btn
                    text
                    :to="{name: 'auth.forgot-password' }"
                  >
                    Forgot password?
                  </v-btn>
                  <v-spacer/>
                  <v-btn
                    color="primary"
                    type="submit"
                  >
                    Login
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import Vue from 'vue'
import { Action } from 'vuex-class'
import { Component } from 'vue-property-decorator'

@Component
export default class LoginUser extends Vue {
  data () {
    return {
      revealCodeForm: false,
      loginForm: {
        two_factor_code: null,
        email: '',
        password: '',
      }
    }
  }

  @Action('Auth/LOGIN') login

  async loginClick () {
    try {
      const loginR = await this.login(this.loginForm)
      if (loginR) {
        this.revealCodeForm = true
      }
    } catch (e) {
      throw e
    }
  }
}
</script>
