<template>
  <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.type"
      :multi-line="true"
      :right="true"
      :timeout="3000"
      :top="true"
  >
    {{ snackbar.message }}
    <v-btn
        primary
        color="white"
        text
        @click="closeSnack"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>

import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

@Component
export default class Snackbar extends Vue {

  @Getter('Index/snackbar') snackbar

  @Mutation('Index/HIDE_SNACK') hideSnack

  closeSnack () {
    this.hideSnack(false)
  }
}
</script>
