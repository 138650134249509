import { MessageDialog, TaskEditConfig as BryntumTaskEditConfig } from '@bryntum/schedulerpro'
import SessionTemplateModel from '@/components/bryntum/models/SessionTemplateModel'
import SessionTemplateStore from '@/components/bryntum/stores/SessionTemplateStore'
import SurfaceStore from '@/components/bryntum/stores/SurfaceStore'
import { WorkloadGridConfig } from '@/components/bryntum/configs/WorkloadGridConfig'
import { SessionElementsGridConfig } from '@/components/bryntum/configs/SessionElementsGridConfig'
import { PlayersComboConfig } from '@/components/bryntum/configs/PlayersComboConfig'
import { PlayerGroupsComboConfig } from '@/components/bryntum/configs/PlayerGroupsComboConfig'
import GroupModel from '@/components/bryntum/models/GroupModel'
import PlayerModel from '@/components/bryntum/models/PlayerModel'
import {
  CONFIRM_RESET_PUBLISHED_SESSION,
  CONFIRM_UNPUBLISH_PUBLISHED_SESSION,
  CONFIRM_UPDATE_PUBLISHED_SESSION
} from '@/components/bryntum/configs/MessageDialogConfirmationConfig'

const TaskEditConfig: Partial<BryntumTaskEditConfig> = {
  editorConfig: {
    title: 'Edit Session',
    maximizable: true,
    minWidth: 700,
    minHeight: 500,
    maxHeight: 500, // Adjusted to have scroll visible when editor height is too big
    // autoClose: false,
    draggable: false,
    bbar: {
      layout: {
        type: 'fit'
      },
      items: {
        saveButton: false,
        deleteButton: false,
        publishButton: {
          type: 'button',
          text: 'Publish',
          icon: 'b-fa-lock',
          tooltip: 'You may apply changes to the sessions in the past. Once all the data is set and correct please publish the session. Once published, any further changes will be tracked and shown as a part of the session details.',
          cls: 'b-raised b-blue',
          weight: 80,
          onClick: ({ source: button }: any) => {
            const editor = button.up('schedulerTaskEditor')
            const { record } = editor.eventEditFeature
            const { owner: scheduler } = editor

            scheduler.trigger('publishSession', { scheduler, record, editor })
          }
        },
        draftButton: {
          type: 'button',
          text: 'Unpublish',
          icon: 'b-fa-unlock',
          tooltip: 'Make session a draft again. All changed that have been tracked will no longer highlighted and any further changes will not be tracked. Once ready, you can publish the session again.',
          cls: 'b-raised b-blue',
          weight: 80,
          onClick: async ({ source: button }: any) => {
            const editor = button.up('schedulerTaskEditor')
            const { record } = editor.eventEditFeature
            const { owner: scheduler } = editor

            const result = await MessageDialog.confirm({ ...CONFIRM_UNPUBLISH_PUBLISHED_SESSION })

            // User selected No
            if (result !== MessageDialog.okButton) {
              return
            }

            scheduler.trigger('unpublishSession', { scheduler, record, editor })
          }
        },
        resetToPublishedButton: {
          type: 'button',
          text: 'Reset',
          icon: 'b-fa-undo-alt',
          tooltip: 'Rollback to the last published version and make session a draft again. All changed that have been tracked will be lost and any further changes will not be tracked. Once ready, you can publish the session again.',
          cls: 'b-raised b-blue',
          weight: 80,
          onClick: async ({ source: button }: any) => {
            const editor = button.up('schedulerTaskEditor')
            const { record } = editor.eventEditFeature
            const { owner: scheduler } = editor

            const result = await MessageDialog.confirm({ ...CONFIRM_RESET_PUBLISHED_SESSION })

            // User selected No
            if (result !== MessageDialog.okButton) {
              return
            }

            scheduler.trigger('resetToLastPublishedSession', { scheduler, record, editor })
          }
        },
        cancelButton: {
          text: 'Cancel',
          weight: 90,
          style: 'margin-left: auto;',
          cls: 'b-raised b-gray',
          minWidth: 100,
        },
        generalSaveButton: {
          type: 'button',
          text: 'Save',
          icon: 'b-fa-cloud-upload',
          cls: 'b-raised b-green', // TODO: highlight on change
          weight: 100,
          minWidth: 100,
          onClick: async ({ source: button }: any) => {
            const editor = button.up('schedulerTaskEditor')
            const { record } = editor.eventEditFeature
            if (record.published && record.isModified) {
              const result = await MessageDialog.confirm({ ...CONFIRM_UPDATE_PUBLISHED_SESSION })
              // User cancelled update. Do not save changes.
              if (result !== MessageDialog.okButton) {
                return
              }
            }
            // Save changes
            editor.onSaveClick()
          }
        },
      },
    },
    // TODO: make save button reactive
    /*
    listeners : {
      readyStateChange({ source }) {
        const saveButton = source.bbar?.widgetMap?.saveButton;

        if (saveButton) {
          // disable Save button if there are no steps recorded by STM
          saveButton.disabled = !source.project.getStm().transaction.length;
        }
      }
    },
    */
  },
  // Tabs configuration
  items: {
    predecessorsTab: false,
    successorsTab: false,
    advancedTab: false,

    generalTab: {
      title: 'Session Overview',
      style: 'min-height: 1em; overflow-y: auto;', // Needed to show scroll when there are lots of players assigned
      tab: {
        icon: 'b-fa b-fa-calendar',
      },
      weight: 10,
      defaults: {
        labelWidth: '10em'
      },
      items: {
        sessionTemplateField: {
          type: 'combobox',
          displayField: 'name',
          valueField: null, // "null" means return record as a value
          weight: 20,
          name: 'sessionTemplate',
          label: 'Session Template',
          required: true,
          // TODO: declare store type once the issue is fixed: https://github.com/bryntum/support/issues/5021
          store: {
            ...SessionTemplateStore.defaultConfig,
          },
          displayValueRenderer: (record: SessionTemplateModel) => {
            return record ? record.fullName : ''
          },
          listItemTpl: (record: SessionTemplateModel) => `
            <div>
              <i class="b-fa b-fa-square" style="color: ${record.eventColor};"></i>
              <span>${record.name} (${record.fullDuration})</span>
            </div>
          `,
          picker: {
            cls: 'sessionTemplatePicker',
            allowGroupSelect: false,
          },
          listeners: {
            change({ source: field, value: sessionTemplateRecord }: any) {
              const editor = field.up('schedulertaskeditor')
              const { eventEditFeature } = editor

              if (eventEditFeature.isEditing && sessionTemplateRecord) {
                const { record: sessionRecord } = eventEditFeature

                if (!sessionRecord.sessionTemplate || sessionRecord.sessionTemplate.id !== sessionTemplateRecord.id) {
                  const { elementsGrid } = editor.widgetMap

                  sessionRecord.set({
                    ...sessionTemplateRecord.templateDataJSON,
                    sessionTemplate: sessionTemplateRecord
                  })

                  elementsGrid.store.data = [...sessionRecord.sessionElements]
                }
              }
            }
          },
          triggers: {
            plug: {
              cls: 'b-fa b-fa-info-circle',
              tooltip: `
                <div>
                  <p>Value is in format "Template / Type / Time".</p>
                  <p>
                    <ul style="padding: 1em;">
                      <li>Template defines the details within your session i.e. session color, duration etc.</li>
                      <li>Type is the umbrella term for your sessions i.e. Medical / Training / Match / Performance etc.</li>
                      <li>Time is the planned duration for the session.</li>
                    </ul>
                  </p>
                  <p>Once saved, it cannot be changed.</p>
                </div>
              `
            }
          },
        },
        playerGroupsField: {
          ...PlayerGroupsComboConfig,
          type: 'combobox',
          weight: 25,
          name: 'groups',
          label: 'Groups',
          listeners: {
            change({ source: field, value: selectedGroups, userAction }: { source: any, value: GroupModel[], userAction: boolean }) {
              const editor = field.up('schedulertaskeditor')
              const { eventEditFeature } = editor

              if (userAction && eventEditFeature.isEditing && selectedGroups) {
                const { record: sessionRecord } = eventEditFeature
                const prevSelectedGroups = sessionRecord.groups as GroupModel[]
                const { playersField } = editor.widgetMap

                // 1. Need to check what groups exist in selectedGroups but missing in prevSelectedGroups
                // and add players from those groups to the session.
                const groupsToAdd = selectedGroups.filter(group => !prevSelectedGroups.includes(group))

                // 2. Need to check what groups exist in prevSelectedGroups but missing in selectedGroups
                // and remove player from those groups out of the session.
                const groupsToRemove = prevSelectedGroups.filter(group => !selectedGroups.includes(group))

                if (groupsToAdd.length > 0) {
                  groupsToAdd.forEach(group => {
                    playersField.value = [
                      ...playersField.value,
                      ...group.players
                    ]
                  })
                }

                if (groupsToRemove.length > 0) {
                  groupsToRemove.forEach(group => {
                    const playerIdsToRemove = group.players.map(player => player.id)
                    const prevPlayers = playersField.value as PlayerModel[]

                    playersField.value = prevPlayers.filter(player => !playerIdsToRemove.includes(player.id))
                  })
                }
              }
            }
          },
        },
        playersField: {
          ...PlayersComboConfig,
          type: 'combobox',
          weight: 30,
          name: 'players',
          label: 'Athletes / Staff',
          listeners: {
            change({ source: field, value: selectedPlayers }: any) {
              const editor = field.up('schedulertaskeditor')
              const { eventEditFeature } = editor

              if (eventEditFeature.isEditing && selectedPlayers) {
                const { record: sessionRecord } = eventEditFeature
                const { elementsGrid, workloadGrid } = editor.widgetMap
                const sessionElementPlayersColumn = elementsGrid.columns.get('playerIds')
                const sessionElementPlayersEditor = sessionElementPlayersColumn.editor

                sessionRecord.assignPlayers(selectedPlayers, true)
                workloadGrid.store.data = [...sessionRecord.playerWorkloads]
                sessionElementPlayersEditor.store.data = [...selectedPlayers]
                // Rerender players in the column
                elementsGrid.refreshColumn(sessionElementPlayersColumn)
              }
            }
          },
        },
        nameField: {
          weight: 40,
          label: 'Session Title',
          required: true,
        },
        resourcesField: {
          weight: 50,
          label: 'Location',
          required: true,
        },
        surfaceField: {
          type: 'combo',
          store: {
            ...SurfaceStore.defaultConfig
          },
          weight: 60,
          name: 'surfaceId',
          label: 'Surface',
          displayField: 'title',
          valueField: 'id',
          clearable: true,
          required: false,
        },
        durationField: {
          flex: '1 0 60%',
          style: 'padding-right: 1em;',
          min: '5minute',
        },
        percentDoneField: false,
        rpeField: {
          type: 'number',
          label: 'Session RPE',
          labelWidth: '5em',
          flex: '1 0 40%',
          name: 'rpe',
          min: 0,
          max: 10,
          weight: 600,
          listeners: {
            async change({ source: field, value }: any) {
              const editor = field.up('schedulertaskeditor')
              const { eventEditFeature } = editor

              if (eventEditFeature.isEditing) {
                const { record } = eventEditFeature
                await record.setAsync('rpe', value) // make workload to be recalculated
              }
            }
          },
          triggers: {
            plug: {
              cls: 'b-fa b-fa-info-circle',
              tooltip: 'RPE is the Rate of Perceived Exertion at any one point during an exercise event'
            }
          },
        },
        workloadField: {
          type: 'text',
          label: 'Workload',
          flex: '1 0 60%',
          style: 'padding-right: 1em; margin-bottom: 0;',
          name: 'workload',
          disabled: true, // TODO: "disabled" does not work, monitor this issue: https://forum.bryntum.com/viewtopic.php?t=23395
          readOnly: true,
          weight: 610,
          placeholder: 'RPE x Duration',
          triggers: {
            plug: {
              cls: 'b-fa b-fa-info-circle',
              tooltip: 'RPE x Duration = Workload (AU - arbitrary units)'
            }
          },
        },
        emptySpaceField: {
          type: 'display',
          flex: '1 0 40%',
          style: 'margin-bottom: 0;',
          weight: 620,
        },
      },
    },

    elementsTab: {
      title: 'Elements / Characters',
      tab: {
        icon: 'b-fa b-fa-bars',
      },
      weight: 15,
      items: {
        elementsGrid: {
          type: 'grid',
          ...SessionElementsGridConfig,
          style: 'height: 100%;',
        },
      },
    },

    workloadTab: {
      title: 'Workload Data',
      cls: 'workload-data-tab',
      tab: {
        icon: 'b-fa b-fa-chart-line',
      },
      weight: 20,
      items: {
        workloadTabContainer: {
          type: 'container',
          cls: 'workload-data-container',
          items: {
            sessionWorkloadBar: {
              type: 'toolbar',
              cls: 'workload-data-toolbar',
              items: {
                sessionRPE: {
                  type: 'display',
                  label: 'Session RPE',
                  name: 'rpe',
                  width: 200,
                  template: (rpe: string) => rpe ? rpe : 'N/A'
                },
                separatorA: {
                  type: 'widget',
                  cls: 'b-toolbar-separator'
                },
                sessionDuration: {
                  type: 'display',
                  label: 'Session Duration',
                  name: 'duration',
                  width: 200,
                  template: (duration: string) => `${duration} mins`
                },
                separatorB: {
                  type: 'widget',
                  cls: 'b-toolbar-separator'
                },
                sessionWorkload: {
                  type: 'display',
                  label: 'Session Workload',
                  name: 'workload',
                  width: 200,
                  template: (workload: string) => workload ? `${workload} AU` : 'N/A'
                },
              }
            },
            workloadGrid: {
              type: 'grid',
              ...WorkloadGridConfig,
            },
          }
        },
      },
    },

    notesTab: {
      title: 'Notes',
      tab: {
        icon: 'b-fa b-fa-note-sticky',
        titleProperty: 'text'
      },
      items: {
        noteField: {
          type: 'textareafield',
          name: 'publicNote',
        }
      }
    },
  }
}

export { TaskEditConfig }
