var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-dashboard fill-height"},[_c('scheduler-toolbar',{attrs:{"start-date":_vm.startDate,"view-preset":_vm.viewPreset,"selected-theme":_vm.selectedTheme,"fit-columns":_vm.fitColumns,"event-layout":_vm.eventLayout,"column-width":_vm.columnWidth,"tick-height":_vm.tickHeight},on:{"update:startDate":_vm.updateStartDate,"update:viewPreset":_vm.updateViewPreset,"update:selectedTheme":_vm.updateSelectedTheme,"update:fillWidth":_vm.updateFillWidth,"update:fitWidth":_vm.updateFitWidth,"update:eventLayout":_vm.updateEventLayout,"update:columnWidth":_vm.updateColumnWidth,"update:tickHeight":_vm.updateTickHeight,"pdfExportInit":_vm.verticalPdfExport}}),_c('div',{staticClass:"calendar-dashboard-container"},[_c('div',{ref:"schedulerContainer",staticClass:"calendar-dashboard-column scheduler-container",class:{
        'scheduler-container-export': _vm.isExport,
        'export-landscape': _vm.isExport && _vm.exportConfig.pageOrientation === 'landscape',
        'export-portrait': _vm.isExport && _vm.exportConfig.pageOrientation === 'portrait',
        'export-font-size-0-5': _vm.isExport && _vm.exportConfig.sessionFontSize === '0.5em',
        'export-font-size-0-6': _vm.isExport && _vm.exportConfig.sessionFontSize === '0.6em',
        'export-font-size-0-7': _vm.isExport && _vm.exportConfig.sessionFontSize === '0.7em',
        'export-font-size-0-8': _vm.isExport && _vm.exportConfig.sessionFontSize === '0.8em',
        'export-font-size-0-9': _vm.isExport && _vm.exportConfig.sessionFontSize === '0.9em',
        'export-font-size-1-0': _vm.isExport && _vm.exportConfig.sessionFontSize === '1em',
        'export-font-size-1-1': _vm.isExport && _vm.exportConfig.sessionFontSize === '1.1em',
        'export-font-size-1-2': _vm.isExport && _vm.exportConfig.sessionFontSize === '1.2em',
        'export-font-size-1-3': _vm.isExport && _vm.exportConfig.sessionFontSize === '1.3em',
        'export-font-size-1-4': _vm.isExport && _vm.exportConfig.sessionFontSize === '1.4em',
        'export-font-size-1-5': _vm.isExport && _vm.exportConfig.sessionFontSize === '1.5em',
      },style:(_vm.exportContainerStyles)},[_c('div',{ref:"exportScheduleHeader",staticClass:"export-schedule-header"},[_c('div',{staticClass:"title-row"},[_c('div',{staticClass:"logo-col"},[_c('v-img',{attrs:{"src":"/img/logo-small.png"}})],1),_c('div',{staticClass:"name-col"},[_vm._v(_vm._s(_vm.selectedSquadName))]),_c('div',{staticClass:"week-date-col"},[_vm._v(_vm._s(_vm.formattedWeekStartDate))])]),_c('div',{staticClass:"body-row"},[_c('div',{staticClass:"date-col"},[_vm._v(_vm._s(_vm.formattedStartDate))]),_c('div',{staticClass:"note-col"},[_vm._v(_vm._s(_vm.exportConfig.comment))])])]),_c('bryntum-scheduler-pro',_vm._b({ref:"verticalScheduler",attrs:{"cls":"vertical-scheduler","resources":_vm.studios,"view-preset":_vm.viewPreset}},'bryntum-scheduler-pro',_vm.SchedulerConfig,false)),_c('div',{ref:"exportScheduleFooter",staticClass:"export-schedule-footer"})],1),_c('div',{staticClass:"calendar-dashboard-column sidebar-container"},[_c('div',[_c('v-btn',{staticClass:"sidebar-collapse-button",attrs:{"elevation":"2","icon":""},on:{"click":function () { return _vm.showSidebar = !_vm.showSidebar; }}},[(!_vm.showSidebar)?_c('v-icon',[_vm._v(" mdi-chevron-left ")]):_vm._e(),(_vm.showSidebar)?_c('v-icon',[_vm._v(" mdi-chevron-right ")]):_vm._e()],1)],1),(_vm.showSidebar)?_c('div',{staticClass:"sidebar-collapsible-container"},[(_vm.squadsStore)?_c('div',{staticClass:"sidebar-top-container"},[_c('bryntum-combo',{attrs:{"flex":"1","store":_vm.squadsStore,"label":"Select Squad","display-field":"title","value-field":"id","value":_vm.selectedSquad},on:{"select":function (ref) {
            var record = ref.record;

            return _vm.selectedSquad = record;
}}})],1):_vm._e(),_c('div',{staticClass:"sidebar-grids"},[_c('group-grid',{attrs:{"data":_vm.participants.playerGroups,"is-admin":_vm.isAdmin},on:{"grid-mounted":_vm.groupGridMounted,"grid-unmounted":_vm.groupGridUnmounted}}),_c('player-grid',{attrs:{"data":_vm.participants.allPlayers,"is-admin":_vm.isAdmin},on:{"grid-mounted":_vm.playerGridMounted,"grid-unmounted":_vm.playerGridUnmounted}}),_c('session-template-grid',{attrs:{"data":_vm.rawEventTemplates,"is-admin":_vm.isAdmin},on:{"grid-mounted":_vm.sessionTemplateGridMounted,"grid-unmounted":_vm.sessionTemplateGridUnmounted}})],1)]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }