import { ViewPresetConfig } from '@bryntum/schedulerpro'

interface ViewPresetConfigExtension extends ViewPresetConfig {
  base: string
}

interface ZoomLevelsType {
  [key: string]: Partial<ViewPresetConfigExtension>
}

export const ZOOM_LEVEL_DAY = 'benchmark-day'
export const ZOOM_LEVEL_WEEK = 'benchmark-week'
export const ZOOM_LEVEL_MONTH = 'benchmark-month'

const DAY_CONFIG: Partial<ViewPresetConfigExtension> = {
  id: ZOOM_LEVEL_DAY,
  name: 'Day',
  base: 'hourAndDay',
  // Controls the width and height of the scheduler cell
  tickWidth: 180, // tickWidth >= ToolbarConfigs::COLUMN_WIDTH_MIN && tickWidth <= ToolbarConfigs::COLUMN_WIDTH_MAX
  tickHeight: 80, // tickHeight >= ToolbarConfig::TICK_HEIGHT_MIN && tickHeight <= ToolbarConfig::TICK_HEIGHT_MAX
  shiftUnit: 'day',
  shiftIncrement: 1,
  defaultSpan: 1, // Unit defaults to middle header unit
  // Events will be snapped to every 5 min
  timeResolution: {
    unit: 'minute',
    increment: 5
  },
  headers: [
    {
      unit: 'day',
      dateFormat: 'dddd, Do MMMM YYYY', // "Thursday, 7th July 2022"
      align: 'start',
    },
    {
      unit: 'hour',
      dateFormat: 'HH:mm', // "07:30" "19:30"
      headerCellCls: 'vertical-hour-tick',
      align: 'start',
    },
  ]
}

const WEEK_CONFIG: Partial<ViewPresetConfigExtension> = {
  id: ZOOM_LEVEL_WEEK,
  name: 'Week',
  base: 'weekAndDay',
  tickWidth: 170, // tickWidth >= ToolbarConfigs::COLUMN_WIDTH_MIN && tickWidth <= ToolbarConfigs::COLUMN_WIDTH_MAX
  tickHeight: 70, // tickHeight >= ToolbarConfig::TICK_HEIGHT_MIN && tickHeight <= ToolbarConfig::TICK_HEIGHT_MAX
  shiftUnit: 'week',
  shiftIncrement: 1,
  defaultSpan: 1, // Unit defaults to middle header unit
  columnLinesFor: 1,
  // Events will be snapped to every 1 hour
  timeResolution: {
    unit: 'hour',
    increment: 1
  },
  headers: [
    {
      unit: 'day',
      dateFormat: 'ddd, Do MMM YYYY', // "Thu, 7th Jul 2022"
      align: 'start',
    },
    {
      unit: 'hour',
      increment: 6,
      dateFormat: 'HH:mm', // "07:30" "19:30"
      headerCellCls: 'vertical-hour-tick',
      align: 'start',
    },
  ]
}

const MONTH_CONFIG: Partial<ViewPresetConfigExtension> = {
  id: ZOOM_LEVEL_MONTH,
  name: 'Month',
  base: 'monthAndYear',
  tickWidth: 160, // tickWidth >= ToolbarConfigs::COLUMN_WIDTH_MIN && tickWidth <= ToolbarConfigs::COLUMN_WIDTH_MAX
  tickHeight: 60, // tickHeight >= ToolbarConfig::TICK_HEIGHT_MIN && tickHeight <= ToolbarConfig::TICK_HEIGHT_MAX
  shiftUnit: 'month',
  shiftIncrement: 1,
  defaultSpan: 1, // Unit defaults to middle header unit
  // Events will be snapped to every 1 day
  timeResolution: {
    unit: 'day',
    increment: 1
  },
  headers: [
    {
      unit: 'month',
      dateFormat: 'MMMM YYYY', // "July 2022"
      align: 'start',
    },
    {
      unit: 'day',
      increment: 1,
      dateFormat: 'ddd, Do', // "Thu, 7th"
      headerCellCls: 'vertical-hour-tick',
      align: 'start',
    },
  ]
}

export const ZOOM_LEVELS: ZoomLevelsType = {
  [ZOOM_LEVEL_DAY]: DAY_CONFIG,
  [ZOOM_LEVEL_WEEK]: WEEK_CONFIG,
  [ZOOM_LEVEL_MONTH]: MONTH_CONFIG,
}

export const ZoomLevelsConfig: Partial<ViewPresetConfigExtension>[] = Object.values(ZOOM_LEVELS)
