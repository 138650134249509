import {
  ObjectHelper,
  ResourceModel,
  Scheduler,
  SchedulerConfig as BryntumSchedulerConfig,
  SchedulerProFeaturesConfigType,
  StringHelper as SH,
} from '@bryntum/schedulerpro'
import SessionRendererHelper from '@/components/bryntum/helper/SessionRendererHelper'
import { TaskEditConfig } from '@/components/bryntum/configs/TaskEditConfig'
import { ZOOM_LEVEL_DAY, ZOOM_LEVELS, ZoomLevelsConfig } from '@/components/bryntum/configs/ZoomLevelsConfig'
import SessionStore from '@/components/bryntum/stores/SessionStore'
import SessionModel from '@/components/bryntum/models/SessionModel'

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5020
interface BryntumSchedulerConfigExtension extends BryntumSchedulerConfig {
  eventCopyPasteFeature: SchedulerProFeaturesConfigType['eventCopyPaste']
  stripeFeature: SchedulerProFeaturesConfigType['stripe']
  timeRangesFeature: SchedulerProFeaturesConfigType['timeRanges']
  dependenciesFeature: SchedulerProFeaturesConfigType['dependencies']
  filterBarFeature: SchedulerProFeaturesConfigType['filterBar']
  eventMenuFeature: SchedulerProFeaturesConfigType['eventMenu']
  scheduleMenuFeature: SchedulerProFeaturesConfigType['scheduleMenu']
  eventFilterFeature: SchedulerProFeaturesConfigType['eventFilter']
  timeAxisHeaderMenuFeature: SchedulerProFeaturesConfigType['timeAxisHeaderMenu']
  eventTooltipFeature: SchedulerProFeaturesConfigType['eventTooltip']
  taskEditFeature: SchedulerProFeaturesConfigType['taskEdit']
}

interface EventMenuItemArgs {
  source: Scheduler,
  eventRecord: SessionModel,
}

interface ScheduleMenuItemArgs {
  source: Scheduler,
  resourceRecord: ResourceModel,
  date: Date,
}

const SchedulerConfig: Partial<BryntumSchedulerConfigExtension> = {
  zoomOnMouseWheel: false,
  zoomOnTimeAxisDoubleClick: false,
  tickSize: 100, // tickHeight - to be determined later in beforeRouteEnter
  resourceColumns: {
    columnWidth: 100, // columnWidth - to be determined later in beforeRouteEnter
    headerRenderer : ({ resourceRecord }: { resourceRecord: any }) => {
      const style = resourceRecord.color ? `background-color: ${resourceRecord.color}` : ''

      return `
        <div class="header-box">
          <div
            class="location-name"
            data-btip="${resourceRecord.title}"
          >
            ${resourceRecord.title}
          </div>
          <div class="location-colored-bar" style="${style}" />
        </div>
      `
    }
  },
  subGridConfigs: {
    locked: {
      width: 150
    }
  },
  verticalTimeAxisColumn: {
    filterable: {
      filterField: {
        type: 'text',
        cls: 'session-filter',
        placeholder: 'Filter sessions...',
        onChange: ({ value, source: field }: any) => {
          // TODO: fire event instead
          const { owner: schedule } = field
          schedule.eventStore.filter({
            // filter event by name converting to lowerCase to be equal comparison
            filters: (event: any) => event.name.toLowerCase().includes(value.toLowerCase()),
            // to replace all existing filters with a new filter
            replace: true,
          })
        }
      }
    }
  },
  // TODO: add controls to the toolbar to enable/disable/config working hours
  // workingTime: {
  //   fromHour: 6,
  //   toHour: 23
  // },
  barMargin: 0,
  eventStyle: 'colored',
  mode: 'vertical',
  eventLayout: 'pack',
  enableRecurringEvents: true,
  resourceImagePath: 'users/',
  createEventOnDblClick: {
    useEventModelDefaults: true,
  },
  columns: [
    { type: 'resourceInfo', field: 'title', text: 'Location', width: 165 }
  ],
  project: {
    autoLoad: true,
    eventStore: {
      ...SessionStore.defaultConfig,
    },
    autoSync: false,
    writeAllFields: true,
  },
  eventRenderer({ eventRecord: session }: { eventRecord: SessionModel }) {
    const snapshot = session.snapshotId
      ? session.eventStore.getById(session.snapshotId) as SessionModel
      : undefined

    return `
      <div class="session-box">
        <div class="session-header">
          ${SessionRendererHelper.renderPublishingInfo(session)}
          ${SessionRendererHelper.renderSessionName(session, snapshot)}
          ${SessionRendererHelper.renderTimeAndDuration(session, snapshot)}
          ${SessionRendererHelper.renderLocation(session, snapshot)}
          ${SessionRendererHelper.renderSessionTemplate(session)}
        </div>
        <div class="session-body">
          ${SessionRendererHelper.renderSessionGroups(session, snapshot)}
          ${SessionRendererHelper.renderSessionStaff(session, snapshot)}
          ${SessionRendererHelper.renderSessionAthlete(session, snapshot)}
        </div>
        <div class="session-footer">
          ${SessionRendererHelper.renderSessionNotes(session, snapshot)}
        </div>
      </div>
    `
  },
  snap: true,
  presets: [...ZoomLevelsConfig],
  viewPreset: ZOOM_LEVEL_DAY,
  zoomKeepsOriginalTimespan: true,
  weekStartDay: 1, // TODO: remove when intro UK locale
  // region Features
  stripeFeature: true,
  timeRangesFeature: true,
  dependenciesFeature: false,
  filterBarFeature: true,
  // Disabled due to the bug: https://github.com/bryntum/support/issues/6021
  // Implemented a custom solution.
  eventCopyPasteFeature: false,
  eventMenuFeature: {
    items: {
      copyEvent: {
        text: 'Copy Session',
        icon: 'b-fa-copy',
        weight: 190,
        onItem: ({ source: schedule, eventRecord: sessionRecord }: EventMenuItemArgs) => {
          schedule.trigger('copySessionPerTime', { sessionRecord })
        }
      },
      deleteEvent: {
        text: 'Delete Session',
      },
      unassignEvent: false,
    }
  },
  scheduleMenuFeature: {
    items: {
      addEvent: {
        text: 'Create Session',
      },
      pasteEvent: {
        text: 'Paste Session',
        icon: 'b-fa-paste',
        weight: 195,
        onItem: ({ source: schedule, date: startDate, resourceRecord: locationRecord }: ScheduleMenuItemArgs) => {
          schedule.trigger('pasteSessionPerTime', { startDate, locationRecord })
        }
      },
    }
  },
  eventFilterFeature: false,
  timeAxisHeaderMenuFeature: {
    items: {
      dateRange: false,
      currentTimeLine: false,
      zoomLevel: false,
      newStudio: {
        text: 'Create New Studio',
        icon: 'b-fa-plus',
        onItem(): any {
          // TODO: use isAdmin to show this item
          window.open(`${process.env.VUE_APP_NOVA_URL}/nova/resources/studios/new`)
        }
      },
    }
  },
  eventTooltipFeature: {
    allowOver: true,
    hideWhenEmpty: true,
    template: ({ eventRecord: session }: { eventRecord: SessionModel }) => {
      let html = ''

      if (session.sessionTemplate?.name) {
        html += `
          <div class="tooltip-content">
            <div class="tooltip-content-title">Session Template:</div>
            <div class="tooltip-content">
              <i>${SH.encodeHtml(session.sessionTemplate.name)}</i>
            </div>
          </div>
        `
      }

      if (session.groups.length > 0) {
        const groupsHtmlList = Object.values(session.groupsWithPlayerDiff).map(groupInfo => {
          let groupHtml = groupInfo.group.title

          if (groupInfo.removedPlayers.length > 0) {
            const removedPlayerNames = groupInfo.removedPlayers.map(player => player.fullName)
            const removedPlayersHtml = `<span class="removed-players">${removedPlayerNames.join(', ')}</span>`

            groupHtml = `${groupHtml} (${removedPlayersHtml})`
          }

          return groupHtml
        })

        html += `
          <div class="tooltip-content">
            <div class="tooltip-content-title">Groups:</div>
            <div class="tooltip-content"><i>${groupsHtmlList.join(', ')}</i></div>
          </div>
        `
      }

      if (session.staffNames.length > 0) {
        html += `
          <div class="tooltip-content">
            <div class="tooltip-content-title">Staff:</div>
            <div class="tooltip-content"><i>${session.staffNames.join(', ')}</i></div>
          </div>
        `
      }

      if (session.athleteNames.length > 0) {
        html += `
          <div class="tooltip-content">
            <div class="tooltip-content-title">Athletes:</div>
            <div class="tooltip-content"><i>${session.athleteNames.join(', ')}</i></div>
          </div>
        `
      }

      if (session.publicNote && session.publicNote.length > 0) {
        html += `
          <div class="tooltip-content">
            <div class="tooltip-content-title">Notes:</div>
            <div class="tooltip-content"><i>${SH.encodeHtml(session.publicNote)}</i></div>
          </div>
        `
      }

      return html.length ? `<div>${html}</div>` : ''
    }
  },
  taskEditFeature: ObjectHelper.clone(TaskEditConfig),
  // endregion
}

export { SchedulerConfig }
