import VueAxios from '@/api/VueAxios'
import SnackbarError from '@/components/snackbar/SnackbarError'

declare global {
    interface Window {
        $cookies: any;
    }
}

const PlayerGroup = {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        SET_LIST(state: any, payload: any) {
            PlayerGroup.state.list = payload
        }
    },
    actions: {
        async FETCH_LIST({commit, dispatch}: any, filters: { key: string, value: number|string }[] = []) {
            try {
                let params = ''
                if (filters.length) {
                    // ?filter[sport_position_id]=99&filter[sport_id]=5
                    params = '?' + filters.map(filter => `filter[${filter.key}]=${filter.value}`).join('&')
                }
                const response = await VueAxios.get('/player-groups' + params)
                const list = response.data
                await commit('SET_LIST', list)

                return list
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
    },
    getters: {
        getPlayerGroups(state: any) {
            return PlayerGroup.state.list
        }
    },

}

export default PlayerGroup
