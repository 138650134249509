import { DataFieldConfig, Model } from '@bryntum/schedulerpro'

// Implicitly adds new fields to an existing interface
interface SessionElementModel {
  id: string | number
  elementId: number
  title: string
  rpe: number
  duration: number
  _workload: number
  playerIds: number[]
}

// Types of the raw fields
export interface SessionElementRawObject {
  id: string | number
  element_id: number
  element_title: string
  rpe: number
  duration: number
  workload: number
  player_ids: number[]
}

// Explicitly extends Bryntum types and adds missing types
// TODO: remove once the issue is fixed: https://github.com/bryntum/support/issues/5017
interface DataFieldConfigExtension extends DataFieldConfig {
  type: string
}

/**
 * SessionElementModel is used to describe element in events[].session_elements[].
 * We assign players to elements there.
 */
class SessionElementModel extends Model {
  static $name = 'SessionElementModel'

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      { name: 'elementId', type: 'number', dataSource: 'element_id' },
      { name: 'title', type: 'string', dataSource: 'element_title' },
      { name: 'rpe', type: 'number', defaultValue: 0 },
      { name: 'duration', type: 'number', defaultValue: 0 },
      { name: 'workload', type: 'number', defaultValue: 0 },
      { name: 'playerIds', type: 'array', defaultValue: [], dataSource: 'player_ids' },
    ]
  }

  get isSessionElementModel(): boolean {
    return true
  }

  set workload(value: number | null) {
    this._workload = this.calculateWorkload
  }

  get workload(): number {
    return this.calculateWorkload
  }

  get calculateWorkload(): number {
    return this.rpe * this.duration
  }
}

export default SessionElementModel
