import VueAxios from '@/api/VueAxios'
import SnackbarError from '@/components/snackbar/SnackbarError'

declare global {
    interface Window {
        $cookies: any;
    }
}

const Surface = {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        SET_LIST(state: any, payload: any) {
            Surface.state.list = payload
        }
    },
    actions: {
        async FETCH_LIST({commit, dispatch}: any) {
            try {
                const { data } = await VueAxios.get('/surfaces')
                await commit('SET_LIST', data)
                return data
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
    },
    getters: {
        getSurfaces(state: any) {
            return Surface.state.list
        }
    },

}

export default Surface
