import { DataFieldConfig, Model } from '@bryntum/schedulerpro'

// Implicitly adds new fields to an existing interface
interface EventTypeModel {
  title: string
  sectionName: string
  isActive: boolean
  itemOrder: number
  createLabel: string
  createUrl: string
  specificLabels: {
    person: string
    session_name: string
  }
}

// Explicitly extends Bryntum types and adds missing types
// TODO: remove once the issue is fixed: https://github.com/bryntum/support/issues/5017
interface DataFieldConfigExtension extends DataFieldConfig {
  type: string
}

/**
 * EventTypeModel is used to describe event-templates[].event_type
 */
class EventTypeModel extends Model {
  static $name = 'EventTypeModel'

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      // Add new fields
      { name: 'title', type: 'string' },
      { name: 'sectionName', type: 'string', dataSource: 'section_name' },
      { name: 'isActive', type: 'boolean', dataSource: 'is_active' },
      { name: 'itemOrder', type: 'number', dataSource: 'item_order' },
      { name: 'createLabel', type: 'string', dataSource: 'create_label' },
      { name: 'createUrl', type: 'string', dataSource: 'create_url' },
      //   specific_labels: {
      //     person: "Supervisor / Coach",
      //     session_name: "Gym Session"
      //   }
      { name: 'specificLabels', type: 'object', dataSource: 'specific_labels' },
    ]
  }

  get isEventTypeModel(): boolean {
    return true
  }
}

export default EventTypeModel
