<template>
  <v-container class="ma-xl-1">
    <v-row v-if="twoFaEnabled">
      <v-col md="3">
        <h2>Two factor authentication</h2>

        <p>Your account is secured by 2fa.</p>

        <v-text-field
            v-model="currentPassword"
            label="Current Password"
            hide-details="auto"
            @keyup.enter="disableTwoFactorClick"
        />

        <v-btn
            class="mt-2 float-right"
            primary
            color="primary"
            :disabled="isPasswordEmpty || isLoading"
            @click="disableTwoFactorClick"
        >
          Disable 2fa
        </v-btn>
      </v-col>
    </v-row>

    <v-row
        v-else
        justify="start"
    >
      <v-col md="6">
        <h2>Two factor authentication.</h2>
        <p class="pt-2">
          Two-factor is an enhanced security measure. Once enabled, you'll be required to give two types of
          identification
          when you log into app.
        </p>

        <v-switch
            v-model="enableTwoFactorSwitcher"
            :label="'Enable Two-Factor Auth'"
        />

        <v-container
            v-show="enableTwoFactorSwitcher"
            class="pa-0"
        >
          <v-row>
            <v-col>
              <p class="pt-6">
                <v-chip>1</v-chip>
                Scan your QR code using Authenticator App

                Scan the QR code with your mobile device’s app. After scanning, the app displays a six-digit code that
                you can enter on field bellow.
                If you can't scan the QR code, click enter this text code to see a code you can copy and manually enter
                instead.
              </p>

              <p>
                <span class="pink--text">{{ qrCode.secret }}</span>
              </p>

              <v-btn
                  color="primary"
                  :disabled="codeGenerated"
                  @click="getQrCode"
              >
                Generate QR-Code
              </v-btn>
            </v-col>
            <v-col>
              <v-img
                  :src="qrCode.image"
                  width="250"
              />
            </v-col>
          </v-row>

          <v-row v-show="codeGenerated">
            <v-col md="6">
              <v-container class="pl-0 pr-0">
                <p>
                  <v-chip>2</v-chip>
                  Then enter six-digit code from Authenticator App:
                </p>
                <v-text-field
                    v-model="sixDigitCode"
                    label="Secret Code"
                    hide-details="auto"
                    counter
                    :rules="[rules.required, rules.min]"
                    @keyup.enter="submitCode"
                />
              </v-container>
              <v-btn
                  :disabled="isLoading"
                  class="secondary float-right"
                  @click="submitCode"
              >
                Send
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { Action, Getter, Mutation } from 'vuex-class'
import { Component } from 'vue-property-decorator'

@Component
export default class UserSecurity extends Vue {
  data () {
    return {
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length === 6 || 'Exactly 6 characters',
      },
      currentPassword: '',
      enableTwoFactorSwitcher: false,
      sixDigitCode: null,
      codeGenerated: false
    }
  }

  get isPasswordEmpty () {
    return this.currentPassword === ''
  }

  get twoFaEnabled () {
    return this.user.two_fa_enabled
  }

  @Mutation('auth/SET_FA_ENABLED') setFaEnabled
  @Mutation('Index/SET_LOADING') setLoading

  @Action('Auth/ME') fetchUser
  @Action('LoginSecurity/GENERATE_QR_CODE') generateQrCode
  @Action('LoginSecurity/ENABLE_TWO_FACTOR') enableTwoFactor
  @Action('LoginSecurity/DISABLE_TWO_FACTOR') disableTwoFactor

  @Getter('LoginSecurity/qrCode') qrCode
  @Getter('Auth/user') user
  @Getter('Index/loading') isLoading

  mounted () {
    this.fetchUser()
  }

  async disableTwoFactorClick () {
    if (this.twoFaEnabled) {
      this.setLoading(true)
      try {
        const disableR = await this.disableTwoFactor(this.currentPassword)
        const { success } = disableR
        if (success) {
          this.setFaEnabled(false)
        }

      } catch (e) {
        throw e
      } finally {
        this.currentPassword = ''
        this.setLoading(false)
      }
    }
  }

  async submitCode () {
    try {
      this.setLoading(true)
      const response = await this.enableTwoFactor(this.sixDigitCode)
      this.setFaEnabled(true)
      this.sixDigitCode = ''
      this.enableTwoFactorSwitcher = false
    } catch (e) {
      throw e
    } finally {
      this.setLoading(false)
    }
  }

  async getQrCode () {
    this.setLoading(true)
    try {
      await this.generateQrCode()
      this.codeGenerated = true
    } catch (e) {
      throw e
    } finally {
      this.setLoading(false)
    }
  }
}
</script>

<style scoped>

</style>
