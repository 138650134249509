<template>
  <v-main id="pages">
    <v-responsive
        class="d-flex align-center"
        min-height="100vh"
    >
      <router-view/>
    </v-responsive>
  </v-main>
</template>

<script>
export default {
  name: 'AuthLayout',
}
</script>
