import { Store, StoreConfig } from '@bryntum/schedulerpro'
import SessionTemplateModel from '@/components/bryntum/models/SessionTemplateModel'

// SessionTemplateModel is not really an Event. It is a draft. Therefore, we define a Store, not an EventStore.
class SessionTemplateStore extends Store {
  static $name = 'SessionTemplateStore'

  static get defaultConfig(): Partial<StoreConfig> {
    return {
      modelClass: SessionTemplateModel,
      sorters: [
        { field: 'name', ascending: true }
      ],
      groupers: [
        { field: 'eventTypeLabel', ascending: true },
      ]
    }
  }
}

export default SessionTemplateStore
