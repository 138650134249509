










import { BryntumGrid } from '@bryntum/schedulerpro-vue'
import { PlayerGridConfig } from '@/components/bryntum/configs/PlayerGridConfig'

export default {
  name: 'PlayerGrid',

  components: {
    BryntumGrid,
  },

  props: {
    data: { type: Array, default: undefined },
    isAdmin: { type: Boolean, default: false },
  },

  data() {
    return {
      PlayerGridConfig,
    }
  },

  computed: {
    createPlayerUrl(): string {
      return `${process.env.VUE_APP_NOVA_URL}/nova/resources/players/new`
    },
  },

  watch: {
    isAdmin(value: boolean): void {
      this.updateGridRights(value)
    }
  },

  mounted(): void {
    this.updateGridRights(this.isAdmin)

    const grid = this.$refs.playerGrid.instance
    grid.on('addNewPlayerClick', this.onAddNewPlayerClick)

    this.$emit('grid-mounted', grid)
  },

  beforeDestroy(): void {
    const grid = this.$refs.playerGrid.instance
    grid.un('addNewPlayerClick')

    this.$emit('grid-unmounted', grid)
  },

  methods: {
    updateGridRights(isAdmin: boolean): void {
      const grid = this.$refs.playerGrid.instance
      grid.adminRights = isAdmin
    },
    onAddNewPlayerClick(): void {
      if (this.isAdmin) {
        window.open(this.createPlayerUrl)
      }
    }
  }
}
