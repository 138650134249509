import { Store, StoreConfig } from '@bryntum/schedulerpro'
import ElementWorkloadModel from '@/components/bryntum/models/ElementWorkloadModel'

interface WorkloadSummary {
  elementIds: (string | number)[]
  totalRpe: number
  totalDuration: number
  totalWorkload: number
}

class ElementWorkloadStore extends Store {
  static $name = 'ElementWorkloadStore'

  static get defaultConfig(): Partial<StoreConfig> {
    return {
      modelClass: ElementWorkloadModel,
    }
  }

  static workloadSummary(records: ElementWorkloadModel[]): WorkloadSummary {
    let totalDuration  = 0
    let totalWorkload  = 0
    const elementIds = [] as (string | number)[]

    records.forEach(r => {
      totalDuration += r.actualDuration || r.duration
      totalWorkload += r.workload
      elementIds.push(r.id)
    })

    const totalRpe  = totalDuration !== 0
      ? Math.round(((totalWorkload / totalDuration) + Number.EPSILON) * 100) / 100
      : 0

    return {
      elementIds,
      totalRpe,
      totalDuration,
      totalWorkload,
    }
  }
}

export default ElementWorkloadStore
