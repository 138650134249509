import VueAxios from '@/api/VueAxios'
import SnackbarError from '@/components/snackbar/SnackbarError'

const Studio = {
    namespaced: true,
    state: {
        list: [],

    },
    mutations: {
        SET_LIST(state: any, payload: any) {
            Studio.state.list = payload
        }
    },
    actions: {
        async FETCH_LIST({commit, dispatch}: any, params: any) {
            try {
                const response = await VueAxios.get('/studios')
                response.data.forEach((studio: any) => {
                    studio.name = studio.title

                    return studio
                })

                const list = response.data
                await commit('SET_LIST', list)

                return list
            } catch (err) {
                SnackbarError({commit, dispatch}, err)
                throw err
            }
        },
    },
    getters: {
        getStudios() {
            return Studio.state.list
        }
    },

}

export default Studio
