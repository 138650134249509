import {
  Column,
  ColumnConfig,
  DomConfig,
  Grid,
  GridConfig,
  GridFeaturesConfigType,
  NumberColumnConfig,
  Popup,
} from '@bryntum/schedulerpro'
import WorkloadStore from '@/components/bryntum/stores/WorkloadStore'
import WorkloadModel from '@/components/bryntum/models/WorkloadModel'
import { ElementWorkloadGridConfig } from '@/components/bryntum/configs/ElementWorkloadGridConfig'
import ElementWorkloadModel from '@/components/bryntum/models/ElementWorkloadModel'

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5031
interface ColumnConfigExtension extends ColumnConfig, NumberColumnConfig {
  type: string
}

// TODO: clean up
interface ColumnExtension extends Column {
  field: string
}

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5020
interface GridConfigExtension extends GridConfig {
  stripeFeature: GridFeaturesConfigType['stripe']
  filterFeature: GridFeaturesConfigType['filter']
  cellTooltipFeature: GridFeaturesConfigType['cellTooltip']
  columnPickerFeature: GridFeaturesConfigType['columnPicker']
  sortFeature: GridFeaturesConfigType['sort']
  groupFeature: GridFeaturesConfigType['group']
  headerMenuFeature: GridFeaturesConfigType['headerMenu']
  cellMenuFeature: GridFeaturesConfigType['cellMenu']
  rowCopyPasteFeature: GridFeaturesConfigType['rowCopyPaste']
}

interface CellMenuItemArgs {
  source: Grid
  cellData: { column: string }
  record: WorkloadModel
}

interface CellClickItemArgs {
  grid: Grid,
  record: WorkloadModel
  column: ColumnExtension
  cellElement: HTMLElement
}

interface TooltipRendererArgs {
  record: WorkloadModel
}

const PLAYER_ELEMENT_WORKLOADS_FIELD = 'elementWorkloads'
const PLAYER_ELEMENT_WORKLOADS_TOTAL_RPE_FIELD = 'sessionElementsTotalRpe'
const PLAYER_ELEMENT_WORKLOADS_TOTAL_DURATION_FIELD = 'sessionElementsTotalDuration'
const PLAYER_ELEMENT_WORKLOADS_TOTAL_WORKLOAD_FIELD = 'sessionElementsTotalWorkload'

const playerElementWorkloadFields = [
  PLAYER_ELEMENT_WORKLOADS_FIELD,
  PLAYER_ELEMENT_WORKLOADS_TOTAL_RPE_FIELD,
  PLAYER_ELEMENT_WORKLOADS_TOTAL_DURATION_FIELD,
  PLAYER_ELEMENT_WORKLOADS_TOTAL_WORKLOAD_FIELD,
]

const columns: Partial<ColumnConfigExtension>[] = [
  {
    text: 'Athlete',
    field: 'fullName',
    width: 200,
    locked: true,
    editor: false,
    enableCellContextMenu: false,
    renderer: ({ value, record: workloadRecord }: { value: string, record: WorkloadModel }): DomConfig => {
      return {
        children: [
          {
            tag: 'i',
            dataset: {
              btip: workloadRecord.availabilityText,
            },
            className: {
              'b-fa': true,
              'b-fa-user': true,
              'player-icon': true,
              'player-injured': !workloadRecord.isAvailable,
            }
          },
          {
            tag: 'span',
            text: value,
          },
        ]
      }
    },
  },
  {
    text: 'Total<br>Jumps',
    htmlEncodeHeaderText: false,
    field: 'jumps',
    width: 80,
    align: 'center',
    type: 'number',
    min: 0,
  },
  {
    text: 'Elements and Characters (Calculated)',
    align: 'center',
    // collapsible: true, // Doesn't work in Scheduler 5.0.5
    children: [
      {
        text: 'Elements /<br>Characters',
        htmlEncodeHeaderText: false,
        field: PLAYER_ELEMENT_WORKLOADS_FIELD,
        type: 'widget',
        width: 150,
        enableCellContextMenu: false,
        tooltip: '<p>Click on the cell to edit duration the player spent on a session element</p>',
        tooltipRenderer({ record }: TooltipRendererArgs): string {
          return record.playerElementNames.join('<br />')
        },
        renderer: ({ value }: { value: ElementWorkloadModel[] }): DomConfig | string => {
          if (value.length === 0) {
            return 'Not set'
          }

          return {
            children: [
              {
                tag: 'span',
                text: value.map(
                  playerSessionElementRecord => playerSessionElementRecord.title
                ).join(', '),
              },
            ]
          }
        },
      },
      {
        text: 'RPE',
        field: PLAYER_ELEMENT_WORKLOADS_TOTAL_RPE_FIELD,
        enableCellContextMenu: false,
        width: 80,
        align: 'center',
        type: 'number',
        min: 0,
        max: 10,
      },
      {
        text: 'Duration<br>(mins)',
        htmlEncodeHeaderText: false,
        field: PLAYER_ELEMENT_WORKLOADS_TOTAL_DURATION_FIELD,
        enableCellContextMenu: false,
        width: 90,
        align: 'center',
        type: 'number',
        min: 0,
      },
      {
        text: 'Workload<br>(AU)',
        htmlEncodeHeaderText: false,
        field: PLAYER_ELEMENT_WORKLOADS_TOTAL_WORKLOAD_FIELD,
        enableCellContextMenu: false,
        width: 90,
        align: 'center',
        type: 'number',
      }
    ]
  },
  {
    text: 'Actual values (Manual)',
    align: 'center',
    // collapsible: true, // Doesn't work in Scheduler 5.0.5
    children: [
      {
        text: 'RPE',
        field: 'rpe',
        width: 80,
        align: 'center',
        type: 'number',
        min: 0,
        max: 10,
      },
      {
        text: 'Duration<br>(mins)',
        htmlEncodeHeaderText: false,
        field: 'duration',
        width: 90,
        align: 'center',
        type: 'number',
        min: 0,
      },
      {
        text: 'Workload<br>(AU)',
        htmlEncodeHeaderText: false,
        field: 'workload',
        width: 90,
        align: 'center',
        type: 'number',
        editor: false,
        enableCellContextMenu: false,
      },
    ],
  },
  {
    text: 'Presence',
    field: 'presence',
    width: 80,
    align: 'center',
    type: 'check',
  },
  {
    text: 'Notes',
    field: 'notes',
    width: 300,
    renderer: ({ value }: { value: string }): DomConfig => {
      return value ? {
        tag: 'div',
        class: 'notes-cell-container',
        dataset: {
          btip: value,
        },
        html: value,
      } : {}
    },
  },
]

const WorkloadGridConfig: Partial<GridConfigExtension> = {
  cls: 'workload-grid',
  rowHeight: 50,
  // TODO: declare store type once the issue is fixed: https://github.com/bryntum/support/issues/5021
  store: {
    ...WorkloadStore.defaultConfig,
  },
  columns,
  listeners: {
    cellClick: ({ grid, record: workloadRecord, column, cellElement }: CellClickItemArgs) => {
      if (playerElementWorkloadFields.includes(column.field)) {
        const playerSessionElementsPopup = new Popup({
          cls: 'player-session-elements-popup',
          header: workloadRecord.fullName,
          forElement: cellElement,
          scrollAction: 'realign',
          width: 600,
          height: 400,
          anchor: true,
          closeAction: 'destroy',
          closable: true,
          layout: {
            type: 'box',
            direction: 'column',
            align: 'stretch',
          },
          items: {
            playerElementWorkloadGrid: {
              type: 'grid',
              ...ElementWorkloadGridConfig,
              flex: 1,
              data: workloadRecord.elementWorkloads
            }
          },
          bbar: {
            items: {
              close: {
                text: 'Cancel',
                minWidth: 100,
                onAction: 'up.close',
                cls: 'b-raised b-gray',
              },
              save: {
                text: 'Apply',
                minWidth: 100,
                onAction: 'up.close',
                cls: 'b-raised b-green',
                onClick : ({ source: button }:any) => {
                  const grid = button.up('popup').widgetMap.playerElementWorkloadGrid
                  workloadRecord.setElementWorkloads([...grid.store.records])
                }
              }
            }
          },
        })

        grid.on({
          editorBeforeHide: () => {
            playerSessionElementsPopup.close()
          },
          thisObj: playerSessionElementsPopup,
        })
      }
    }
  },
  // region Features (to be applied to ES6 Bryntum component instead of Vue component)
  features: {
    stripe: true,
    filter: false,
    columnPicker: false,
    columnReorder: false,
    cellTooltip: true,
    sort: false,
    group: false,
    headerMenu: false,
    rowCopyPaste: false,
    cellMenu: {
      items: {
        removeRow: false,
        bulkUpdate: {
          text: 'Bulk Update',
          icon: 'b-fa b-fa-arrow-down',
          onItem({ source: grid, cellData, record }: CellMenuItemArgs) {
            const value = record.get(cellData.column)
            const store = grid.store as WorkloadStore
            store.beginBatch()
            let nextRecord = store.getNext(record) as WorkloadModel | null
            while (nextRecord) {
              nextRecord.set(cellData.column, value)
              nextRecord = store.getNext(nextRecord) as WorkloadModel | null
            }
            store.endBatch()
          },
          weight: 140
        }
      },
    }
  },
  // endregion
}

export { WorkloadGridConfig }
