import { ColumnStore, DateHelper, Grid, Store } from '@bryntum/schedulerpro'
import SessionModel from '@/components/bryntum/models/SessionModel'
import PlayerModel from '@/components/bryntum/models/PlayerModel'
import PlayersIntersectionModel from '@/components/bryntum/models/PlayersIntersectionModel'

interface AssignmentValidatorGrid {
  store: Store,
  intersections: PlayersIntersectionModel[]
}

class AssignmentValidatorGrid extends Grid {
  static get $name() {
    return 'AssignmentValidatorGrid'
  }

  static get type() {
    return 'assigmentvalidatorgrid'
  }

  construct({ rangeSessions, targetSession, selectedPlayers }: {
    rangeSessions: SessionModel[],
    targetSession: SessionModel,
    selectedPlayers: PlayerModel[]
  }) {
    const sideSessions = rangeSessions.filter((session: SessionModel) => session.id !== targetSession.id)
    const intersections = this.getPlayersIntersections(sideSessions, selectedPlayers, targetSession)
    const columns = this.getColumns(intersections, targetSession)
    const data = this.getData(intersections)
    const fields = this.getFields(intersections)
    this.intersections = intersections
    const config = {
      height: 300,
      columns: {
        data: columns
      },
      store: {
        fields,
        data
      }
    }
    super.construct(config)
  }

  private getColumns(intersections: PlayersIntersectionModel[], targetSession: SessionModel) {
    const columns = [
      {
        flex: 2,
        minWidth: 200,
        field: 'name',
        text: 'Session player',
        order: 0
      },
      ...intersections.map((intersection: PlayersIntersectionModel) => {
        const session = intersection.session
        const isTargetSession = targetSession.id === session.id
        const sessionLocation = session.resource.name
        const sessionStartTime = DateHelper.format(session.startDate as Date, 'HH:mm')

        return {
          text: `${session.name}<br>(${sessionLocation})<br>at ${sessionStartTime}`,
          htmlEncodeHeaderText: false,
          field: session.id.toString(),
          flex: 1,
          minWidth: 150,
          type: 'check',
          align: 'center',
          cls: isTargetSession ? 'validator-col-background' : '',
          cellCls: isTargetSession ? 'validator-col-background' : '',
          order: isTargetSession ? 1 : 2,
          renderer: ({ widgets, record }: any) => {
            const checkbox = widgets?.[0]
            if (checkbox) {
              /**
               *  Disable the checkbox for all side sessions in which the player does not participate.
               *  https://www.bryntum.com/docs/scheduler-pro/api/Grid/column/CheckColumn
               */
              const playerId = record.id
              const isPlayerAssignedToSession = session.isPlayerAssigned(playerId)
              checkbox.disabled = !isTargetSession && !isPlayerAssignedToSession
            }
          }
        }
      })
    ]
    return columns.sort((col1, col2) => col1.order - col2.order)
  }

  private getData(intersections: PlayersIntersectionModel[]) {
    const data: any = {}
    intersections.forEach((intersection: PlayersIntersectionModel) => {
      intersection.players.forEach((player: PlayerModel) => {
        if (!data[player.id]) {
          data[player.id] = {
            id: player.id,
            name: player.fullName,
            [intersection.session.id]: true
          }
        } else {
          data[player.id] = {
            ...data[player.id],
            [intersection.session.id]: true
          }
        }
      })
    })
    return Object.values(data)
  }

  private getPlayersIntersections(
    sideSessions: SessionModel[],
    selectedPlayers: PlayerModel[],
    targetSession: SessionModel): PlayersIntersectionModel[] {
    const intersections: PlayersIntersectionModel[] = []
    let targetSessionPlayers: PlayerModel[] = []
    sideSessions.forEach((session: SessionModel) => {
      // Checking intersection of sessionPlayers with selectedPlayers
      const sessionPlayers = session.players.filter(
        (sessionPlayer: PlayerModel) => selectedPlayers.find(
          (selectedPlayer: PlayerModel) => sessionPlayer.id === selectedPlayer.id
        )
      )
      if (sessionPlayers.length > 0) {
        const intersection = new PlayersIntersectionModel({
          session,
          players: sessionPlayers
        })
        intersections.push(intersection)
        // Add only unique players to the target session
        targetSessionPlayers = [...targetSessionPlayers, ...sessionPlayers.filter(
          (player: PlayerModel) => !targetSessionPlayers.find(
            (targetPlayer: PlayerModel) => targetPlayer.id === player.id
          )
        )]
      }
    })
    intersections.push(new PlayersIntersectionModel({
      session: targetSession,
      players: targetSessionPlayers
    }))
    return intersections
  }

  private getFields(intersections: PlayersIntersectionModel[]) {
    const fields = [
      'name',
      ...intersections.map((intersection: PlayersIntersectionModel) => intersection.session.id.toString())
    ]
    return fields
  }

  public uncheckSideSessions(targetSessionId: number) {
    const { records } = (this.store as Store)
    const columns = (this.columns as ColumnStore)

    // Taking only session columns ids by type 'check'
    const sessionColumnsIds = new Map()
    columns.query((column: any) => column.type && column.type === 'check')
      .forEach((column: any) => {
        // Column "field" has type string
        sessionColumnsIds.set(column.field, column.field === targetSessionId.toString())
      })
    records.forEach((record: any) => {
      record.set(Object.fromEntries(sessionColumnsIds))
    })
  }

  public filterSessionsPlayers(): void {
    this.intersections.forEach((intersection: PlayersIntersectionModel) => {
      // Taking grid records where current session marked as false
      const filters: any = []
      this.store.forEach((filter: any) => {
        if (filter[intersection.session.id] === false) {
          filters.push(filter)
        }
      })
      intersection.session.players = intersection.session.players.filter(
        (player: PlayerModel) => !filters.find(
          (filter: any) => player.id === filter.id
        )
      )
    })
  }
}

AssignmentValidatorGrid.initClass()

export default AssignmentValidatorGrid

