import {
  Column,
  ColumnConfig,
  Grid,
  GridConfig,
  GridFeaturesConfigType,
  Menu,
  MenuItem,
  StringHelper
} from '@bryntum/schedulerpro'
import GroupStore from '@/components/bryntum/stores/GroupStore'
import GroupModel from '@/components/bryntum/models/GroupModel'

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5031
interface ColumnConfigExtension extends ColumnConfig {
  type: string
}

// TODO: use Bryntum type once the issue is fixed: https://github.com/bryntum/support/issues/5020
interface GridConfigExtension extends GridConfig {
  stripeFeature: GridFeaturesConfigType['stripe']
  filterFeature: GridFeaturesConfigType['filter']
  cellTooltipFeature: GridFeaturesConfigType['cellTooltip']
  columnPickerFeature: GridFeaturesConfigType['columnPicker']
  sortFeature: GridFeaturesConfigType['sort']
  groupFeature: GridFeaturesConfigType['group']
  headerMenuFeature: GridFeaturesConfigType['headerMenu']
}

type TooltipRendererArgs = {
  record: GroupModel
}

type MenuItemArgs = {
  menu: Menu
}

type ProcessMenuItemsArgs = {
  column: Column,
  items: {
    addNewRecord: MenuItem
  }
}

const columns: Partial<ColumnConfigExtension>[] = [
  {
    text: 'Groups',
    field: 'title',
    icon: 'b-fa b-fa-circle-info',
    tooltip: `
      <h4>Athletes and staff organised in user groups</h4>
      <p>Hover members number to see who is in the group.</p>
      <p>Drag a group onto a session to assign its members to the session.</p>
    `,
    flex: 1,
    htmlEncode : false,
    renderer: ({ value }: { value: string }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return StringHelper.xss`
        <i class="b-fa b-fa-user-group"></i>
        <span class="cell-value">${value}</span>`
    },
  },
  {
    htmlEncodeHeaderText: false,
    text: 'Total<br/>Members',
    field: 'playerCount',
    type: 'number',
    width: 80,
    sortable: false,
    filterable: false,
    align: 'center',
    tooltipRenderer({ record }: TooltipRendererArgs): string {
      return record.playerNames.join('<br />')
    }
  },
]

const GroupGridConfig: Partial<GridConfigExtension> = {
  cls: 'groups-grid',
  readOnly: true,
  rowHeight: 20,
  // TODO: declare store type once the issue is fixed: https://github.com/bryntum/support/issues/5021
  store: {
    ...GroupStore.defaultConfig,
  },
  columns,
  // region Features
  stripeFeature: true,
  filterFeature: true,
  cellTooltipFeature: true,
  columnPickerFeature: false,
  sortFeature: {
    multiSort: false,
  },
  groupFeature: false,
  headerMenuFeature: {
    items: {
      // Hide filter feature menu icons
      filter: false,
      editFilter: false,
      removeFilter: false,
      // Hide sort feature menu icons
      sortAsc: false,
      sortDesc: false,
      // Additional items
      addNewRecord: {
        text: 'Create New Group',
        icon: 'b-fa-plus',
        weight: 200,
        onItem: ({ menu }: MenuItemArgs) => {
          menu.owner.trigger('addNewGroupClick')
        }
      },
    },
    processItems({ column, items }: ProcessMenuItemsArgs) {
      const grid = column.subGrid.owner as Grid
      // TODO: Introduce public adminRights property. Property 'adminRights' does not exist on type 'Grid'.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      items.addNewRecord.hidden = !grid.adminRights
    }
  }
  // endregion
}

export { GroupGridConfig }
