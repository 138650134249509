import { StringHelper as SH, ObjectHelper as OH } from '@bryntum/schedulerpro'
import SessionModel, { GroupInfo } from '@/components/bryntum/models/SessionModel'
import PlayerModel from '@/components/bryntum/models/PlayerModel'

const ACTUAL_VALUE_CLASS = 'actual-value'
const ORIGINAL_VALUE_CLASS = 'original-value'
const FIELD_VALUE_CLASS = 'field-value'

const PUBLISHED_BADGE = `
  <div class="session-status">
    <span><i class="b-fa b-fa-check-circle"></i>Published</span>
  </div>
`
const SNAPSHOT_BADGE = `
  <div class="session-snapshot">
    <span>_Snapshot_</span>
  </div>
`

interface SessionFieldDiff {
  valueExist: boolean
  valueEqual: boolean
  valueUpdated: boolean
  valueAdded: boolean
  valueRemoved: boolean
}

class SessionRendererHelper {
  private static groupInfoIsEqual(groupInfoA: GroupInfo | null, groupInfoB: GroupInfo | null): boolean {
    return Boolean(groupInfoA && groupInfoB &&
      groupInfoA.group.id === groupInfoB.group.id &&
      groupInfoA.removedPlayers.length === groupInfoB.removedPlayers.length &&
      groupInfoA.removedPlayers.every((player, index) => {
        return player.id === groupInfoB.removedPlayers[index].id
      })
    )
  }

  private static commonFieldIsEqual(fieldValueA: any, fieldValueB: any): boolean {
    return fieldValueA === fieldValueB
  }

  private static compareFieldValues (sessionValue: any, snapshotValue: any, snapshotExist: boolean): SessionFieldDiff {
    const isEqualFn = (sessionValue?.isGroupInfo || snapshotValue?.isGroupInfo)
      ? this.groupInfoIsEqual
      : this.commonFieldIsEqual

    const valueExist = Boolean(sessionValue || snapshotValue)

    const valueEqual =  Boolean(
      (!snapshotExist && sessionValue) || (snapshotExist && isEqualFn(sessionValue, snapshotValue))
    )

    const valueUpdated = Boolean(
      snapshotExist && snapshotValue && sessionValue && !isEqualFn(sessionValue, snapshotValue)
    )

    const valueAdded = Boolean(snapshotExist && !snapshotValue && sessionValue)

    const valueRemoved = Boolean(snapshotExist && snapshotValue && !sessionValue)

    return {
      valueExist,
      valueEqual,
      valueUpdated,
      valueAdded,
      valueRemoved,
    }
  }

  static renderSessionName(session: SessionModel, snapshot?: SessionModel): string {
    const valueUpdated = Boolean(snapshot && snapshot.name !== session.name)

    return `
      <div class="session-name">
        <div class="${valueUpdated ? ACTUAL_VALUE_CLASS: ''}">
          <span class="${FIELD_VALUE_CLASS}">${SH.encodeHtml(session.name)}</span>
        </div>
      </div>
    `
  }

  static renderTimeAndDuration(session: SessionModel, snapshot?: SessionModel): string {
    const valueUpdated = Boolean(snapshot && (
      !OH.isEqual(snapshot.startDate, session.startDate) ||
      !OH.isEqual(snapshot.endDate, session.endDate)
    ))

    return `
      <div class="session-duration">
        <div class="${valueUpdated ? ACTUAL_VALUE_CLASS: ''}">
          <span class="${FIELD_VALUE_CLASS}">
            <span>${session.startTime} - ${session.endTime}</span>
            <span>(${session.niceDuration})</span>
          </span>
        </div>
      </div>
    `
  }

  static renderLocation(session: SessionModel, snapshot?: SessionModel): string {
    const valueUpdated = Boolean(snapshot && snapshot.resourceId !== session.resourceId)

    if (valueUpdated) {
      const sessionResource = session.getResource(session.resourceId as string)

      return `
        <div class="session-location">
          <div class="${ACTUAL_VALUE_CLASS}">
            <span class="${FIELD_VALUE_CLASS}">${sessionResource.name}</span>
          </div>
        </div>
      `
    }

    // Do not show location by default
    return ''
  }

  static renderPublishingInfo(session: SessionModel): string {
    return `
      ${session.published ? PUBLISHED_BADGE : ''}
      ${session.isSnapshot ? SNAPSHOT_BADGE : ''}
    `
  }

  static renderSessionTemplate(session: SessionModel): string {
    if (session.sessionTemplate) {
      return `
        <div class="session-template">
          <div>
            <span class="${FIELD_VALUE_CLASS}">${SH.encodeHtml(session.sessionTemplate.name)}</span>
          </div>
        </div>
      `
    }

    return ''
  }

  private static renderGroupInfo({ group, removedPlayers }: GroupInfo, showRemovedPlayers = true): string {
    let groupHtml = `<i class="b-fa b-fa-user-group"></i>${group.title}`

    if (showRemovedPlayers && removedPlayers.length > 0) {
      const removedPlayerNames = removedPlayers.map(player => {
        return `
          <span class="removed-player">
            <i class="b-fa ${player.isStaff ? 'b-fa-user-tie' : 'b-fa-user'}"></i>
            ${player.fullName}
          </span>
        `
      })

      groupHtml = `${groupHtml}<br/>${removedPlayerNames.join('<br/>')}`
    }

    return groupHtml
  }

  static renderSessionGroups(session: SessionModel, snapshot?: SessionModel): string {
    const sessionGroupIds = session.groups.map(group => group.id)
    const snapshotGroupIds = snapshot?.groups.map(group => group.id) || []

    const combinedUniqueGroupIds = [...new Set([...sessionGroupIds, ...snapshotGroupIds])]

    if (combinedUniqueGroupIds.length === 0) {
      return ''
    }

    const groupListHtml = combinedUniqueGroupIds.map(groupId => {
      // Since we loop through unique ids collected from both sources, at least one info is not null
      const sessionGroupInfo = session.groupsWithPlayerDiff[groupId] || null
      const snapshotGroupInfo = snapshot?.groupsWithPlayerDiff[groupId] || null

      const {
        valueExist,
        valueEqual,
        valueUpdated,
        valueAdded,
        valueRemoved,
      } = this.compareFieldValues(sessionGroupInfo, snapshotGroupInfo, Boolean(snapshot))

      if (!valueExist) {
        return ''
      }

      if (valueRemoved) {
        return `
          <div class="session-group">
            <div class="${ORIGINAL_VALUE_CLASS}">
              <span class="${FIELD_VALUE_CLASS}">${this.renderGroupInfo(snapshotGroupInfo as GroupInfo, false)}</span>
            </div>
          </div>
        `
      }

      if (valueEqual || valueUpdated || valueAdded) {
        return `
          <div class="session-group">
            <div class="${(valueUpdated || valueAdded) ? ACTUAL_VALUE_CLASS : ''}">
              <span class="${FIELD_VALUE_CLASS}">${this.renderGroupInfo(sessionGroupInfo)}</span>
            </div>
          </div>
        `
      }

      // Fallback
      return ''
    })

    return `<div class="session-group-list">${groupListHtml.join('')}</div>`
  }

  private static renderStaff(staff: PlayerModel) {
    return `<i class="b-fa b-fa-user-tie"></i>${staff.fullName}`
  }

  static renderSessionStaff(session: SessionModel, snapshot?: SessionModel): string {
    const sessionStandaloneStaffIds = session.standaloneStaff.map(player => player.id)
    const snapshotStandaloneStaffIds = snapshot?.standaloneStaff.map(player => player.id) || []

    const combinedUniqueStandaloneStaffIds = [
      ...new Set([...sessionStandaloneStaffIds, ...snapshotStandaloneStaffIds])
    ]

    if (combinedUniqueStandaloneStaffIds.length === 0) {
      return ''
    }

    const staffListHtml = combinedUniqueStandaloneStaffIds.map(playerId => {
      const sessionStaff = session.standaloneStaffMap[playerId] || null
      const snapshotStaff = snapshot?.standaloneStaffMap[playerId] || null

      if (!snapshot || (sessionStaff && snapshotStaff)) {
        return `
          <div class="session-staff">
            <div>
              <span class="${FIELD_VALUE_CLASS}">${this.renderStaff(sessionStaff)}</span>
            </div>
          </div>
        `
      }

      const originalValueHtml = snapshotStaff ? `
        <div class="${ORIGINAL_VALUE_CLASS}">
          <span class="${FIELD_VALUE_CLASS}">${this.renderStaff(snapshotStaff)}</span>
        </div>
      ` : ''

      const actualValueHtml = sessionStaff ? `
        <div class="${ACTUAL_VALUE_CLASS}">
          <span class="${FIELD_VALUE_CLASS}">${this.renderStaff(sessionStaff)}</span>
        </div>
      ` : ''

      return `
        <div class="session-staff">
          ${originalValueHtml}
          ${actualValueHtml}
        </div>
      `
    })

    return `<div class="session-staff-list">${staffListHtml.join('')}</div>`
  }

  private static renderAthlete(athlete: PlayerModel) {
    return `<i class="b-fa b-fa-user"></i>${athlete.fullName}`
  }

  static renderSessionAthlete(session: SessionModel, snapshot?: SessionModel): string  {
    const sessionStandaloneAthleteIds = session.standaloneAthletes.map(player => player.id)
    const snapshotStandaloneAthleteIds = snapshot?.standaloneAthletes.map(player => player.id) || []

    const combinedUniqueStandaloneAthleteIds = [
      ...new Set([...sessionStandaloneAthleteIds, ...snapshotStandaloneAthleteIds])
    ]

    if (combinedUniqueStandaloneAthleteIds.length === 0) {
      return ''
    }

    const athleteListHtml = combinedUniqueStandaloneAthleteIds.map(playerId => {
      const sessionAthlete = session.standaloneAthletesMap[playerId] || null
      const snapshotAthlete = snapshot?.standaloneAthletesMap[playerId] || null

      if (!snapshot || (sessionAthlete && snapshotAthlete)) {
        return `
          <div class="session-athlete">
            <div>
              <span class="${FIELD_VALUE_CLASS}">${this.renderAthlete(sessionAthlete)}</span>
            </div>
          </div>
        `
      }

      const originalValueHtml = snapshotAthlete ? `
        <div class="${ORIGINAL_VALUE_CLASS}">
          <span class="${FIELD_VALUE_CLASS}">${this.renderAthlete(snapshotAthlete)}</span>
        </div>
      ` : ''

      const actualValueHtml = sessionAthlete ? `
        <div class="${ACTUAL_VALUE_CLASS}">
          <span class="${FIELD_VALUE_CLASS}">${this.renderAthlete(sessionAthlete)}</span>
        </div>
      ` : ''

      return `
        <div class="session-athlete">
          ${originalValueHtml}
          ${actualValueHtml}
        </div>
      `
    })

    return `<div class="session-athlete-list">${athleteListHtml.join('')}</div>`
  }

  static renderSessionNotes(session: SessionModel, snapshot?: SessionModel): string {
    const sessionValue = session.publicNote || ''
    const snapshotValue = snapshot?.publicNote || ''

    const {
      valueExist,
      valueEqual,
      valueUpdated,
      valueAdded,
      valueRemoved,
    } = this.compareFieldValues(sessionValue, snapshotValue, Boolean(snapshot))

    if (!valueExist) {
      return ''
    }

    if (valueRemoved) {
      return `
        <div class="session-notes">
          <div class="${ORIGINAL_VALUE_CLASS}">
            <span class="${FIELD_VALUE_CLASS}">
              <i class="b-fa b-fa-pen-to-square"></i>${SH.encodeHtml(snapshotValue)}
            </span>
          </div>
        </div>
      `
    }

    if (valueEqual || valueUpdated || valueAdded) {
      return `
        <div class="session-notes">
          <div class="${(valueUpdated || valueAdded) ? ACTUAL_VALUE_CLASS : ''}">
            <span class="${FIELD_VALUE_CLASS}">
              <i class="b-fa b-fa-pen-to-square"></i>${SH.encodeHtml(sessionValue)}
            </span>
          </div>
        </div>
      `
    }

    // Fallback
    return ''
  }
}

export default SessionRendererHelper
