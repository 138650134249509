import Vue from 'vue'
import VueCookie from 'vue-cookies'

Vue.use(VueCookie)

import App from '@/views/pages/App.vue'
import store from '@/store/store'
import vuetify from '@/plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import index from '@/router'

Vue.config.productionTip = false

new Vue({
    router: index,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
