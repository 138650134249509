import { DataFieldConfig, Model } from '@bryntum/schedulerpro'

// Implicitly adds new fields to an existing interface
interface ElementModel {
  id: string | number
  title: string
  duration: number
  rpe: number
}

// Types of raw data used as a template for session elements
export interface ElementRawDataObject {
  element_id: string | number // field used by Session Element record to have reference to Element id
  title: string
  duration: number
  rpe: number
}

// Types of data used as a template for session elements
export interface ElementDataObject {
  elementId: string | number // field used by Session Element record to have reference to Element id
  title: string
  duration: number
  rpe: number
}

// Explicitly extends Bryntum types and adds missing types
// TODO: remove once the issue is fixed: https://github.com/bryntum/support/issues/5017
interface DataFieldConfigExtension extends DataFieldConfig {
  type: string
}

/**
 * ElementModel is used to describe element in event-templates[].elements[]
 */
class ElementModel extends Model {
  static $name = 'ElementModel'

  static get fields(): Partial<DataFieldConfigExtension>[] {
    return [
      { name: 'title', type: 'string' },
      { name: 'duration', type: 'number', defaultValue: null },
      { name: 'rpe', type: 'number', defaultValue: null },
    ]
  }

  get isElementModel(): boolean {
    return true
  }

  /**
   * Returns raw data used as a template for session elements.
   * Keys correspond to raw object from server.
   */
  get rawDataJSON(): ElementRawDataObject {
    return {
      element_id: this.id,
      title: this.title,
      duration: this.duration,
      rpe: this.rpe,
    }
  }

  /**
   * Returns data used as a template for session elements.
   * Keys correspond to field names.
   */
  get dataJSON(): ElementDataObject {
    return {
      elementId: this.id,
      title: this.title,
      duration: this.duration,
      rpe: this.rpe,
    }
  }
}

export default ElementModel
